import React from "react";

const EdistynytKriteeriB5Tekstit = {
  id: "B5",
  nimi: <text>B5 Kriteerikortti: Tekninen optimointi</text>,
  kriteeri:
    <text>
      Tuote sisältää tiedot, joilla vähentää sen resurssienkulutusta, ohjelmiston koodi ja muu toteutus on optimoitu resurssienkulutuksen minimoimiseksi.
    </text>,
  plainKriteeri:
    "Tuote sisältää tiedot, joilla vähentää sen resurssienkulutusta, ohjelmiston koodi ja muu toteutus on optimoitu resurssienkulutuksen minimoimiseksi.",
  kriteerinLyhytKuvaus:
    <text>
      Tekninen optimointi on erityisesti ohjelmistokehityksen ja lähdekoodin kääntämisen tekniikoista lähtöisin oleva käsite. Sillä viitataan joukkoon manuaalisia
      ja automatisoituja menetelmiä, joilla tehostetaan ohjelmiston toimintaa mitattuun suorituskykyyn pohjautuvan evidenssin avulla. Optimaalisen tehokkuuden
      saavuttaminen on kuitenkin usein erittäin vaikeaa ellei suorastaan mahdotonta. Optimointi on tästä huolimatta hyödyllinen työkalu ja sillä voi olla
      merkittäviä vaikutuksia ohjelmiston suorituskykyyn ja ympäristöön. <br />
      Optimoinnilla on sekä suoria että epäsuoria ympäristövaikutuksia. Tehokkaampi ohjelmisto suorittaa vähemmän laskenta- ja muita operaatioita, kuluttaa vähemmän
      muistitilaa ja siirtää verkossa vähemmän dataa, mikä vähentää suoraan resurssitarvetta. Optimoinnilla on vaikutuksia myös järjestelmätasolla, sillä tehokas
      järjestelmä toimii verrattain vähemmällä laitemäärällä ja yksinkertaisemmalla -arkkitehtuurilla. <br />
      Käytetyt optimoinnit riippuvat paljon optimoitavasta tietojärjestelmästä, minkä lisäksi optimointiin käytettävissä olevat resurssit voivat olla liiketaloudellisesti
      rajoitettuja. Optimoinnin menetelmät vaihtelevat algoritmisuunnittelusta systemaattiseen mittaamiseen pohjautuviin kokeellisiin menetelmiin. Eri sovellusalueille
      on kehittynyt myös erilaisia koodin optimaalisuutta edistäviä suunnittelun peukalosääntöjä.
    </text>,
  perustelu:
    <text>
      Teknisen optimoinnin lähtökohta on resurssien käytön suora väheneminen kaikissa muodoissaan. Järjestelmän resurssikäyttö käsittää sen vaatiman laskenta- ja tallennuskapasiteetin,
      siirretyn tiedon määrän sekä muut sille varatut ja siihen liittyvien komponenttien resurssit. Tekninen optimointi järjestelmän arkkitehtuurin tasolla vaikuttaa myös epäsuorasti
      virrankulutukseen ja siirretyn tiedon määrään järjestelmän hajautuksen asteen perusteella. Kriteerin ympäristövaikutukset ovat merkittävästi sovelluskohtaisia ja ne voivat vaihdella
      hyvin pienistä hyvin merkittäviin riippuen siitä, miten suuri panostus kriteerin toteuttamiseen on käytettävissä ja toisaalta järjestelmän rakenteesta ja sen aiempien osajärjestelmien
      teknisen optimoinnin asteesta.
    </text>,
  plainPerustelu:
    "Teknisen optimoinnin lähtökohta on resurssien käytön suora väheneminen kaikissa muodoissaan. Järjestelmän resurssikäyttö käsittää sen vaatiman laskenta- ja tallennuskapasiteetin, " +
    "siirretyn tiedon määrän sekä muut sille varatut ja siihen liittyvien komponenttien resurssit. Tekninen optimointi järjestelmän arkkitehtuurin tasolla vaikuttaa myös epäsuorasti " +
    "virrankulutukseen ja siirretyn tiedon määrään järjestelmän hajautuksen asteen perusteella. Kriteerin ympäristövaikutukset ovat merkittävästi sovelluskohtaisia ja ne voivat vaihdella " +
    "hyvin pienistä hyvin merkittäviin riippuen siitä, miten suuri panostus kriteerin toteuttamiseen on käytettävissä ja toisaalta järjestelmän rakenteesta ja sen aiempien osajärjestelmien " +
    "teknisen optimoinnin asteesta.",
  minkaTyyppisiinEiSovi:
    <text>
      Resurssien kulutukseltaan vähäiset järjestelmät, joiden toteutus on verrattain kevyttä resurssien optimointiin verrattuna.
    </text>,
  kysymyksetHankkijanSuuntaan:
    <text>
      Onko järjestelmissä osioita, joiden tulee pystyä käsittelemään äkillistä suurta kuormitusta (esim. ruuhkautunut web-palvelu)? <br />
      Sisältääkö järjestelmä pitkäkestoisia toimintoja, joiden pitkä suoritusaika voi muodostua ongelmaksi? <br />
      Suorittaako järjestelmä laskentaa, jonka tiedetään algoritmiikan kirjallisuuden pohjalta vaativan huomattavaa laskentatehoa (kuvan-, äänen- ja signaalinkäsittely yms.) <br />
      Onko järjestelmässä mobiililaitteilla käytettäviä osia, joissa akkukestolla on merkitystä? <br />
      Käsitteleekö järjestelmä toistuvasti samaa dataa niin, että aiemman käsittelyn tuloksia voitaisiin käyttää uudelleen? <br />
      Käsitteleekö järjestelmä hyvin suuria datamääriä?
    </text>,
  copyPastevaatimus:
    <text>
      Tuote sisältää tiedot, joilla vähentää sen resurssienkulutusta, ohjelmiston koodi ja muu toteutus on optimoitu resurssienkulutuksen minimoimiseksi.
    </text>,
  arvioKriteerinHinnasta:
    <text>
      Kriteerin kustannus riippuu hyvin paljon siitä, miten laajasti ja merkittävällä tavalla järjestelmää joudutaan mittaamaan, arvioimaan ja toteuttamaan uudelleen ja miten
      suuresti optimoinnin tavoitetaso eroaa mitatusta. Kriteerin toteuttamisen kustannus kasvaa järjestelmän kehityksen etenemisen myötä, sillä muutoksen laajuus ja kompleksisuus voi kasvaa. <br />
      Kriteerin seuranta lisää joka tapauksessa vähäisesti kustannuksia resurssikulutuksen mittaukseen perustuvien, ideaalisti automatisoitujen testausjärjestelyjen muodossa.
      Mikäli optimoinnin tavoitetaso asetetaan verrattain korkeaksi, mittausten lisäksi voidaan joutua suorittamaan työlästä algoritmista analyysiä. Analyysi kuitenkin tarkentaa
      arviota viitteellisestä resurssikulutuksesta eri käyttöskenaarioissa, mikä voi säästää auttamalla välttämään järjestelmän kapasiteetin yli- tai alimitoitusta. <br />
      Toisaalta tekninen optimointi keventää järjestelmän käyttökustannuksia ja erityisesti tilanteissa, joissa järjestelmän käyttökustannus on merkittävä suhteessa
      kehityskustannuksiin, tekninen optimointi voi olla liiketoiminnallisesti perusteltua ja laskea järjestelmän kokonaiskustannuksia.
    </text>,
  arvioKriteerinYmparisto:
    <text>
      Teknisen optimoinnin lähtökohta on resurssien käytön suora väheneminen kaikissa muodoissaan. Järjestelmän resurssikäyttö käsittää sen vaatiman laskenta- ja tallennuskapasiteetin,
      siirretyn tiedon määrän sekä muut sille varatut ja siihen liittyvien komponenttien resurssit. Tekninen optimointi järjestelmän arkkitehtuurin tasolla vaikuttaa myös epäsuorasti
      virrankulutukseen ja siirretyn tiedon määrään järjestelmän hajautuksen asteen perusteella. Kriteerin ympäristövaikutukset ovat merkittävästi sovelluskohtaisia ja ne voivat
      vaihdella hyvin pienistä hyvin merkittäviin riippuen siitä, miten suuri panostus kriteerin toteuttamiseen on käytettävissä ja toisaalta järjestelmän rakenteesta ja sen aiempien
      osajärjestelmien teknisen optimoinnin asteesta.
    </text>,
  ohjeistusKriteerinKaytosta:
    <text>
      TBA
    </text>,
  todentaminen:
    <text>
      TBA
    </text>,
  ehdotusSopimuksenSeuranta:
    <text>
      TBA
    </text>,
  edellytyksetHyodyntamiselle:
    <text>
      Hankintaorganisaation pitää pystyä tunnistamaan ja kartoittamaan resurssien kulutuksen kannalta potentiaalisesti merkittäviä järjestelmiä ja käyttötapauksia. Toteutettavan
      järjestelmän ja sen osien teknisen optimoinnin arviointi edellyttää joko omaa ja ulkopuolelta hankittua ohjelmistokehityksen osaamista. Optimoinnin vaikutus karkealla tasolla
      on havaittavissa mahdollisesti myös silmämääräisesti, mikäli järjestelmässä on tehottomuudesta johtuvia pullonkauloja, jotka haittaavat järjestelmän normaalia käyttöä.
    </text>,
  esimerkki:
    <text>
      Web-kuvagalleriaohjelmistossa esikatselukuvien luonti suoritetaan järjestelmän palvelimella. Palvelin käyttää alkutilassa web-palvelun ohjelmistona PHP-kielen versiota
      5.6.0 sekä kuvien käsittelyyn libjpeg-kirjastoa (https://libjpeg.sourceforge.net/). Palvelinohjelmisto päivitetään PHP-versioon 8.1.0 ja toisaalta kuvien käsittelyyn otetaan
      käyttöön optimoitu libjpeg-turbo-kirjasto (https://libjpeg-turbo.org/). Kummankin muutoksen vaikutus suorituskykyyn voidaan todentaa esimerkiksi ab-työkalulla
      (https://httpd.apache.org/docs/2.4/programs/ab.html). Työkalulla havaitaan pyyntöjen käsittelyn kapasiteetin kasvu ja matalampi latenssi. Mittausta voitaisiin laajentaa fyysisen
      laitteen virtamittauksella.
    </text>,
  mittausJaViitearvot:
    <text>
      Tekninen optimointi on hyvin tapauskohtaista ja mahdolliset parannukset riippuvat myös käytettävästä järjestelmäalustasta. Mahdollinen resurssikulutus voidaan todeta ainoastaan
      systemaattisella mittausjärjestelyllä. Mahdollisia viitearvoja varten järjestelmän toiminnallisuuksista voidaan rakentaa karsittuja versioita ja todentaa niitä käyttäen eri
      toiminnallisuuksien vaikutus resurssikulutukseen. Viime kädessä algoritminen analyysi ja komponenttien toteuttaminen analyysin pohjalta antavat teoreettisen viitekehyksen
      toiminnallisuuksien resurssikulutukselle.
    </text>,
  kytkeytyvatKriteerit:
    <text>
      Ohjelmisto antaa palautetta kapasiteetin ja virran käytöstä, Ohjelmisto vapauttaa väliaikaisesti kapasiteettia, jota ei tarvitse, Oletusasetukset tukevat resurssien säilymistä, Virrankulutus.
    </text>,
  lisatiedotJaLahteet:
    <text>
      Aleti, Aldeida, et al. "Software architecture optimization methods: A systematic literature review." IEEE Transactions on Software Engineering 39.5 (2012): 658-683. <br />
      Hort, Max, et al. "A survey of performance optimization for mobile applications." IEEE Transactions on Software Engineering 48.8 (2021): 2879-2904. <br />
      Cong, Peijin, et al. "A survey of hierarchical energy optimization for mobile edge computing: A perspective from end devices to the cloud." ACM Computing Surveys (CSUR) 53.2 (2020): 1-44. <br />
      Benini, Luca, and Giovanni de Micheli. "System-level power optimization: techniques and tools." ACM Transactions on Design Automation of Electronic Systems (TODAES) 5.2 (2000): 115-192. <br />
    </text>

};

export default EdistynytKriteeriB5Tekstit;
