import React from "react";

const PerustasonKriteeriA3Tekstit = {
  id: "A3",
  nimi: <text>A3 Kriteerikortti: Ohjelmisto vapauttaa kapasiteettia tarvittaessa</text>,
  kriteeri:
    <text>
      Ohjelmisto käyttää vain tarvitsemiaan resursseja käyttäjän päätelaitteella, jossa ohjelmistoa suoritetaan, ja vapauttaa tarpeettomat resurssit muiden ohjelmistojen käyttöön.
    </text>,
  plainKriteeri:
    "Ohjelmisto käyttää vain tarvitsemiaan resursseja käyttäjän päätelaitteella, jossa ohjelmistoa suoritetaan, ja vapauttaa tarpeettomat resurssit muiden ohjelmistojen käyttöön.",
  kriteerinLyhytKuvaus:
    <text>
      Ohjelmiston tulisi vaatia laitteistolta ainoastaan niitä resursseja, joita se kullakin hetkellä tarvitsee. Resurssit joita ei tietyllä hetkellä enää vaadita vapautetaan muuhun
      käyttöön. Ajantasaiseen, todelliseen tarpeeseen pohjautuva kapasiteetin varaus mahdollistaa resurssien tehokkaan jakamisen muiden samanaikaisesti käytettävien ohjelmistojen kanssa,
      tehostaa laitteiston virransäästötilojen säätelyn automatiikkaa sekä yleisesti pyrkii vähentämään ohjelmiston kuormitusta.
    </text>,
  perustelu:
    <text>
      Loppukäyttäjän päätelaitteella kriteerin huomiotta jättäminen voi olla merkittävä, mikäli sovellus varaa turhaan käyttäjän laitteen keskusmuistia tai pyörittää ylimääräisiä
      taustaprosesseja kuluttaen energiaa turhaan. Tällöin on kuitenkin yleensä kyse selkeistä ohjelmointivirheistä, sillä normaalisti toimivan sovelluksen osalta toteutusalustan
      ja käyttöjärjestelmän toiminta vaikuttaa merkittävästi kapasiteetin vapauttamiseen.
    </text>,
  plainPerustelu:
    "Loppukäyttäjän päätelaitteella kriteerin huomiotta jättäminen voi olla merkittävä, mikäli sovellus varaa turhaan käyttäjän laitteen keskusmuistia tai pyörittää ylimääräisiä " +
    "taustaprosesseja kuluttaen energiaa turhaan. Tällöin on kuitenkin yleensä kyse selkeistä ohjelmointivirheistä, sillä normaalisti toimivan sovelluksen osalta toteutusalustan " +
    "ja käyttöjärjestelmän toiminta vaikuttaa merkittävästi kapasiteetin vapauttamiseen.",
  minkaTyyppisiinEiSovi:
    <text>
      Tietyissä kriittisissä järjestelmissä varatun kapasiteetin vapauttaminen ennakoivasti voi heikentää kykyä varautua äkillisiin kuormituspiikkeihin. Resursseja voi olla
      tarvetta pitää varalla tietyille toiminnoille jos niitä yhtäkkiä tarvitaankin. Esimerkiksi terveydenhuollossa käytetyissä järjestelmissä voi olla elintärkeää varmistaa jatkuva toimintakyky.
    </text>,
  kysymyksetHankkijanSuuntaan:
    <text>
      Onko ohjelmistolla erilaisia käyttömoodeja joilla on merkittävästi eriävä vaikutus virrankulutukseen? <br />
      Vaihtaako ohjelmisto dynaamisesti virtaa säästävään moodiin kun mahdollista? <br />
      Voidaanko ohjelmiston kuormittavia toiminnallisuuksia jakaa modulaarisiin, tarvittaessa käynnistettäviin prosesseihin?
    </text>,
  copyPastevaatimus:
    <text>
      Ohjelmisto käyttää vain tarvitsemiaan resursseja käyttäjän päätelaitteella tietokoneella, jossa ohjelmistoa suoritetaan, ja vapauttaa tarpeettomat resurssit muiden sovellusten käyttöön
    </text>,
  arvioKriteerinHinnasta:
    <text>
      Kriteerin toteuttamisen hinta riippuu järjestelmän monimutkaisuudesta ja sen toimintamalleista. Yleisesti ottaen kriteerin toteutuminen on osa järjestelmän normaalia toimintaa
      eikä poikkeustilanteiden ulkopuolella aiheuta ylimääräisiä kustannuksia.
    </text>,
  arvioKriteerinYmparisto:
    <text>
      Loppukäyttäjän päätelaitteella koneella kriteerin huomiotta jättäminen voi olla merkittävä, mikäli sovellus varaa turhaan käyttäjän laitteen koneen keskusmuistia tai pyörittää
      ylimääräisiä taustaprosesseja. Tällöin on kuitenkin yleensä kyse selkeistä ohjelmointivirheistä, sillä normaalisti toimivan sovelluksen osalta toteutusalustan ja käyttöjärjestelmän
      toiminta vaikuttaa merkittävästi kapasiteetin vapauttamiseen.
    </text>,
  ohjeistusKriteerinKaytosta:
    <text>
      Suosittelemme kriteerin käyttöä vähimmäisvaatimuksena.
    </text>,
  todentaminen:
    <text>
      Toimittajan selvitys siitä, että ohjelmisto käyttää vain tarvitsemiaan resursseja käyttäjän päätelaitteella, jossa ohjelmistoa suoritetaan, ja vapauttaa tarpeettomat resurssit muiden ohjelmistojen käyttöön.
    </text>,
  ehdotusSopimuksenSeuranta:
    <text>
      Käyttäjän päätelaitteessa olevan ohjelmiston osalta kapasiteetin vapautuminen mitataan osana ohjelmiston testausta yleisimpien käyttöskenaarioiden osalta.
    </text>,
  edellytyksetHyodyntamiselle:
    <text>
      Merkitys korostuu, jos ohjelmisto aiheuttaa ajoittaisia työkuormia tai piikkejä resurssien tarpeessa, ja pienenee jos ohjelmiston toiminta aiheuttaa tasaista kuormitusta.
    </text>,
  esimerkki:
    <text>
      Ohjelmistoissa voi ilmetä virheitä jotka aiheuttavat muistin vuotoa, jolloin varattua muistia ei vapauteta kun sitä ei enää tarvita. Tämä tarkoittaa että ohjelmisto vie
      yhä enemmän järjestelmän muistista mitä pidempään se on käynnissä, mikä on erityisen haitallista jos ohjelmistoa on tarkoitus pitää pitkään päällä valvomatta sen käytöstä.
    </text>,
  mittausJaViitearvot:
    <text>
      Kriteerin ymmärtämisessä auttaa jos voidaan tarkastella varsinaista dataa palvelinkeskuksen kuormituksesta ja suhteuttaa sitä ohjelmiston toimintaan.
    </text>,
  kytkeytyvatKriteerit:
    <text>
      Kuormituksen seuranta loppukäyttäjälle
    </text>

};

export default PerustasonKriteeriA3Tekstit;
