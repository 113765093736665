import React from 'react';
import { Link } from 'react-router-dom';

import GoBackArrow from '../components/GoBackArrow';
import Footer from '../components/Footer'

/* Mitvidi-opas sivu linkillä mittausjärjestely-sivulle */

const MitvidiOpas = () => {
    return (
        <div>
            <div className='kriteerikortit-container'>
                { /* Classnamet samoja kuin kriteerikortit sivulla, koska sama layout */}
                <h1 className='kriteerikortit-topheader'>MitViDi-opas</h1>
                <div className="kriteerikortit-text-container">
                    Ekologisten kestävien julkisten ohjelmistohankintojen opas esittelee MitViDi — Mittarit vihreän digitalisaation julkisiin
                    ICT-hankintoihin-hankkeessa kerättyä ja koottua tietoa ohjelmistojen ekologisesta kestävyydestä. Julkaisussa
                    esitellään hankkeessa kehitetty kestävien ohjelmistojen arviointikehikko sekä siihen perustuva selainpohjainen
                    työkalu hyödynnettäväksi julkisiin ohjelmistohankintoihin.

                    <br /> <br />

                    Julkaisu keskittyy erityisesti ohjelmistohankintoihin, mutta se tarjoaa lyhyesti tietoa myös laitteiden,
                    palvelinkeskusten ja verkkoliikenteen ekologisista vaikutuksista, joita on mahdotonta erottaa ohjelmistojen
                    ekologisista vaikutuksista.

                    <br /> <br />

                    Julkaisu on tarkoitettu julkisten hankintojen asiantuntijoille, jotka haluavat huomioida ekologisen kestävyyden
                    näkökulmat ohjelmistohankinnoissaan, mutta siitä hyötyvät myös julkisiin ohjelmistokilpailutuksiin osallistuvat
                    yritykset.

                    <br /> <br />

                    Opas on koottu MitViDi-hankkeessa ja sen työstöön ovat osallistuneet Turun ammattikorkeakoulu, Turun yliopisto,
                    LUT-yliopisto, TIEKE Tietoyhteiskunnan kehittämiskeskus ja Turku Science Park. Julkaisu on tehty yhteistyössä
                    hankintayksiköiden, ICT-alan yritysten sekä alan keskeisten toimijoiden kanssa.

                    <br />
                    <a href="https://urn.fi/URN:ISBN:978-952-216-853-5" className="styled-button" target="_blank" rel="noopener noreferrer">
                        MitViDi-opas
                    </a>
                </div>

                <h1 className='kriteerikortit-topheader'>Mittausjärjestely</h1>
                <div className="kriteerikortit-text-container">
                    MitViDi — Mittarit vihreän digitalisaation julkisiin ICT-hankintoihin-hankkeen osana
                    kehitettiin myös empiirinen mittausjärjestely virrankulutuksen määrittämiseksi.

                    <br /> <br />

                    Tietojärjestelmän virrankulutus on laaja kokonaisuus, jota voidaan havainnoida sekä
                    yksittäisten komponenttien ja osajärjestelmien että makrotasolla. Virrankulutukselle
                    saadaan karkea arvio järjestelmän laitteiston spesifikaation, mutta myös käytön
                    laskutuksen sekä esimerkiksi akkuvirralla toimivissa laitteissa niiden toiminta-ajan
                    perusteella. Tarkka kulutuksen määrittäminen edellyttää kuitenkin usein
                    systemaattista järjestelmän virtamittausta järjestelmän kehityksen ja/tai käytön
                    aikana.

                    <br /> <br />

                    MitViDi-hankkeessa kehitetty mittausjärjestely mahdollistaa sekä manuaalisten
                    että automaattisten mittausten suorittamisen arvioitavasta järjestelmästä.
                    Arvioitavana järjestelmänä on käytetty tarkan virtamittauksen mahdollistavaa,
                    varsinaista tuotantojärjestelmää edustavaa järjestelmää. Selvitys kuvaa
                    ratkaisun etuja ja rajoitteita sekä mahdollisuuksia yleistää järjestelyä.
                    Mittaukset voidaan tarvittaessa toistaa tilastollisen analysoinnin
                    mahdollistamiseksi. Kuvattu järjestely käsittää mittauslaitteistojen ja
                    -ohjelmistojen kartoituksen, fyysisen mittausjärjestelyn ja -ohjelmiston
                    määrittelyn, mittausten suunnittelun sekä tulosten käsittelyn.

                    Mittausjärjestelyn kuvaus perustuu Turun yliopistossa MitViDi-hankkeessa tehtyyn
                    selvitys- ja tutkimustyöhön.

                    <br /> <br />

                    <Link to="/mitvidiopas/mittausjarjestely" className="styled-button">Lisätietoa mittausjärjestelystä</Link>
                </div>
            </div>
            <GoBackArrow />
            <Footer />
        </div>
    )
}

export default MitvidiOpas
