import { Link } from 'react-router-dom';
import React from 'react';

import EdistynytKriteeriB1Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB1Tekstit';
import EdistynytKriteeriB2Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB2Tekstit';
import EdistynytKriteeriB3Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB3Tekstit';
import EdistynytKriteeriB4Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB4Tekstit';
import EdistynytKriteeriB5Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB5Tekstit';
import EdistynytKriteeriB6Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB6Tekstit';
import EdistynytKriteeriB7Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB7Tekstit';
import EdistynytKriteeriB8Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB8Tekstit';
import EdistynytKriteeriB9Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB9Tekstit';

import EdistynytKriteeriC1Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriC1Tekstit';
import EdistynytKriteeriC2Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriC2Tekstit';



/* Edistyneen tason kriteerit mitvidikysely sivulle */

const AccordionitEdistynyttaso = ({ accordionCheckboxes, openAccordion, handleAccordionCheck, handleAccordionClick }) => {

    return (
        <div>
            <div className="accordion-topheader"> <h2>2. Edistynyt taso</h2> </div>
            <div className={`accordion-item ${openAccordion.includes(EdistynytKriteeriB1Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(EdistynytKriteeriB1Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={EdistynytKriteeriB1Tekstit.id}
                        checked={accordionCheckboxes[EdistynytKriteeriB1Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {EdistynytKriteeriB1Tekstit.nimi} </p>


                    {openAccordion.includes(EdistynytKriteeriB1Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {EdistynytKriteeriB1Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {EdistynytKriteeriB1Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {EdistynytKriteeriB1Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {EdistynytKriteeriB1Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {EdistynytKriteeriB1Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiB1" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>

            <div className={`accordion-item ${openAccordion.includes(EdistynytKriteeriB2Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(EdistynytKriteeriB2Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={EdistynytKriteeriB2Tekstit.id}
                        checked={accordionCheckboxes[EdistynytKriteeriB2Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {EdistynytKriteeriB2Tekstit.nimi} </p>


                    {openAccordion.includes(EdistynytKriteeriB2Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {EdistynytKriteeriB2Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {EdistynytKriteeriB2Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {EdistynytKriteeriB2Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {EdistynytKriteeriB2Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {EdistynytKriteeriB2Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiB2" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>


            <div className={`accordion-item ${openAccordion.includes(EdistynytKriteeriB3Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(EdistynytKriteeriB3Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={EdistynytKriteeriB3Tekstit.id}
                        checked={accordionCheckboxes[EdistynytKriteeriB3Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {EdistynytKriteeriB3Tekstit.nimi} </p>


                    {openAccordion.includes(EdistynytKriteeriB3Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {EdistynytKriteeriB3Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {EdistynytKriteeriB3Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {EdistynytKriteeriB3Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {EdistynytKriteeriB3Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {EdistynytKriteeriB3Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiB3" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>


            <div className={`accordion-item ${openAccordion.includes(EdistynytKriteeriB4Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(EdistynytKriteeriB4Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={EdistynytKriteeriB4Tekstit.id}
                        checked={accordionCheckboxes[EdistynytKriteeriB4Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {EdistynytKriteeriB4Tekstit.nimi} </p>


                    {openAccordion.includes(EdistynytKriteeriB4Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {EdistynytKriteeriB4Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {EdistynytKriteeriB4Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {EdistynytKriteeriB4Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {EdistynytKriteeriB4Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {EdistynytKriteeriB4Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiB4" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>
            <div className={`accordion-item ${openAccordion.includes(EdistynytKriteeriB5Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(EdistynytKriteeriB5Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={EdistynytKriteeriB5Tekstit.id}
                        checked={accordionCheckboxes[EdistynytKriteeriB5Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {EdistynytKriteeriB5Tekstit.nimi} </p>


                    {openAccordion.includes(EdistynytKriteeriB5Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {EdistynytKriteeriB5Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {EdistynytKriteeriB5Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {EdistynytKriteeriB5Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {EdistynytKriteeriB5Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {EdistynytKriteeriB5Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiB5" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>
            <div className={`accordion-item ${openAccordion.includes(EdistynytKriteeriB6Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(EdistynytKriteeriB6Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={EdistynytKriteeriB6Tekstit.id}
                        checked={accordionCheckboxes[EdistynytKriteeriB6Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {EdistynytKriteeriB6Tekstit.nimi} </p>


                    {openAccordion.includes(EdistynytKriteeriB6Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {EdistynytKriteeriB6Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {EdistynytKriteeriB6Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {EdistynytKriteeriB6Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {EdistynytKriteeriB6Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {EdistynytKriteeriB6Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiB6" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>
            <div className={`accordion-item ${openAccordion.includes(EdistynytKriteeriB7Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(EdistynytKriteeriB7Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={EdistynytKriteeriB7Tekstit.id}
                        checked={accordionCheckboxes[EdistynytKriteeriB7Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {EdistynytKriteeriB7Tekstit.nimi} </p>


                    {openAccordion.includes(EdistynytKriteeriB7Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {EdistynytKriteeriB7Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {EdistynytKriteeriB7Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {EdistynytKriteeriB7Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {EdistynytKriteeriB7Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {EdistynytKriteeriB7Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiB7" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>
            <div className={`accordion-item ${openAccordion.includes(EdistynytKriteeriB8Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(EdistynytKriteeriB8Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={EdistynytKriteeriB8Tekstit.id}
                        checked={accordionCheckboxes[EdistynytKriteeriB8Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {EdistynytKriteeriB8Tekstit.nimi} </p>


                    {openAccordion.includes(EdistynytKriteeriB8Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {EdistynytKriteeriB8Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {EdistynytKriteeriB8Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {EdistynytKriteeriB8Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {EdistynytKriteeriB8Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {EdistynytKriteeriB8Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiB8" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>
            <div className={`accordion-item ${openAccordion.includes(EdistynytKriteeriB9Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(EdistynytKriteeriB9Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={EdistynytKriteeriB9Tekstit.id}
                        checked={accordionCheckboxes[EdistynytKriteeriB9Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {EdistynytKriteeriB9Tekstit.nimi} </p>


                    {openAccordion.includes(EdistynytKriteeriB9Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {EdistynytKriteeriB9Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {EdistynytKriteeriB9Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {EdistynytKriteeriB9Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {EdistynytKriteeriB9Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {EdistynytKriteeriB9Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiB9" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>

            <div className={`accordion-item ${openAccordion.includes(EdistynytKriteeriC1Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(EdistynytKriteeriC1Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={EdistynytKriteeriC1Tekstit.id}
                        checked={accordionCheckboxes[EdistynytKriteeriC1Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {EdistynytKriteeriC1Tekstit.nimi} </p>


                    {openAccordion.includes(EdistynytKriteeriC1Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {EdistynytKriteeriC1Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {EdistynytKriteeriC1Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {EdistynytKriteeriC1Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {EdistynytKriteeriC1Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {EdistynytKriteeriC1Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiC1" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>

                </div>
            </div>

            <div className={`accordion-item ${openAccordion.includes(EdistynytKriteeriC2Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(EdistynytKriteeriC2Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={EdistynytKriteeriC2Tekstit.id}
                        checked={accordionCheckboxes[EdistynytKriteeriC2Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {EdistynytKriteeriC2Tekstit.nimi} </p>


                    {openAccordion.includes(EdistynytKriteeriC2Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}

                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {EdistynytKriteeriC2Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {EdistynytKriteeriC2Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {EdistynytKriteeriC2Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {EdistynytKriteeriC2Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {EdistynytKriteeriC2Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiC2" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>

                </div>
            </div>
        </div>
    );
};

export default AccordionitEdistynyttaso