import React from 'react';
import "../index.css";

/* Kategoriapainikkeella valitaan haluttu vaativuustaso, ohjelmiston valitsemisen jälkeen */ 

const CategoryButton = ({ selected, onClick, label, disabled }) => {
  const buttonClassName = selected ? "CategoryButton-selected" : "CategoryButton";
  const disabledClass = disabled ? "CategoryButton-disabled" : "";
  const title = disabled ? "Painike on poissa käytöstä. Muistithan valita ohjelmiston tyypin?" : null; // Kategoriapainikkeille: näyttää tekstin titlenä, jos ohjelmistontyyppiä ei ole valittu

  return (
    <button className={`${buttonClassName} ${disabledClass}`} onClick={onClick} title={title}>
            <span className="CategoryButton-icon">{selected ? '■' : '□'}</span>
      {label}
    </button>
  );
};

export default CategoryButton;