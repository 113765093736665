import React from 'react';

import PerustasonKriteeriA5Tekstit from '../../tekstit/perustaso/PerustasonKriteeriA5Tekstit';


const PerustasonMittariA5 = ({ openKriteeri, handleKriteerikorttiClick }) => {
    const { id, nimi, kriteeri, kriteerinLyhytKuvaus, perustelu, minkaTyyppisiinEiSovi, kysymyksetHankkijanSuuntaan, copyPastevaatimus, ohjeistusKriteerinKaytosta,
        arvioKriteerinHinnasta, arvioKriteerinYmparisto, todentaminen, ehdotusSopimuksenSeuranta, edellytyksetHyodyntamiselle, esimerkki, kuva, mittausJaViitearvot, kytkeytyvatKriteerit } = PerustasonKriteeriA5Tekstit;

    return (
        <div>
            <div className={`accordion-item ${openKriteeri.includes(id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleKriteerikorttiClick(id)}>

                    <p className="accordion-name">{nimi} </p>

                    {openKriteeri.includes(id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}
                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {kriteeri}
                        </li>
                        <li>
                            <strong> Kriteerin lyhyt kuvaus: </strong>
                            {kriteerinLyhytKuvaus}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {perustelu}
                        </li>
                        <li>
                            <strong> Minkätyyppisiin järjestelmiin kriteeri ei sovi? </strong>
                            {minkaTyyppisiinEiSovi}
                        </li>
                        <li>
                            <strong>Työkalussa esitetyt kysymykset hankkijan suuntaan: </strong>
                            {kysymyksetHankkijanSuuntaan}
                        </li>
                        <li>
                            <strong> Copy-paste vaatimus: </strong>
                            {copyPastevaatimus}
                        </li>
                        <li>
                            <strong> Ohjeistus kriteerin käytöstä hankinnan aikana </strong>
                            {ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Arvio kriteerin toteutuksen hinnasta: </strong>
                            {arvioKriteerinHinnasta}
                        </li>
                        <li>
                            <strong>Arvio kriteerin toteutuksen ympäristövaikutuksista: </strong>
                            {arvioKriteerinYmparisto}
                        </li>
                        <li>
                            <strong> Kriteerin toteutumisen seuranta hankinnan aikana </strong>
                            {todentaminen} <br /><br />
                            {ehdotusSopimuksenSeuranta}
                        </li>
                        <li>
                            <strong>Edellytykset kriteerin hyödyntämiselle: </strong>
                            {edellytyksetHyodyntamiselle}

                        </li>
                        <li>
                            <strong>Esimerkki: </strong>
                            {esimerkki}
                        </li>
                        {kuva}
                        <li>
                            <strong>Kriteeriin liittyvä mittaus ja viitearvot: </strong>
                            {mittausJaViitearvot}
                        </li>
                        <li>
                            <strong>Kytkeytyvät kriteerit: </strong>
                            {kytkeytyvatKriteerit}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default PerustasonMittariA5