import React from 'react';
import "../index.css";

/* 
   Tavallinen painike muokattavalla tekstillä, onclick, disabled ja title parametreillä.
   CSS-luokat "categoryButton" ja "categorybutton-disabled" samat kuin hankkijan kyselyn kategoriapainikkeissa.
*/

const RegularButton = ({ label, onClick, disabled, title }) => {
  const buttonClassName = disabled ? "styled-button-disabled" : "styled-button";  /* Sama tyyli kuin frontpagen navigointipainikkeilla */ 
  
  return (
    <button className={buttonClassName} onClick={onClick} disabled={disabled} title={title}>
      {label}
    </button>
  );
};

export default RegularButton;