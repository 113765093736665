import React from "react";

const PerustasonKriteeriA8Tekstit = {
  id: "A8",
  nimi: <text>A8 Kriteerikortti: Ohjelmiston oletusasetusten kytkös kokonaisvirrankulutukseen </text>,
  kriteeri:
    <text>
      Enemmistö käyttäjistä ei koske asetuksiin, joita he eivät koe relevanteiksi itselleen tai joista he eivät koe tietävänsä tarpeeksi. Tämä tarkoittaa että oletusasetukset
      toimivat samanaikaisesti vahvana suosituksena tai jopa muuttumattomana tekijänä monelle käyttäjälle. Mitä paremmin oletusasetukset tukevat ympäristöystävällistä käyttöä,
      sitä enemmän ohjelmisto säästää elinkaaren aikana kustannuksia.
    </text>,
  plainKriteeri:
    "Enemmistö käyttäjistä ei koske asetuksiin, joita he eivät koe relevanteiksi itselleen tai joista he eivät koe tietävänsä tarpeeksi. Tämä tarkoittaa että oletusasetukset " +
    "toimivat samanaikaisesti vahvana suosituksena tai jopa muuttumattomana tekijänä monelle käyttäjälle. Mitä paremmin oletusasetukset tukevat ympäristöystävällistä käyttöä, " +
    "sitä enemmän ohjelmisto säästää elinkaaren aikana kustannuksia.",
  kriteerinLyhytKuvaus:
    <text>
      Enemmistö käyttäjistä ei koske asetuksiin, joita he eivät koe relevanteiksi itselleen tai joista he eivät koe tietävänsä tarpeeksi. Tämä tarkoittaa että oletusasetukset
      toimivat samanaikaisesti vahvana suosituksena tai jopa muuttumattomana tekijänä monelle käyttäjälle. Mitä paremmin oletusasetukset tukevat kokonaisvirrankulutuksen optimointia,
      sitä enemmän ohjelmisto säästää elinkaaren aikana kustannuksia.
    </text>,
  perustelu:
    <text>
      Enemmistö käyttäjistä ei koske asetuksiin, joita he eivät koe relevanteiksi itselleen tai joista he eivät koe tietävänsä tarpeeksi. Tämä tarkoittaa että oletusasetukset
      toimivat samanaikaisesti vahvana suosituksena tai jopa muuttumattomana tekijänä monelle käyttäjälle. Mitä paremmin oletusasetukset tukevat kokonaisvirrankulutuksen optimointia,
      sitä enemmän ohjelmisto säästää elinkaaren aikana kustannuksia.
    </text>,
  plainPerustelu:
    "Enemmistö käyttäjistä ei koske asetuksiin, joita he eivät koe relevanteiksi itselleen tai joista he eivät koe tietävänsä tarpeeksi. Tämä tarkoittaa että oletusasetukset " +
    "toimivat samanaikaisesti vahvana suosituksena tai jopa muuttumattomana tekijänä monelle käyttäjälle. Mitä paremmin oletusasetukset tukevat kokonaisvirrankulutuksen optimointia, " +
    "sitä enemmän ohjelmisto säästää elinkaaren aikana kustannuksia.",
  minkaTyyppisiinEiSovi:
    <text>
      Ohjelmistot joissa asetuksia voidaan vaihtaa hyvin rajoitetusti, tai joiden käyttötavat ovat niin rajoitettuja että ohjelmiston ympäristökuorma on käytännössä sama jokaisen käyttäjän käsissä.
    </text>,
  kysymyksetHankkijanSuuntaan:
    <text>
      Onko ohjelmistossa raskasta laskentaa vaativia toimintoja? <br />
      Onko ohjelmistossa paljon graafisia elementtejä, kuten animaatioita tai videoita? <br />
      Onko ohjelmistossa asetuksia tai toimintoja, jotka on suunnattu vain kehittyneille käyttäjille tai ylipäätään vain tietylle käyttäjäryhmälle?
    </text>,
  copyPastevaatimus:
    <text>
      Oletusasetukset tukevat resurssien säilymistä. Loppukäyttäjän oletusasetukset tulee olla valittuna niin, että oletuksena valittuna ovat ympäristövaikutusten kannalta /
      virrankulutuksen kannalta optimaaliset asetukset.
    </text>,
  arvioKriteerinHinnasta:
    <text>
      Kriteerin toteuttaminen vaatii ohjelmiston suunnittelijoilta arviota siitä millaisiksi asetukset olisi tehokkainta määrittää ja mitkä asetukset annetaan käyttäjän
      muokattaviksi, mikä aiheuttaa lisäkustannuksia.
    </text>,
  arvioKriteerinYmparisto:
    <text>
      Koska asetukset koskevat jokaisen käyttäjän toimintaa ohjelmiston kanssa, tämä vaikuttaa merkittävästi ohjelmiston elinkaaren aikaisiin käyttökustannuksiin.
      Suurin osa käyttäjistä ei tule koskemaan jokaiseen asetukseen, mikä tarkoittaa että oletusasetuksilla on suuri merkitys ohjelmiston koko elinkaaren aikaisen ympäristökuorman kannalta.
    </text>,
  ohjeistusKriteerinKaytosta:
    <text>
      TBA
    </text>,
  todentaminen:
    <text>
      TBA
    </text>,
  ehdotusSopimuksenSeuranta:
    <text>
      TBA
    </text>,
  edellytyksetHyodyntamiselle:
    <text>
      Ohjelmiston toiminnallisuuksiin voi vaikuttaa merkittävästi asetusten kautta ja nämä vaikuttavat myös ohjelmiston aiheuttamaan kuormitukseen.
    </text>,
  esimerkki:
    <text>
      Youtube suhteuttaa videon laadun käyttäjän laitteeseen ja näyttöön. Toisaalta Youtube myös toimii tämän periaatteen vastaisesti videoiden automaattisen toiston kanssa. Tämä asetus on
      oletuksena päällä, jolloin videoita toistetaan yksi toisensa jälkeen jollei käyttäjä tule väliin.
    </text>,
  mittausJaViitearvot:
    <text>
      Oletusasetusten valikointiin voi kokeilla eri ohjelmakonfiguraatioita ja verrata näiden toimintaa keskenään.
    </text>,
};

export default PerustasonKriteeriA8Tekstit;
