import React from "react";

const PerustasonKriteeriA1Tekstit = {
  id: "A1",
  nimi: <text>A1 Kriteerikortti: Ohjelmiston toiminta offline-tilassa</text>,
  kriteeri:
    <text> Ohjelmiston tulee toimia offline-tilassa.
      Hankintayksikkö määrittelee ohjelmistohankintakohtaisesti, minkä ohjelmiston toiminnallisuuksien tulee toimia offline-tilassa.
      Kriteeri on perustason kriteeri hankintaykslön näkökulmasta. Sen käyttäminen tarjouspyynnössä vaatii hankintayksiköltä perustason tietämystä ohjelmistojen ominaisuuksita,
      jotka halutaan toimia offline.</text>,
  plainKriteeri:
    "Ohjelmiston tulee toimia offline-tilassa. " +
    "Hankintayksikkö määrittelee ohjelmistohankintakohtaisesti, minkä ohjelmiston toiminnallisuuksien tulee toimia offline-tilassa. " +
    "Kriteeri on perustason kriteeri hankintaykslön näkökulmasta. Sen käyttäminen tarjouspyynnössä vaatii hankintayksiköltä perustason tietämystä ohjelmistojen ominaisuuksita, " +
    "jotka halutaan toimia offline",
  kriteerinLyhytKuvaus:
    <text> Offline-toiminnallisuudet mahdollistavat ohjelmiston käyttöä ainakin osittain monessa tilanteessa joissa muuten olisi haastavaa tai tarpeetonta muodostaa verkkoyhteys,
      eikä työkyky katoa täysin mahdollisten yhteyden tai palvelun katkojen johdosta. <br />
      Kriteeri ohjaa välillisesti järjestelmän arkkitehtuuria energiatehokkuuteen esim. välimuistin käyttöä tehostamalla sekä pakottaa kiinnittämään kehitystyön aikana huomiota
      järjestelmän eri osien verkkoliikenteen määrään.
    </text>,
  perustelu:
    <text>Tiedonsiirto kuluttaa merkittävästi energiaa, joten tarpeettoman tiedonsiirron välttäminen säästää sitä vastaavasti.  Ylimääräistä tiedonsiirtoa voi välttää sillä,
      että ohjelmisto on käytettävissä myös offline-tilassa. Tietoliikenteen infrastruktuuri aiheuttaa myös ympäristökuormaa. <br />

      Offline-toiminnallisuudet mahdollistavat ohjelmiston käyttöä ainakin osittain monessa tilanteessa joissa muuten olisi haastavaa tai tarpeetonta muodostaa verkkoyhteys,
      eikä työkyky katoa täysin mahdollisten yhteyden tai palvelun katkojen johdosta. <br />

      Kriteeri ohjaa välillisesti järjestelmän arkkitehtuuria energiatehokkuuteen esim. välimuistin käyttöä tehostamalla sekä pakottaa kiinnittämään kehitystyön aikana
      huomiota järjestelmän eri osien verkkoliikenteen määrään.
    </text>,
  plainPerustelu:
    "Tiedonsiirto kuluttaa merkittävästi energiaa, joten tarpeettoman tiedonsiirron välttäminen säästää sitä vastaavasti.  Ylimääräistä tiedonsiirtoa voi välttää sillä, " +
    "että ohjelmisto on käytettävissä myös offline-tilassa. Tietoliikenteen infrastruktuuri aiheuttaa myös ympäristökuormaa. \n " +
    "Offline-toiminnallisuudet mahdollistavat ohjelmiston käyttöä ainakin osittain monessa tilanteessa joissa muuten olisi haastavaa tai tarpeetonta muodostaa verkkoyhteys, " +
    "eikä työkyky katoa täysin mahdollisten yhteyden tai palvelun katkojen johdosta. \n" +
    "Kriteeri ohjaa välillisesti järjestelmän arkkitehtuuria energiatehokkuuteen esim. välimuistin käyttöä tehostamalla sekä pakottaa kiinnittämään kehitystyön aikana " +
    "huomiota järjestelmän eri osien verkkoliikenteen määrään.",
  minkaTyyppisiinEiSovi:
    <text> Kriteeri sopii sellaisenaan huonosti verkkosivujen ja selaimessa toimivien ratkaisujen kehittämiseen. Näiden osalta on tärkeämpää keskittyä välimuistin järkevään
      käyttöön kaikissa järjestelmän osissa (ks. kriteerikortti B9).
    </text>,
  kysymyksetHankkijanSuuntaan:
    <text> Sisältääkö haluttu järjestelmä toiminnallisuuksia, joita käyttäjä voisi käyttää ilman verkkoyhteyttä: <br />
      <strong>a)</strong> koko järjestelmä <br />
      <strong>b)</strong> osa järjestelmästä <br />
      <strong>c)</strong> ei ollenkaan" </text>,
  copyPastevaatimus:
    <text>
      Järjestelmän tulee toimia myös offline-tilassa, mikäli verkkoyhteys ei ole olennainen toiminnallisuuksille.
    </text>,
  arvioKriteerinHinnasta:
    <text>
      Kriteerin hyödyntämisestä aiheutuu kehitystyön aikana lisäkustannuksia, jotka kuitenkin tasaantuvat käytönaikaisen pienemmän verkkoliikenteen myötä.
      Toteuttamisen aiheuttamat kustannukset vaihtelevat mutta ovat todennäköisesti pienet.
    </text>,
  arvioKriteerinYmparisto:
    <text>
      Tiedonsiirto kuluttaa merkittävästi energiaa, joten tarpeettoman tiedonsiirron välttäminen säästää sitä vastaavasti. Ylimääräistä tiedonsiirtoa voi välttää sillä,
      että ohjelmisto on käytettävissä myös offline-tilassa. Tietoliikenteen infrastruktuuri aiheuttaa myös ympäristökuormaa. Lopullinen vaikutus tulee olemaan kiinni siitä
      kuinka suuri osa ohjelmistosta on käytettävissä offline-tilassa.
    </text>,
  ohjeistusKriteerinKaytosta:
    <text>
      Hankintayksikkö listaa tarjouspyynnössä ne ohjelmistojen toiminnallisuudet, jotka se haluaa toimivan offline-tilassa. Listaa voidaan hyödyntää tarjouspyynnössä seuraavasti: <br />

      <strong>Pisteytettävänä:</strong> Pisteitä annetaan jokaisesta toiminnallisuudesta, jonka ohjelmisto tekee offline-tilassa. <br />
      <strong>Vähimmäisvaatimuksena:</strong> Hankintayksikön listaamien ohjelmiston toiminnallisuuksien tulee toimia oflline-tilassa
    </text>,
  todentaminen:
    <text>
      Mikäli kyseessä on valmisohjelmisto, offline-toiminnallisuudet todennetaan testaamalla. <br />
      Mikäli kyseessä on räätälöity ohjelmisto, tarjoaja toimittaa suunnitelman offline-toiminnallisuuksien toteuttamisesta. On suositeltavaa varmistaa offline-toiminnallisuuksien kriteerin mukaisuus myöhemmin testauksen tai järjestelmän käytön yhteydessä.
    </text>,
  ehdotusSopimuksenSeuranta:
    <text>
      Se, että ohjelmiston tietyt toiminnallisuudet toimivat offline-tilassa varmistetaan sopimuskaudella ohjelmiston testauksen tai käytön yhteydessä.
    </text>,
  edellytyksetHyodyntamiselle:
    <text>
      Järjestelmästä on mahdollista erottaa sellaisia osia jotka eivät luonteeltaan vaadi jatkuvaa tiedonsiirtoa tai ole riippuvaisia ulkoisista palveluista. <br />
      Pisteyttävä kriteeri voidaan rakentaa kahdella tavalla: <br />
      <strong>1)</strong> Tilaaja määrittelee ne järjestelmän toiminnallisuudet, jotka olisi hyvä toimia offline-tilassa. <br />
      <strong>2)</strong> Tilaaja pyytää toimittajaa arvioimaan, mitkä järjestelmän toiminnallisuudet on mahdollista toteuttaa offline-tilassa. Vähimmäisvaatimus voidaan toteuttaa niin, että tilaaja
      määrittelee toiminnallisuudet, jotka tulee toimia offline tilassa tai määrittelee, että koko sovelluksen tulee toimia offline-tilassa.
    </text>,
  esimerkki:
    <text>
      Outlookissa voi tarkastella ja luonnostella sähköposteja ilman verkkoyhteyttä.
    </text>,
  kuva:
    <picture>
      {/* WebP */}
      <source
        srcSet="/pictures/offline-toiminnallisuus.webp"
        type="image/webp"
      />
      {/* PNG jos webp ei käytössä */}
      <source
        srcSet="/pictures/offline-toiminnallisuus.png"
        type="image/png"
      />
      {/* Fallback */}
      <img
        src="/pictures/offline-toiminnallisuus.png"
        loading="lazy"
        alt="Kuva outlookin offline-toiminnallisuudesta"
        className="kriteerikortti-images"
      />
    </picture>,
  mittausJaViitearvot:
    <text>
      Ei mitattavissa.
    </text>,
  kytkeytyvatKriteerit:
    <text>
      Ohjelmisto tukee käyttäjän kykyä muokata asetuksia tarpeisiinsa.
    </text>

};

export default PerustasonKriteeriA1Tekstit;
