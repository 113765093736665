import React from "react";

const EdistynytKriteeriB8Tekstit = {
  id: "B8",
  nimi: <text>B8 Kriteerikortti: Bottiliikenteen hallinnointi</text>,
  kriteeri:
    <text>
      Ohjelmisto toteuttaa bottien hallintaan tarkoitettuja toimenpiteitä, jotka estävät haitallisten bottien pääsyn ohjelmistoon ja päästävät halutut botit läpi.
    </text>,
  plainKriteeri:
    "Ohjelmisto toteuttaa bottien hallintaan tarkoitettuja toimenpiteitä, jotka estävät haitallisten bottien pääsyn ohjelmistoon ja päästävät halutut botit läpi.",
  kriteerinLyhytKuvaus:
    <text>
      Verkossa toimiva robotti tai botti on sovellus, joka suorittaa skriptejä internetin välityksellä. Bottiliikenne koostaa noin puolet internetistä tapahtuvasta
      tietojensiirrosta. Boteista osa on hyödyllisiä ja haluttavia, ja osa on haitallisia. Selaimen kautta toimivan ohjelmiston pitäisi pystyä suodattamaan haitalliset botit haittaamatta hyödyllisten
      bottien toimintaa.
    </text>,
  perustelu:
    <text>
      Bottiliikenteen rajoittaminen säästää palvelinkapasiteettia todellisille käyttäjille ja vähentää tiedonsiirron määrää, muiden hyötyjen ohella. Bottiliikenne
      on merkittävä osa kaikesta internetin liikenteestä ja niiden kontrolloimiseksi ei tällä hetkellä tehdä tarpeeksi toimenpiteitä.
    </text>,
  plainPerustelu:
    "Bottiliikenteen rajoittaminen säästää palvelinkapasiteettia todellisille käyttäjille ja vähentää tiedonsiirron määrää, muiden hyötyjen ohella. Bottiliikenne " +
    "on merkittävä osa kaikesta internetin liikenteestä ja niiden kontrolloimiseksi ei tällä hetkellä tehdä tarpeeksi toimenpiteitä.",
  minkaTyyppisiinEiSovi:
    <text>
      Järjestelmät, jotka eivät ole yhteydessä julkiseen verkkoon tai perinteiset työpöytäsovellukset ilman varsinaisia verkkoyhteyksiä. Joillain
      kehitysalustoilla toimittajan kyky vaikuttaa asiaan voi myös olla rajallinen.
    </text>,
  copyPastevaatimus:
    <text>
      Ohjelmisto toteuttaa bottien hallintaan tarkoitettuja toimenpiteitä, jotka estävät haitallisten bottien
      pääsyn ohjelmistoon ja päästävät halutut botit läpi.
    </text>,
  arvioKriteerinHinnasta:
    <text>
      Kriteerin toteutus vaatii erillisten toiminnallisuuksien tai palveluiden toteuttamista bottien estämiseksi, mikä aiheuttaa itsessään kustannuksia
      joko sivuston kehittäjille tai maksettaessa palveluntarjoajalle, joka toteuttaa suojauksen.
    </text>,
  arvioKriteerinYmparisto:
    <text>
      Bottiliikenteen rajoittaminen säästää palvelinkapasiteettia todellisille käyttäjille ja vähentää tiedonsiirron määrää, muiden hyötyjen ohella. Bottiliikenne on merkittävä osa kaikesta
      internetin liikenteestä ja niiden kontrolloimiseksi ei tällä hetkellä tehdä tarpeeksi toimenpiteitä.
    </text>,
  ohjeistusKriteerinKaytosta:
    <text>
      TBA
    </text>,
  todentaminen:
    <text>
      TBA
    </text>,
  ehdotusSopimuksenSeuranta:
    <text>
      TBA
    </text>,
  edellytyksetHyodyntamiselle:
    <text>
      Hyödylliset botit tulisi pystyä päästämään läpi samalla kun haitalliset botit estetään. Tämän toteuttaminen tehokkaasti vaatii osaamista toimijalta, joka asiasta vastaa.
    </text>,
  esimerkki:
    <text>
      Bottien erotteluun käytettyjä tekniikoita ovat esimerkiksi haasteet, joista ihminen suoriutuu, mutta botti ei, kuten CAPTCHA, listat hyväksyttyjen tai hylättyjen bottien IP-
      osoitteista tai bottien tunnistaminen käytöksen perusteella.
    </text>,
  mittausJaViitearvot:
    <text>
      Verkkosivustoon kohdistuvan liikenteen seuraaminen mahdollistaa myös bottiliikenteen ja siihen liittyvän torjumisen tilastotietojen seuraamisen.
      Yleisesti, jos 10 % tai enemmän liikenteestä koostuu boteista, ohjelmisto voi olla huonosti suojattu.
    </text>,
};

export default EdistynytKriteeriB8Tekstit;
