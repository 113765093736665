import React, { Component } from 'react';

import GoBackArrow from '../components/GoBackArrow';

import PerustasonKriteeriA1 from '../kriteerikortit/perustaso/PerustasonKriteeriA1';
import PerustasonKriteeriA2 from '../kriteerikortit/perustaso/PerustasonKriteeriA2';
import PerustasonKriteeriA3 from '../kriteerikortit/perustaso/PerustasonKriteeriA3';
import PerustasonKriteeriA4 from '../kriteerikortit/perustaso/PerustasonKriteeriA4';
import PerustasonKriteeriA5 from '../kriteerikortit/perustaso/PerustasonKriteeriA5';
import PerustasonKriteeriA6 from '../kriteerikortit/perustaso/PerustasonKriteeriA6';
import PerustasonKriteeriA7 from '../kriteerikortit/perustaso/PerustasonKriteeriA7';
import PerustasonKriteeriA8 from '../kriteerikortit/perustaso/PerustasonKriteeriA8';

import EdistyneentasonKriteeriB1 from '../kriteerikortit/edistynyttaso/EdistyneentasonKriteeriB1';
import EdistyneentasonKriteeriB2 from '../kriteerikortit/edistynyttaso/EdistyneentasonKriteeriB2';
import EdistyneentasonKriteeriB3 from '../kriteerikortit/edistynyttaso/EdistyneentasonKriteeriB3';
import EdistyneentasonKriteeriB4 from '../kriteerikortit/edistynyttaso/EdistyneentasonKriteeriB4';
import EdistyneentasonKriteeriB5 from '../kriteerikortit/edistynyttaso/EdistyneentasonKriteeriB5';
import EdistyneentasonKriteeriB6 from '../kriteerikortit/edistynyttaso/EdistyneentasonKriteeriB6';
import EdistyneentasonKriteeriB7 from '../kriteerikortit/edistynyttaso/EdistyneentasonKriteeriB7';
import EdistyneentasonKriteeriB8 from '../kriteerikortit/edistynyttaso/EdistyneentasonKriteeriB8';
import EdistyneentasonKriteeriB9 from '../kriteerikortit/edistynyttaso/EdistyneentasonKriteeriB9';

import EdistyneentasonKriteeriC1 from '../kriteerikortit/edistynyttaso/EdistyneentasonKriteeriC1';
import EdistyneentasonKriteeriC2 from '../kriteerikortit/edistynyttaso/EdistyneentasonKriteeriC2';

import HuipputasonKriteeriD1 from '../kriteerikortit/huipputaso/HuipputasonKriteeriD1';


import Footer from '../components/Footer';

/* kriteerikortit-sivu, jossa listattuna kaikki tiedot kriteerikorteista */

class Kriteerikortit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      openKriteeri: [],
    };
  }

  componentDidMount() {
    // Tarkistaa onko urlissa ID:tä (esim. kriteerikorttiA1)
    const hash = window.location.hash;
    if (hash && hash.match(/kriteerikortti[A-Z]\d+/)) {
      const match = hash.match(/kriteerikortti([A-Z])(\d+)/); // Etsi ID matchaava osa
      if (match) {
        const letter = match[1]; // Erottele kirjain
        const number = parseFloat(match[2]); // Erottele numero ja muuta lukuksi
        const id = `${letter}${number}`; // Yhdistä kirjain ja numero
        this.setState({ openKriteeri: [id] }); // Lisää this.stateen, jotta mittari aukeaa

        // Scrollaa sivun kyseisen kriteerikortin kohdalle
        const element = document.getElementById(`kriteerikortti${id}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
    }));
  };

  handleKriteerikorttiClick = (index) => (event) => {
    const { openKriteeri } = this.state;
    const isOpen = openKriteeri.includes(index.toString());
    let newOpenKriteeri;

    console.log(index)
    console.log(openKriteeri)
    if (isOpen) {
      newOpenKriteeri = openKriteeri.filter((item) => item !== index.toString());
    } else {
      newOpenKriteeri = [...openKriteeri, index.toString()];
    }

    this.setState({ openKriteeri: newOpenKriteeri });
  };


  render() {
    const { menuOpen, openKriteeri } = this.state;

    return (
      <div>
        <div className='kriteerikortit-container'>
          <div className='kriteerikortit-buttons'>
            <GoBackArrow />
            <div>
              <div className={`hamburger-menu ${menuOpen ? 'open' : ''}`}>
                <button className="hamburger-menu-button" onClick={this.toggleMenu}>
                  ☰
                </button>
                <div className="hamburger-menu-content">
                  <ul className="hamburger-menu-list">
                    <li>
                      <a href="#perustaso">Perustaso</a>
                      <ul className="hamburger-submenu-list">
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiA1">A1 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiA2">A2 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiA3">A3 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiA4">A4 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiA5">A5 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiA6">A6 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiA7">A7 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiA8">A8 kriteerikortti</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#edistynyt-taso">Edistynyt taso</a>
                      <ul className="hamburger-submenu-list">
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiB1">B1 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiB2">B2 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiB3">B3 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiB4">B4 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiB5">B5 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiB6">B6 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiB7">B7 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiB8">B8 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiB9">B9 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiC1">C1 kriteerikortti</a>
                        </li>
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiC2">C2 kriteerikortti</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#huipputaso">Huipputaso</a>
                      <ul className="hamburger-submenu-list">
                        <li>
                          <a href="/mitvidikriteerikortit#kriteerikorttiD1">D1 kriteerikortti</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>



          <h1 className='kriteerikortit-topheader'>MitViDi-Kriteerikortit</h1>
          <div className="kriteerikortit-text-container">

            Arviointikehikko muodostuu konkreettisesti joukosta kriteerikortteja, joiden sisältö on muodostettu hankkeen aikana. Yksittäisessä kortissa esitellään tietty ohjelmiston kestävyyteen
            liittyvä tekijä. Tästä tekijästä annetaan yleinen kuvaus, ehdotus kriteeristä, mahdollinen esimerkki ja muuta tietoa sen käytöstä ja merkittävyydestä. Kriteerikortteihin kerätty tieto
            on tarkasteltavissa tällä sivulla, jaoteltuna arviointikehikon eri tasoille.  Työkalussa esitetään tiivistetty versio jokaisesta kriteerikortista.

          </div>

          <h2 id="perustaso" className='kriteerikortti-second-header'>Perustaso</h2>
          <div className="kriteerikortit-text-container">
            Perustaso koostuu kokoelmasta kriteerejä, jotka voidaan rinnastaa ohjelmiston toiminnallisiin vaatimuksiin, eli ne kuvaavat, minkälaisia konkreettisia toimintoja järjestelmässä
            tulisi olla. Arviointikehikossa lähdetään siitä oletuksesta, että esitellyt toiminnot hyvin todennäköisesti pienentävät järjestelmän virrankulutusta sen käytön aikana ja aiheuttavat
            mahdollisesti välillisesti myös muita järjestelmän kestävyyden kannalta positiivisia vaikutuksia. Perustason kriteerit on valittu niin, että hankkijalta ei edellytetä aiempaa kokemusta
            ympäristökriteerien käytöstä eikä syvällistä tietämystä tietojärjestelmien teknisestä toiminnasta. Kriteerit voidaan esittää osana järjestelmävaatimuksia ja niiden toteutumisen seuranta
            on monissa tapauksissa suoraviivaista – toimintojen olemassaolo todetaan järjestelmän testauksen tai käytön yhteydessä.
          </div>

          <div className='accordion-container'>

            <div id="kriteerikorttiA1">
              <PerustasonKriteeriA1
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>
            <div id="kriteerikorttiA2">
              <PerustasonKriteeriA2
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>
            <div id="kriteerikorttiA3">
              <PerustasonKriteeriA3
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>
            <div id="kriteerikorttiA4">
              <PerustasonKriteeriA4
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>
            <div id="kriteerikorttiA5">
              <PerustasonKriteeriA5
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>
            <div id="kriteerikorttiA6">
              <PerustasonKriteeriA6
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>
            <div id="kriteerikorttiA7">
              <PerustasonKriteeriA7
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>

            <div id="kriteerikorttiA8">
              <PerustasonKriteeriA8
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>
          </div>


          <h2 id="edistynyt-taso" className='kriteerikortti-second-header'>Edistynyt taso</h2>
          <div className="kriteerikortit-text-container">
            Edistyneen tason kriteerit voidaan jakaa kahteen osaan. Ensinnäkin kriteereissä tarkastellaan hankittavan ohjelmiston arkkitehtuuriin liittyviä vaatimuksia, jotka edistävät järjestelmän
            positiivisia ympäristövaikutuksia. Nämäkin vaatimukset voidaan esittää osana järjestelmävaatimuksia. Toisena kohtana edistyneen tason kriteereissä tarkastellaan järjestelmätoimittajan
            oman toiminnan ympäristöystävällisyyttä. Varsinaisen hiilijalanjäljen lisäksi kehittäjäorganisaation kestävän ohjelmistokehityksen osaaminen vaikuttaa siihen, kuinka hyvin
            ympäristökysymykset otetaan kehityksen aikana huomioon ja kuinka hyvin lopputuloksena syntyvä järjestelmä toteuttaa sovittuja ympäristökriteereitä.

          </div>

          <div className='accordion-container'>
            <div id="kriteerikorttiB1">
              <EdistyneentasonKriteeriB1
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>

            <div id="kriteerikorttiB2">
              <EdistyneentasonKriteeriB2
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>

            <div id="kriteerikorttiB3">
              <EdistyneentasonKriteeriB3
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>

            <div id="kriteerikorttiB4">
              <EdistyneentasonKriteeriB4
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>

            <div id="kriteerikorttiB5">
              <EdistyneentasonKriteeriB5
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>

            <div id="kriteerikorttiB6">
              <EdistyneentasonKriteeriB6
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>
            <div id="kriteerikorttiB7">
              <EdistyneentasonKriteeriB7
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>
            <div id="kriteerikorttiB8">
              <EdistyneentasonKriteeriB8
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>
            <div id="kriteerikorttiB9">
              <EdistyneentasonKriteeriB9
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>
            <div id="kriteerikorttiC1">
              <EdistyneentasonKriteeriC1
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>
            <div id="kriteerikorttiC2">
              <EdistyneentasonKriteeriC2
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>
          </div>
          <h2 id="huipputaso" className='kriteerikortti-second-header'>Huipputaso</h2>
          <div className="kriteerikortit-text-container">
            Huipputason kriteerit edellyttävät järjestelmän eri osien virrankulutuksen mittausta ja näin ollen vaativat
            hankinnan osapuolilta syvempää teknistä ymmärrystä järjestelmän toiminnasta. Huipputasolla ajatuksena on
            seurata järjestelmän komponenttien (edustajärjestelmä, verkkoliikenne, omassa konesalissa tai pilvessä pyörivä
            taustajärjestelmä) virrankulutusta aktiivisesti sekä kehitystyön että järjestelmän ylläpidon aikana ja pyrkiä
            optimoimaan järjestelmän toimintaa virrankulutuksen pienentämiseksi. Ideaalimaailmassa virrankulutukselle
            voitaisiin jo hankintavaiheessa määritellä raja-arvot erityyppisiä järjestelmiä varten. Koska tällä hetkellä
            tällaisia viitearvoja ei ole vielä olemassa, voidaan huipputason kriteerit ottaa huomioon kehitys– ja ylläpitotyötä
            koskevien toimintatapavaatimusten avulla. Konesalien toiminnan suhteen voidaan lisäksi nojautua EU-tason
            konesalistandardeihin ja niiden sertifiointeihin.
          </div>
          <div className='accordion-container'>
            <div id="kriteerikorttiD1">
              <HuipputasonKriteeriD1
                openKriteeri={openKriteeri}
                handleKriteerikorttiClick={this.handleKriteerikorttiClick}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  };
};

export default Kriteerikortit;