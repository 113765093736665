import React, { Component } from 'react';
import DownloadResultsButton from '../components/DownloadResultsButton';
import CategoryButton from '../components/CategoryButton';
import "../index.css";
import Footer from '../components/Footer';


import GoBackArrow from '../components/GoBackArrow';

import AccordionitPerustaso from '../components/AccordionitPerustaso';
import AccordionitEdistynyttaso from '../components/AccordionitEdistynyttaso';
import AccordionitHuipputaso from '../components/AccordionitHuipputaso';


/* Käytetään ohjelmisto-kategoria parin kriteerien määrittelyysä */
/* 0 = valmisohjelmisto
   1 = räätälöity ohjelmisto
   2 = Palvelu- ja konsultointihankinta
    */
const buttonCategoryToCheckboxes = {
  "0-Perustaso": ["A2", "A3", "A4", "A6", "A7", "A8"],
  "0-Edistynyt taso": ["A2", "A3", "A4", "A6", "A7", "A8", "B1", "B3", "B5", "B8", "B9"],
  "0-Huipputaso": ["A2", "A3", "A4", "A6", "A7", "A8", "B1", "B3", "B5", "B8", "B9"],
  "1-Perustaso": ["A2", "A3", "A4", "A6", "A8"],
  "1-Edistynyt taso": ["A2", "A3", "A4", "A6", "A8", "B1", "B2", "B3", "B4", "B5", "B6", "B7", "B8", "B9"],
  "1-Huipputaso": ["A2", "A3", "A4", "A6", "A8", "B1", "B2", "B3", "B4", "B5", "B6", "B7", "B8", "B9", "C1", "C2", "D1"],
  "2-Perustaso": [],
  "2-Edistynyt taso": ["B4", "B7"],
  "2-Huipputaso": ["B4", "B7", "C1", "C2", "D1"],
};

// hankkijan kysely sivun pääkomponentti
class MitvidiTyokalu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pdfNotes: "",
      checkboxStates: {},
      selectedButton: null, // Ohjelmiston tyyppi (TODO: vaihda kuvaavampi nimi)
      selectedCategory: null, // Taso (perus,edistynyt,huippu)
      openAccordion: [],
      accordionCheckboxes: {
        "A1": false,
        "A2": false,
        "A3": false,
        "A4": false,
        "A5": false,
        "A6": false,
        "A7": false,
        "A8": false,
        "B1": false,
        "B2": false,
        "B3": false,
        "B4": false,
        "B5": false,
        "B6": false,
        "B7": false,
        "B8": false,
        "B9": false,
        "C1": false,
        "C2": false,
        "D1": false
      },
      hankinta: null
    };
  }

  handleUserInputChange = (event) => {
    const pdfNotes = event.target.value;
    this.setState({ pdfNotes });
  };

  // käsittelee valmisohjelmisto, räätälöity ohjelmisto ja palvelumuotoilu painikkeiden painamiset
  handleButtonClick = (buttonIndex) => {
    const { selectedButton } = this.state;
    if (selectedButton === buttonIndex) {
      // resettaa kriteerit kun ohjelmistovalitna poistetaan
      this.setState({
        selectedButton: null,
        selectedCategory: null,
        checkboxStates: {},
      });
    } else {
      // resettaa kriteerit kun ohjelmiston tyyli vaihtuu
      this.setState(
        {
          selectedButton: buttonIndex,
          selectedCategory: null,
          checkboxStates: {},
        },
        () => {
          this.handleResetCheckboxes();
        }
      );
    }
  };


  // käsittelee accordionin avaamisen
  handleAccordionClick = (index) => (event) => {
    if (event.target.tagName.toLowerCase() === 'input') {
      return; // älä huomio input klikkauksia
    }

    // tarkistaa onko accordion jo auki
    const isOpen = this.state.openAccordion.includes(index);

    // uusi lista jonka avulla päivitetään accordionit
    let newOpenAccordions;
    if (isOpen) {
      // poista klikattu accordion avatuista
      newOpenAccordions = this.state.openAccordion.filter(
        (accordionIndex) => accordionIndex !== index
      );
    } else {
      // lisää uusi accordion avattuihin
      newOpenAccordions = [...this.state.openAccordion, index];
    }

    // päivitä state päivitettyjen accordionien perusteella
    this.setState({ openAccordion: newOpenAccordions });
  };

  // Käsittelee yksittäisten accordionin valinnan/poiston
  handleAccordionCheck = (event) => {
    const { value, checked } = event.target;
    this.setState((prevState) => ({
      checkboxStates: {
        ...prevState.checkboxStates,
        [value]: checked,
      },
    }));
  };

  /* Vaihtaa buttonCategoryToCheckboxes määritellyt kriteerit trueksi riippuen ohjelmisto-kategoria parista*/
  updateCheckboxesBasedOnSelection = () => {
    const { selectedButton, selectedCategory, checkboxStates } = this.state;
    const updatedAccordionCheckboxes = { ...checkboxStates };

    if (selectedButton !== null && selectedCategory !== null) {
      const checkboxKeys = buttonCategoryToCheckboxes[`${selectedButton}-${selectedCategory}`];
      if (checkboxKeys) {
        checkboxKeys.forEach((key) => {
          updatedAccordionCheckboxes[key] = true;
        });
      }
    }

    return updatedAccordionCheckboxes;
  };

  handleResetCheckboxes = () => {
    const updatedCheckboxes = this.updateCheckboxesBasedOnSelection();
    this.setState({ checkboxStates: updatedCheckboxes });
  };



  // Käsittelee kategorian valinnan/poiston
  handleCategorySelection = (category) => {
    const { selectedButton } = this.state;
    const checkboxStates = {}; /* Tyhjentää aikaisemman ohjelmisto-taso parin aikana tehdyt valinnat */

    if (selectedButton !== null) {
      const { selectedCategory } = this.state;
      if (selectedCategory === category) {
        this.setState({ selectedCategory: null, checkboxStates });
      } else {
        this.setState({ selectedCategory: category, checkboxStates });
      }
    }
  };

  handleHankintaChange = (event) => {
    const newHankinta = event.target.value;
    this.setState({ hankinta: newHankinta });
  };




  render() {
    const { selectedButton, selectedCategory, openAccordion, hankinta, pdfNotes } = this.state;

    return (
      <div className="hankkija-kysely-container">
        <h1 className="hankkija-kysely-header">MitViDi-työkalu</h1>
        <div className="hankkija-kysely-text-container">
          Arviointikehikon pohjalta on muodostettu ohjelmistohankintoihin sopivia hankintakriteerejä.
          Hankintakriteerien tarkasteluun työstettiin avoimesti saatavilla oleva työkalu tukemaan kestävien ohjelmistohankintojen tekemistä.
          Työkalua voivat hyödyntää julkisten hankkijoiden lisäksi ohjelmistoalan yritykset oman palvelunsa, tuotteensa tai toimintansa
          ympäristövaikutusten arvioimiseksi.

          <br /> <br />

          Työkalun tarkoitus on mahdollistaa hankkijalle ympäristövaikutuksiin liittyvien kriteerien läpikäynti
          hallitusti ja opastuksen kanssa.Työkalu antaa hankkijan valita hankkeelleen sopivan haastavuustason vaatimuksille ja yleisesti valikoida
          hankintaan soveltuvia vaatimuksia.Koska ohjelmistoja on erityyppisiä ja niitä voidaan hankkia ohjelmiston elinkaaren eri vaiheissa, kaikki
          vaatimukset eivät sovi kaikkiin ohjelmistoihin. Työkalu auttaa tämän kanssa tarjoamalla ennalta määriteltyjä vaihtoehtoja.

          <br /> <br />

          Työkalun käyttäjä valitsee, mitkä näistä kriteereistä huomioidaan ohjelmistohankinnassa. Valittujen kriteerien perusteella työkalu generoi vaatimusten
          toteutukseen liittyvät ohjeet. Käyttäjä voi joko valita vapaasti kriteereistä haluamansa tai käyttää ennalta määriteltyjä vaihtoehtoja.
          Valittujen kriteerien perusteella käyttäjälle generoidaan PDF-dokumentti, joka sisältää ohjeet niiden käyttämiseen ohjelmistohankinnassa.

          <br /> <br />

          Vaikkakin työkalu on pääasiassa suunnattu hankkijalle, voi sitä hyödyntää myös toimittajat ja yleisesti kestävästä tietojenkäsittelystä kiinnostuneet.
          Toimittajalle tärkeää on tutustua eri kriteereihin, työkalusta saatavan yhteenvedon sisältöön, sekä yhteenvedosta löytyviin toimittajan ohjeisiin.
          Kestävästä tietojenkäsittelystä kiinnostuneille tärkeintä on tutustua luotuihin kriteereihin ja muuhun portaalissa saatavilla olevaan tietoon.

        </div>

        <div>
          <div className="hankkija-kysely-ohjelmisto-button-container">
            {/* Ohjhelmistontyyppi napit */}
            <CategoryButton
              selected={selectedButton === 0}
              onClick={() => this.handleButtonClick(0)}
              label="Valmisohjelmisto"
            />
            <CategoryButton
              selected={selectedButton === 1}
              onClick={() => this.handleButtonClick(1)}
              label="Räätälöity ohjelmisto"
            />
            <CategoryButton
              selected={selectedButton === 2}
              onClick={() => this.handleButtonClick(2)}
              label="Palvelu- ja konsultointihankinta"
            />
          </div>


          {/* kategorianapit */}
          <div className="hankkija-kysely-category-button-container">
            <CategoryButton
              selected={selectedCategory === "Perustaso"}
              onClick={() => this.handleCategorySelection("Perustaso")}
              label="Perustaso"
              disabled={selectedButton === null}
            />
            <CategoryButton
              selected={selectedCategory === "Edistynyt taso"}
              onClick={() => this.handleCategorySelection("Edistynyt taso")}
              label="Edistynyt taso"
              disabled={selectedButton === null}
            />
            <CategoryButton
              selected={selectedCategory === "Huipputaso"}
              onClick={() => this.handleCategorySelection("Huipputaso")}
              label="Huipputaso"
              disabled={selectedButton === null}
            />
          </div>

          <div className="accordion-container">
            <AccordionitPerustaso
              accordionCheckboxes={this.updateCheckboxesBasedOnSelection()}
              openAccordion={openAccordion}
              handleAccordionCheck={this.handleAccordionCheck}
              handleAccordionClick={this.handleAccordionClick}
            />

            <AccordionitEdistynyttaso
              accordionCheckboxes={this.updateCheckboxesBasedOnSelection()}
              openAccordion={openAccordion}
              handleAccordionCheck={this.handleAccordionCheck}
              handleAccordionClick={this.handleAccordionClick}
            />

            <AccordionitHuipputaso
              accordionCheckboxes={this.updateCheckboxesBasedOnSelection()}
              openAccordion={openAccordion}
              handleAccordionCheck={this.handleAccordionCheck}
              handleAccordionClick={this.handleAccordionClick}
            />


            {/* Accordion containerin sisällä, jotta css-pysyy samana accordionien kanssa */}
            <div className="accordion-topheader"> <h2>Muistiinpanot:</h2> </div>
            <textarea
              value={this.state.pdfNotes}
              onChange={this.handleUserInputChange}
              className="resizable-textarea"
              placeholder="Tähän voi lisätä kriteereihin liittyviä muistiinpanoja PDF:n"
            />
          </div>
          <div>
            <p className="hankinnan-input-container">
              <span className="hankinnan-input-label"><strong>Hankinnan nimi:</strong> </span>
              <input
                type="text"
                value={hankinta || ''}
                onChange={this.handleHankintaChange}
                className="hankinnan-text-input"
                aria-label="Hankinnan nimi"
              />
            </p>
          </div>
        </div>
        <DownloadResultsButton checkboxes={this.updateCheckboxesBasedOnSelection()} hankintaName={hankinta} pdfNotes={pdfNotes} />
        <GoBackArrow />
        <Footer />
      </div>
    );
  }
}

export default MitvidiTyokalu;