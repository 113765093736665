import React from "react";

const EdistynytKriteeriB9Tekstit = {
  id: "B9",
  nimi: <text>B9 Kriteerikortti: Välimuistin tehokas käyttö</text>,
  kriteeri:
    <text>
      Ohjelmisto käyttää paikallista ja palvelimen välimuistia hyödykseen ohjelmiston käyttöön tarvittavan tiedonsiirron pienentämiseksi.
    </text>,
  plainKriteeri:
    "Ohjelmisto käyttää paikallista ja palvelimen välimuistia hyödykseen ohjelmiston käyttöön tarvittavan tiedonsiirron pienentämiseksi.",
  kriteerinLyhytKuvaus:
    <text>
      Välimuistia käytetään yleisesti tietoliikenteen työkaluna, joka vähentää siirrettävän tiedon määrää ja nopeuttaa resurssien latausta. Resurssin siirtäminen
      verkon kautta on aina hitaampaa kuin sen hakeminen paikallisesta muistista. Sekä käyttäjän päätelaitteen että palvelimen puolella voidaan hyödyntää välimuistia. Sen
      käyttäminen tehokkaasti ei kuitenkaan ole yksiselitteinen asia, ja siihen tulisi panostaa.
    </text>,
  perustelu:
    <text>
      Välimuistin kerrannaisvaikutukset ovat merkittävät, jos käyttäjillä on syytä vierailla toistuvasti samalla sivulla tai muuten käyttää tiettyjä resurssia
      uudestaan sivustolla vieraillessa. Staattisen sivun tarjoaminen palvelimen välimuistin kautta on myös paljon tehokkaampaa kuin vastaavan sivun generoiminen
      dynaamisesti joka vierailun yhteydessä.
    </text>,
  plainPerustelu:
    "Välimuistin kerrannaisvaikutukset ovat merkittävät, jos käyttäjillä on syytä vierailla toistuvasti samalla sivulla tai muuten käyttää tiettyjä resurssia " +
    "uudestaan sivustolla vieraillessa. Staattisen sivun tarjoaminen palvelimen välimuistin kautta on myös paljon tehokkaampaa kuin vastaavan sivun generoiminen " +
    "dynaamisesti joka vierailun yhteydessä.",
  minkaTyyppisiinEiSovi:
    <text>
      Perinteisiin työpöytäsovelluksiin, joihin ei liity selaimen käyttö. Toisaalta joidenkin alustojen päälle rakennetuissa ohjelmistoissa kehittäjillä saattaa olla rajallinen
      kyky itse vaikuttaa välimuistin käyttöön.
    </text>,
  copyPastevaatimus:
    <text>
      Ohjelmisto käyttää paikallista ja palvelimen välimuistia hyödykseen ohjelmiston käyttöön tarvittavan tiedonsiirron pienentämiseksi.
    </text>,
  arvioKriteerinHinnasta:
    <text>
      Kriteerin toteuttaminen aiheuttaa vähäisiä kustannuksia, sillä se on luontainen osa verkkopohjaisen ohjelmiston kehitystyötä.
    </text>,
  arvioKriteerinYmparisto:
    <text>
      Välimuistin kerrannaisvaikutukset ovat merkittävät, jos käyttäjillä on syytä vierailla toistuvasti samalla sivulla tai muuten käyttää tiettyjä resurssia uudestaan sivustolla
      vieraillessa. Staattisen sivun tarjoaminen palvelimen välimuistin kautta on myös paljon tehokkaampaa kuin vastaavan sivun generoiminen dynaamisesti joka vierailun yhteydessä.
    </text>,
  ohjeistusKriteerinKaytosta:
    <text>
      TBA
    </text>,
  todentaminen:
    <text>
      TBA
    </text>,
  ehdotusSopimuksenSeuranta:
    <text>
      TBA
    </text>,
  edellytyksetHyodyntamiselle:
    <text>
      Sovelluksen tulee olla selainpohjainen tai muuten pystyä hyödyntämään välimuistia ja kehittäjien tulisi pystyä muokkaamaan välimuistin käyttöä sovelluksen kehitysalustan puitteissa.
    </text>,
  esimerkki:
    <text>
      Esimerkissä on välimuistin normaalia käyttöä: Wikipedian sivun lataaminen ensimmäisen kerran, ja uudestaan kun sivun sisältöä on jo välimuistissa. Osa
      tiedostoista, kuten Wikipedian logo, on jo valmiiksi välimuistissa eikä niitä täten ladata uudestaan.
    </text>,
  kuva:
    <picture>
      {/* WebP */}
      <source
        srcSet="/pictures/cacheimage.webp"
        type="image/webp"
      />
      {/* PNG jos webp ei käytössä */}
      <source
        srcSet="/pictures/cacheimage.png"
        type="image/png"
      />
      {/* Fallback */}
      <img
        src="/pictures/cacheimage.png"
        loading="lazy"
        alt="Kuva välimuistin vertailusta"
        className="kriteerikortti-images"
      />
    </picture>,
  mittausJaViitearvot:
    <text>
      Välimuistin käytössä ja sivuston latauksessa ansaittuja säästöjä voidaan mitata esimerkiksi erikseen suunnitteluilla testeillä, ja tietoja voi kerätä sekä selaimen että palvelimen puolelta.
    </text>

};

export default EdistynytKriteeriB9Tekstit;
