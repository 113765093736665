import React from 'react';
import '../index.css';


const Footer = () => {
    return (
        <footer className="footer-container">
            <div className="footer-content">
                <div className="footer-left-content">
                    <p>
                        Hanketta rahoittavat Varsinais-Suomen liitto ja Etelä-Karjalan liitto Uudenmaan liiton koordinoiman Euroopan unionin
                        aluekehitysrahaston kautta. Aluekehitysrahaston tuki on myönnetty hankekumppaneille EU-REACT-erillishaun kautta, osana
                        EU:n covid-19-pandemian johdosta toteuttamia elpymistoimia.
                    </p>
                </div>
                <div className="footer-right-content">
                    <picture>
                        {/* WebP */}
                        <source
                            srcSet="/pictures/MitViDi_Hankekumppanit_banneri_800x300_web.webp"
                            type="image/webp"
                        />
                        {/* PNG jos webp ei käytössä */}
                        <source
                            srcSet="/pictures//pictures/MitViDi_Hankekumppanit_banneri_800x300_web.jpg"
                            type="image/png"
                        />
                        {/* Fallback */}
                        <img
                            src="/pictures/MitViDi_Hankekumppanit_banneri_800x300_web.jpg"
                            loading="lazy"
                            alt="Banneri, jossa kaikkien hankekumppaneiden logot"
                            className="kriteerikortti-images"
                        />
                    </picture>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
