import React from "react";

const HuipputasonKriteeriC1Tekstit = {
    id: "C1",
    nimi: <text>C1 Kriteerikortti: Ohjelmiston tuottaja käyttää ISO 14024 tyypin 1 mukaisia ICT-laitteita toiminnassaan. </text>,
    kriteeri:
        <text>
            Tuottajaorganisaatio on sitoutunut hankkimaan laitekantansa tuotteina, joilla on ISO 14024 tyypin 1 ympäristömerkki. Laitteet sisältävät
            kaiken ICT-laitteiston kuten työasemat, näytöt, kannettavat, älypuhelimet ja täppärit. Tyypin 1 merkkejä ovat esimerkiksi Joutsenmerkki,
            EU:n ympäristömerkki, TCO Certified ja Blue Angel.
        </text>,
    plainKriteeri:
        "Tuottajaorganisaatio on sitoutunut hankkimaan laitekantansa tuotteina, joilla on ISO 14024 tyypin 1 ympäristömerkki. Laitteet sisältävät " +
        "kaiken ICT-laitteiston kuten työasemat, näytöt, kannettavat, älypuhelimet ja täppärit. Tyypin 1 merkkejä ovat esimerkiksi Joutsenmerkki, " +
        "EU:n ympäristömerkki, TCO Certified ja Blue Angel. ",
    kriteerinLyhytKuvaus:
        <text>
            Tuottajaorganisaatio on sitoutunut hankkimaan laitekantansa tuotteina, joilla on ISO 14024 tyypin 1 ympäristömerkki. Laitteet sisältävät kaiken ICT-laitteiston
            kuten työasemat, näytöt, kannettavat, älypuhelimet ja täppärit. Tyypin 1 merkkejä ovat esimerkiksi Joutsenmerkki, EU:n ympäristömerkki, TCO Certified ja Blue Angel.
        </text>,
    perustelu:
        <text>
            Etenkin suurempien toimittajien kohdalla siirtymä ympäristömerkittyihin ICT-laitteisiin voi tuottaa laajoja ympäristövaikutuksia. Vaatimuksen
            mukana oleminen luo myös markkinoille signaalin, että ympäristömerkityt laitteet tulevat jatkossa olemaan lisääntyvä vaatimus ja luo painetta
            kaikille toimijoille siirtyä ympäristömerkittyihin laitteisiin.
        </text>,
    plainPerustelu:
        "Etenkin suurempien toimittajien kohdalla siirtymä ympäristömerkittyihin ICT-laitteisiin voi tuottaa laajoja ympäristövaikutuksia. Vaatimuksen " +
        "mukana oleminen luo myös markkinoille signaalin, että ympäristömerkityt laitteet tulevat jatkossa olemaan lisääntyvä vaatimus ja luo painetta " +
        "kaikille toimijoille siirtyä ympäristömerkittyihin laitteisiin.",
    minkaTyyppisiinEiSovi:
        <text>
            Kriteeri sopii kaikkiin hankintoihin.
        </text>,
    kysymyksetHankkijanSuuntaan:
        <text>
            Haluatteko varmistua, että toimittajaorganisaation ohjelmistokehitys on sertifioidusti ympäristöystävällistä?
        </text>,
    copyPastevaatimus:
        <text>
            Toimittaja on sitoutunut hankkimaan toiminnassaan käyttämänsä ICT-laitteet ISO 14024 tyypin 1 ympäristömerkittynä.
        </text>,
    arvioKriteerinHinnasta:
        <text>
            Mittari voidaan edellyttää lähes kaikissa hankinnoissa. Sen hintavaikutus on vähäinen.
        </text>,
    arvioKriteerinYmparisto:
        <text>
            Etenkin suurempien toimittajien kohdalla siirtymä ympäristömerkittyihin ICT-laitteisiin voi tuottaa laajoja ympäristövaikutuksia. Vaatimuksen mukana oleminen luo myös
            markkinoille signaalin, että ympäristömerkityt laitteet tulevat jatkossa olemaan lisääntyvä vaatimus ja luo painetta kaikille toimijoille siirtyä ympäristömerkittyihin laitteisiin.
        </text>,
    ohjeistusKriteerinKaytosta:
    <text>
      TBA
    </text>,
    todentaminen:
    <text>
      TBA
    </text>,
    ehdotusSopimuksenSeuranta:
    <text>
      TBA
    </text>,
    edellytyksetHyodyntamiselle:
        <text>
            Määrittely laitetyypeistä, joilta ympäristömerkki vaaditaan.
        </text>,
    esimerkki:
        <text>
            Lista TCO Certified merkin omaavista ICT-laitteista löytyy TCO:n Product Finder työkalusta: https://tcocertified.com/product-finder/
        </text>,
    mittausJaViitearvot:
        <text>
            Kuinka suuri osuus ICT-laitteista täyttää kriteerin, esimerkiksi 20 % osuuksina (20 – 40 – 60 – 80 – 100 %).
        </text>,
    lisatiedotJaLahteet:
        <text>
            Lista ISO 14024 tyypin 1 ympäristömerkeistä: https://www.ecogloballabel.org/download/GLOBAL_ECO_LABELS_LISTESI.pdf
        </text>
};

export default HuipputasonKriteeriC1Tekstit;
