import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

import "@fontsource/roboto";
import "@fontsource/roboto/700.css";


ReactDOM.render(
  <App />,
  document.getElementById('root')
);
