import React from "react";

const HuipputasonKriteeriC2Tekstit = {
    id: "C2",
    nimi: <text>C2 Kriteerikortti: Ohjelmiston toimittajan toiminta on hiilineutraalia Scope 1 ja 2 osalta GHG protokollan mukaan</text>,
    kriteeri:
        <text>
            GHG protokollan scope 1 ja 2 käsittävät organisaation suorat ja epäsuorat päästöt, mutta eivät arvoketjun päästöjä. Hiilineutraalisuus
            tässä tapauksessa tarkoittaa päästöjen vähentämistä mahdollisimman pieniksi ja loppujen kompensointia.
        </text>,
    plainKriteeri:
        "GHG protokollan scope 1 ja 2 käsittävät organisaation suorat ja epäsuorat päästöt, mutta eivät arvoketjun päästöjä. Hiilineutraalisuus " +
        "tässä tapauksessa tarkoittaa päästöjen vähentämistä mahdollisimman pieniksi ja loppujen kompensointia.",
    kriteerinLyhytKuvaus:
        <text>
            GHG protokollan scope 1 ja 2 käsittävät organisaation suorat ja epäsuorat päästöt, mutta eivät arvoketjun päästöjä. Hiilineutraalisuus tässä tapauksessa
            tarkoittaa päästöjen vähentämistä mahdollisimman pieniksi ja loppujen kompensointia.
        </text>,
    perustelu:
        <text>
            GHG protokollan scope 1 ja 2 käsittävät organisaation suorat ja epäsuorat päästöt, mutta eivät arvoketjun päästöjä. Hiilineutraalisuus
            tässä tapauksessa tarkoittaa päästöjen vähentämistä mahdollisimman pieniksi ja loppujen kompensointia.
        </text>,
    plainPerustelu:
        "GHG protokollan scope 1 ja 2 käsittävät organisaation suorat ja epäsuorat päästöt, mutta eivät arvoketjun päästöjä. Hiilineutraalisuus " +
        "tässä tapauksessa tarkoittaa päästöjen vähentämistä mahdollisimman pieniksi ja loppujen kompensointia.",
    minkaTyyppisiinEiSovi:
        <text>
            Kriteeri Sopii kaiken tyyppisiin järjestelmiin.
        </text>,
    kysymyksetHankkijanSuuntaan:
        <text>
            Haluatteko varmistua, että ohjelmiston toimittajan toiminta on hiilineutraalia?
        </text>,
    copyPastevaatimus:
        <text>
            Ohjelmiston toimittajan tulee olla hiilineutraali omien suorien ja epäsuorien päästöjensä osalta. Nämä päästöt on määritelty GHG protokollan scope 1 ja scope 2 määrityksissä.
        </text>,
    arvioKriteerinHinnasta:
        <text>
            Kriteerin toteutuminen ei maksa hankkijalle mitään.
        </text>,
    arvioKriteerinYmparisto:
        <text>
            Hiilineutraalisuudella ja sen vaatimisella oman toiminnan suorien ja epäsuorien päästöjen osalta on kohtuullisen kokoinen vaikutus.
        </text>,
    ohjeistusKriteerinKaytosta:
        <text>
          TBA
        </text>,
    todentaminen:
        <text>
          TBA
        </text>,
    ehdotusSopimuksenSeuranta:
        <text>
          TBA
        </text>,
    edellytyksetHyodyntamiselle:
        <text>
            Määrittely päästöistä, joiden osalta halutaan hiilineutraalisuus.
        </text>,
    esimerkki:
        <text>
            Päästölaskenta on tehty itse ja perustuu laskelmiin liiketoiminnan aidoista päästölähteistä. Laskelmissa on otettu huomioon toimistotila, laitteet, käytetty sähkö, käytetty lämpö ja
            ilmastointi. Tuloksena saatua lukua on optimoitu vaihtamalla sähkö ilmastoystävälliseksi (ydinvoima, uusiutuvat) ja loput päästöt on kompensoitu 4H puuntaimiohjelman kautta.
        </text>,
    mittausJaViitearvot:
        <text>
            Päästöt hiilidioksidiekvivalenttina, päästökompensaation määrä.
        </text>,
    lisatiedotJaLahteet:
        <text>
            GHG Protocol Scope 1 and 2 guidance: https://www.epa.gov/climateleadership/scope-1-and-scope-2-inventory-guidance
        </text>

};

export default HuipputasonKriteeriC2Tekstit;
