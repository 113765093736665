import React from 'react';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import PerustasonMittariA1Tekstit from '../tekstit/perustaso/PerustasonKriteeriA1Tekstit';
import PerustasonMittariA2Tekstit from '../tekstit/perustaso/PerustasonKriteeriA2Tekstit';
import PerustasonMittariA3Tekstit from '../tekstit/perustaso/PerustasonKriteeriA3Tekstit';
import PerustasonMittariA4Tekstit from '../tekstit/perustaso/PerustasonKriteeriA4Tekstit';
import PerustasonMittariA5Tekstit from '../tekstit/perustaso/PerustasonKriteeriA5Tekstit';
import PerustasonMittariA6Tekstit from '../tekstit/perustaso/PerustasonKriteeriA6Tekstit';
import PerustasonMittariA7Tekstit from '../tekstit/perustaso/PerustasonKriteeriA7Tekstit';
import PerustasonMittariA8Tekstit from '../tekstit/perustaso/PerustasonKriteeriA8Tekstit';

import EdistynytKriteeriB1Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB1Tekstit';
import EdistynytKriteeriB2Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB2Tekstit';
import EdistynytKriteeriB3Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB3Tekstit';
import EdistynytKriteeriB4Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB4Tekstit';
import EdistynytKriteeriB5Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB5Tekstit';
import EdistynytKriteeriB6Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB6Tekstit';
import EdistynytKriteeriB7Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB7Tekstit';
import EdistynytKriteeriB8Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB8Tekstit';
import EdistynytKriteeriB9Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriB9Tekstit';

import EdistynytKriteeriC1Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriC1Tekstit';
import EdistynytKriteeriC2Tekstit from '../tekstit/edistynyttaso/EdistynytKriteeriC2Tekstit';


import HuipputasonKriteeriD1Tekstit from '../tekstit/huipputaso/HuipputasonKriteeriD1Tekstit';


import RegularButton from './RegularButton';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

/* Päivämäärän lisäykseen käytetty funktio */
function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${date}/${month}/${year}`;
}

/* PDF:n latauspainike, latauksen logiikka, eli PDF:n sisältöön vaikuttavat ehdot */
const DownloadResultsButton = ({ checkboxes, hankintaName, pdfNotes }) => {
  const today = getDate();


  // improvements = hankinnan nimi ja kyselyjen tulokset otsikko, jotka lisätään palautepdf:n ekalle sivulle
  var improvements = [{ text: "MitViDi-työkalun yhteenveto", style: "header" }]
  improvements.push({ text: `Päivämäärä: ${today} \n`, style: "hankinta" });
  improvements.push({ text: `Lisätietoa MitViDi-hankkeesta \n`, link: "https://mitvidi.tt.utu.fi/", style: "hankinta", decoration: "underline", color: 'blue' });

  /* Jos hankinnalle on asetettu nimi, merkkaa se headerin alapuolelle, jos ei älä lisää mitään */
  if (hankintaName !== null && hankintaName.trim() !== "") {
    improvements.push({ text: `Hankinnan nimi: ${hankintaName} \n`, style: "hankinta" });
  }


  // PDF:n latauspainike käyttäen Pdfmake-kirjastoa
  const DownloadPdfButton = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.src = process.env.PUBLIC_URL + '/pictures/MitViDi_Hankekumppanit_banneri_800x300_web.webp';

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const imageData = canvas.toDataURL(); // Muuttaa kuvan base64:ksi
      var doc = {

        content: [
          improvements,
          {
            image: 'logo',
            width: 500,
            heigth: 500,
            alignment: 'center'
          },
        ],

        styles: {
          header: {
            fontSize: 20,
            bold: true,
            alignment: 'center',
            margin: [5, 0, 0, 20]
          },
          subheader: {
            fontSize: 18,
            bold: true
          },
          hankinta: {
            fontSize: 14,
            bold: true,
          },

          kriteeri: {
            fontSize: 12,
            bold: true,
          },
          tekstinAlku: {
            fontSize: 12,
            bold: true
          },
          text: {
            fontSize: 12,
          },
        },

        images: {
          logo: imageData,
        }
      }
      /* jos hankintaname = null niin tiedoston nimi vain mitvidi_yhteenveto, muutoin hankinnan nimi mukana */
      const fileName = hankintaName !== null && hankintaName.trim() !== "" ? `${hankintaName}_mitvidi_yhteenveto` : 'mitvidi_yhteenveto';

      pdfMake.createPdf(doc).download(fileName);  // Muokkaa ladattavan pdf-tiedoston nimen
    }
  }

  const convertToString = (obj) => {
    if (obj === null) {
      return '';
    } else if (typeof obj === 'string') {
      return obj;
    } else if (Array.isArray(obj)) {
      return obj.map((item) => convertToString(item)).join('');
    } else if (typeof obj === 'object') {
      return Object.values(obj).map((value) => convertToString(value)).join('');
    } else {
      return String(obj);
    }
  };





  // Lisää PDF:n tekstiä sen perusteella mitkä chechboxeista on True (mitkä accordionit on valittu)
  const handleDownload = () => {
    let content = ['\n']
    if (checkboxes["A1"]) {
      const kriteeriText = convertToString(PerustasonMittariA1Tekstit.plainKriteeri);
      const perusteluText = convertToString(PerustasonMittariA1Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: " A1 Ohjelmiston toiminta offline-tilassa \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ],
        }
      );
    }
    if (checkboxes["A2"]) {
      const kriteeriText = convertToString(PerustasonMittariA2Tekstit.plainKriteeri);
      const perusteluText = convertToString(PerustasonMittariA2Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "A2 Kapasiteetin käyttö ja vapautus ohjelmiston taustajärjestelmässä \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }
    if (checkboxes["A3"]) {
      const kriteeriText = convertToString(PerustasonMittariA3Tekstit.plainKriteeri);
      const perusteluText = convertToString(PerustasonMittariA3Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "A3 Ohjelmisto vapauttaa kapasiteettia tarvittaessa \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }
    if (checkboxes["A4"]) {
      const kriteeriText = convertToString(PerustasonMittariA4Tekstit.plainKriteeri);
      const perusteluText = convertToString(PerustasonMittariA4Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "A4 Ohjelmiston ja laitteen virranhallintaominaisuudet \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }
    if (checkboxes["A5"]) {
      const kriteeriText = convertToString(PerustasonMittariA5Tekstit.plainKriteeri);
      const perusteluText = convertToString(PerustasonMittariA5Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "A5 Loppukäyttäjän mahdollisuus seurata ohjelmiston kuormitusta \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
      console.log('1.1')
    }
    if (checkboxes["A6"]) {
      const kriteeriText = convertToString(PerustasonMittariA6Tekstit.plainKriteeri);
      const perusteluText = convertToString(PerustasonMittariA6Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "A6 Ylläpitäjän mahdollisuus seurata ohjelmiston kuormitusta \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }

    if (checkboxes["A7"]) {
      const kriteeriText = convertToString(PerustasonMittariA7Tekstit.plainKriteeri);
      const perusteluText = convertToString(PerustasonMittariA7Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "A7 Ohjelmisto sallii asetusten muokkaamisen \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }
    if (checkboxes["A8"]) {
      const kriteeriText = convertToString(PerustasonMittariA8Tekstit.plainKriteeri);
      const perusteluText = convertToString(PerustasonMittariA8Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "A8 Ohjelmiston oletusasetusten kytkös kokonaisvirrankulutukseen \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }





    /* edistynyt taso */
    if (checkboxes["B1"]) {
      const kriteeriText = convertToString(EdistynytKriteeriB1Tekstit.plainKriteeri);
      const perusteluText = convertToString(EdistynytKriteeriB1Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "B1 Ohjelmiston toimivat rajapinnat \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }
    if (checkboxes["B2"]) {
      const kriteeriText = convertToString(EdistynytKriteeriB2Tekstit.plainKriteeri);
      const perusteluText = convertToString(EdistynytKriteeriB2Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "B2 Ohjelmiston taaksepäin yhteensopivuus \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }
    if (checkboxes["B3"]) {
      const kriteeriText = convertToString(EdistynytKriteeriB3Tekstit.plainKriteeri);
      const perusteluText = convertToString(EdistynytKriteeriB3Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "B3 Tulevaisuuden ennakointi \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }
    if (checkboxes["B4"]) {
      const kriteeriText = convertToString(EdistynytKriteeriB4Tekstit.plainKriteeri);
      const perusteluText = convertToString(EdistynytKriteeriB4Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "B4 Avoimuus \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }
    if (checkboxes["B5"]) {
      const kriteeriText = convertToString(EdistynytKriteeriB5Tekstit.plainKriteeri);
      const perusteluText = convertToString(EdistynytKriteeriB5Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "B5 Tekninen optimointi \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }
    if (checkboxes["B6"]) {
      const kriteeriText = convertToString(EdistynytKriteeriB6Tekstit.plainKriteeri);
      const perusteluText = convertToString(EdistynytKriteeriB6Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "B6 Käytettävyyden optimointi \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }
    if (checkboxes["B7"]) {
      const kriteeriText = convertToString(EdistynytKriteeriB7Tekstit.plainKriteeri);
      const perusteluText = convertToString(EdistynytKriteeriB7Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "B7 Ohjelmistonkehityksen tekniset valinnat pyrkivät tehokkuuteen \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }
    if (checkboxes["B8"]) {
      const kriteeriText = convertToString(EdistynytKriteeriB8Tekstit.plainKriteeri);
      const perusteluText = convertToString(EdistynytKriteeriB8Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "B8 Bottiliikenteen hallinnointi \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }
    if (checkboxes["B9"]) {
      const kriteeriText = convertToString(EdistynytKriteeriB9Tekstit.plainKriteeri);
      const perusteluText = convertToString(EdistynytKriteeriB9Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "B9 Välimuistin tehokas käyttö \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }

    /*Huipputaso */
    if (checkboxes["C1"]) {
      const kriteeriText = convertToString(EdistynytKriteeriC1Tekstit.plainKriteeri);
      const perusteluText = convertToString(EdistynytKriteeriC1Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "C1 Ohjelmiston tuottaja käyttää ISO 14024 tyypin 1 mukaisia ICT-laitteita toiminnassaan. \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }
    if (checkboxes["C2"]) {
      const kriteeriText = convertToString(EdistynytKriteeriC2Tekstit.plainKriteeri);
      const perusteluText = convertToString(EdistynytKriteeriC2Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "C2 Ohjelmiston toimittajan toiminta on hiilineutraalia Scope 1 ja 2 osalta GHG protokollan mukaan. \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }
    if (checkboxes["D1"]) {
      const kriteeriText = convertToString(HuipputasonKriteeriD1Tekstit.plainKriteeri);
      const perusteluText = convertToString(HuipputasonKriteeriD1Tekstit.plainPerustelu);
      content.push(
        {
          text: [
            { text: "D1 Virrankulutus \n", style: "subheader" },

            { text: "Kriteeri: ", style: "tekstinAlku" },
            {
              text: kriteeriText + "\n \n",
              style: "text",
            },
            { text: "Perustelu: ", style: "tekstinAlku" },
            {
              text: perusteluText + "\n \n",
              style: "text",
            },
          ]
        },
      )
    }
    improvements.push(content)


    /*Hankkijan itse asettamat muistiinpanot */
    if (pdfNotes !== "" && hankintaName.trim() !== "") {
      improvements.push([{ text: "Hankkijan muistiinpanot", style: "header", pageBreak: 'before' },
      {
        text: pdfNotes, style: "text"
      }]);
    }



    // PDF:n kolmannelle sivulle lisätään geneeriset ohjeet siitä, miten kriteeri kannattaa toteuttaa ja sen toteutumista seurata
    // pagebreak before = ohjeet aloitetaan uudelta sivulta
    improvements.push([{ text: "Ohjeet toimittajalle / alihankkijalle", style: "header", pageBreak: 'before' },
    { text: "Ohjeistus kriteerin käytöstä hankinnan aikana \n", style: "subheader" },
    {
      text: "Esitetään lista kysymyksiä joihin toimittajan tulisi vastata toimittamalla dokumentaatiota. " +
        "Mitattavien tekijöiden kanssa voidaan mitata arvoja. Vastauksista saa kriteeristä riippuen tietyn " +
        "määrän pisteitä tai niitä voidaan pitää minimivaatimuksina joita täyttämätön toimittaja karsitaan " +
        "harkinnasta. \n \n \n",
      style: "text"
    },
    { text: "Kriteerin toteutumisen seuranta hankinnan aikana \n", style: "subheader" },
    {
      text: "Yleisesti ottaen toimittaja tarjoaa dokumentaatiota kriteerin täyttymisestä kriteerikohtaisesti " +
        "määritetyn menetelmän mukaan. Seurantaa toteutetaan säännöllisesti kehityksen aikana. Todennetaan erikseen " +
        " sovelluksen julkaisun yhteydessä ja ylläpidon aikana tilanteeseen vaikuttavien päivitysten yhteydessä. \n \n \n",
      style: "text"
    }
    ])
    DownloadPdfButton()
  };

  return (
    <div>
      <RegularButton
        label={"Lataa yhteenveto PDF:nä"}
        onClick={handleDownload}
        disabled={!Object.values(checkboxes).some(value => value)} /* Ei voi ladata tyhjää pdf:ää ilman kriteeriä */
        title={
          (!Object.values(checkboxes).some(value => value))
            ? "Kriteerejä on oltava valittuna vähintään yksi ennen lataamista"
            : undefined
        }
      />
    </div>
  );
}

export default DownloadResultsButton;