import React from "react";

const EdistynytKriteeriB6Tekstit = {
  id: "B6",
  nimi: <text>B6 Kriteerikortti: Käytettävyyden optimointi</text>,
  kriteeri:
    <text>
      Käyttäjien tulee suoriutua tehtävistään ohjelmiston kanssa onnistuneesti, mahdollisimman virheettömästi ja nopeasti.
    </text>,
  plainKriteeri:
    "Käyttäjien tulee suoriutua tehtävistään ohjelmiston kanssa onnistuneesti, mahdollisimman virheettömästi ja nopeasti.",
  kriteerinLyhytKuvaus:
    <text>
      Käyttäjäkeskeisten suunnittelumenetelmien periaatteiden mukaisesti käyttäjille tarjottavien toiminnallisuuksien tulee perustua todellisiin tarpeisiin. Käyttäjälle
      tarpeettomien toiminnallisuuksien toteuttaminen, ylläpitäminen ja jatkuva saavutettavuus aiheuttavat turhaa resurssien kulutusta. <br />

      Toteutettavien toiminnallisuuksien osalta tavoitteena on saada niiden käyttö käyttäjälle mahdollisimman sujuvaksi, ja siten minimoida ohjelmiston käyttöaikaa ja vähentää virrankulutusta.
      Käyttöaikaa voidaan minimoida esimerkiksi vähentämällä käyttäjän tekemien syötteiden määrää, optimoimalla navigointipolkuja ja informaatioarkkitehtuuria tai estämällä
      virheellisten syötteiden tekemistä. Tämän kriteerin tarkoituksena on vähentää virrankulutusta varmistamalla ohjelmiston riittävä käytettävyys, missä huomioidaan erityisesti
      ohjelmiston käytön onnistuneisuus ja käyttöajan ja käyttövirheiden minimointi.
    </text>,
  perustelu:
    <text>
      Ohjelmiston käyttöön kuluvaa aikaa minimoimalla voidaan vähentää energiankulutusta. Käyttöaikaa minimoidaan parantamalla
      käytettävyyttä, esimerkiksi vähentämällä käyttäjän tekemien syötteiden määrää sekä estämällä virheellisten syötteiden tekemistä.
      Ympäristövaikutukset riippuvat vaatimuksen kohteeksi valittujen käyttötapausten ja -tehtävien lukumäärästä, sekä näiden myöhemmästä
      todellisesta käyttömäärästä (käyttövolyymi ja toistuvuus).
    </text>,
  plainPerustelu:
    "Ohjelmiston käyttöön kuluvaa aikaa minimoimalla voidaan vähentää energiankulutusta. Käyttöaikaa minimoidaan parantamalla " +
    "käytettävyyttä, esimerkiksi vähentämällä käyttäjän tekemien syötteiden määrää sekä estämällä virheellisten syötteiden tekemistä. " +
    "Ympäristövaikutukset riippuvat vaatimuksen kohteeksi valittujen käyttötapausten ja -tehtävien lukumäärästä, sekä näiden myöhemmästä " +
    "todellisesta käyttömäärästä (käyttövolyymi ja toistuvuus).",
  minkaTyyppisiinEiSovi:
    <text>
      Kriteeri sopii valmisohjelmistojen ja räätälöityjen ohjelmistojen hankintaan.
    </text>,
  copyPastevaatimus:
    <text>
      Käyttäjien tulee suoriutua tehtävistään ohjelmiston kanssa onnistuneesti, virheettömästi ja mahdollisimman nopeasti. Vaihtoehtoisia muotoiluja vaatimukselle (ks. Jokela, 2010): <br />
      <strong>a)</strong> Ohjelmiston käytettävyyden tulee olla tasolla, jossa 95 %:n varmuudella voidaan todeta, että vähintään 50 % kohderyhmän käyttäjistä suoriutuu valitusta tehtävästä onnistuneesti. <br />

      <strong>b)</strong> Ohjelmiston käytettävyyden tulee olla tasolla, jossa 95 %:n varmuudella voidaan todeta, että vähintään 50 % kohderyhmän käyttäjistä suoriutuu valitusta tehtävästä
      ilman käyttötilannetta keskeyttäviä tai häiritseviä ongelmia. <br />

      <strong>c)</strong> Ohjelmiston käytettävyyden tulee olla tasolla, jossa 95 %:n varmuudella voidaan todeta, että vähintään 50 % kohderyhmän käyttäjistä suoriutuu valitusta tehtävästä
      määritellyn ajan kuluessa.
    </text>,
  arvioKriteerinHinnasta:
    <text>
      Käytettävyyteen liittyvät kriteerit ovat yleisesti käytettyjä hankinnoissa. Mittari sisällytetään osaksi ohjelmiston toiminnallisia vaatimuksia.
      Kustannukset muodostuvat toteutumisen arvioinnista ja seurannasta kilpailutuksen aikana ja sen jälkeen.
    </text>,
  arvioKriteerinYmparisto:
    <text>
      Ympäristövaikutukset riippuvat vaatimuksen kohteeksi valittujen käyttötapausten ja -tehtävien lukumäärästä, sekä näiden
      myöhemmästä käytön aikaisesta käyttömäärästä (käyttövolyymi ja toistuvuus). <br />

      Esimerkiksi vähennetään ohjelmiston yhden, päivittäin toistuvan tehtävän käyttöaikaa 5 sekuntia (esimerkiksi
      koululaisen päivän kotitehtävien tarkistaminen) siirtämällä tiedon esityspaikkaa käyttöliittymässä ja lisäämättä
      palvelinkutsuja tai prosessointitehoa vaativaa laskentaa muualla. Päivittäisten käyttäjien määrän ollessa esimerkiksi
      100 000, tämä säästö tarkoittaa, että sovellusta käytetään yhteensä 138 tuntia vähemmän yhdessä päivässä. <br />

      Käyttöajan minimoinnin ympäristövaikutukset ovat tapauskohtaisia, eikä edellä mainitussa esimerkissä vaikutukset
      välttämättä ole ollenkaan merkityksellisiä. Vaikutuksiltaan kriittisten tehtävien tunnistaminen on keskeistä. <br />
    </text>,
  ohjeistusKriteerinKaytosta:
    <text>
      TBA
    </text>,
  todentaminen:
    <text>
      TBA
    </text>,
  ehdotusSopimuksenSeuranta:
    <text>
      TBA
    </text>,
  lisatiedotJaLahteet:
    <text>
      Jokela, T. (2010). Determining Usability Requirements into a Call-for-Tenders. A Case Study on the Development of a
      Healthcare System. NordiCHI 2010. <br />

      Tarkkanen, K. &amp; Harkke, V. (2015). Obstacles and Workarounds in Usability Practices during Tendering from IS
      Vendor&#39;s Perspective. 24th European Conference on Information Systems (ECIS). <br />

      Tarkkanen, K., Harkke, V. &amp; Reijonen, P. (2015). Are we testing utility? Analysis of usability problem types. HCI International, Springer.

    </text>

};

export default EdistynytKriteeriB6Tekstit;
