import React from 'react';
import '../index.css';

/* Palaa yhden urlin takaisinpäin osoitteen mukaan */

const GoBackArrow = () => {
  const goBack = () => {
    const currentPath = window.location.pathname;
    const parts = currentPath.split('/').filter(part => part !== '');
    if (parts.length > 1) {
      parts.pop(); // Poistaa viimeisen /osan/ urlista
      const newPath = `/${parts.join('/')}`;
      window.location.href = newPath; // Menee uuteen urliin
    } else {
      window.location.href = '/'; // muissa tapauksissa juureen
    }
  };


  return (
    <div className="go-back-arrow" onClick={goBack} title="Palaa takaisin">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M24 11l-10-9v7h-14v4h14v7z" />
      </svg>
    </div>
  );
};

export default GoBackArrow;
