import React from "react";

const EdistynytKriteeriB1Tekstit = {
  id: "B1",
  nimi: <text>B1 Kriteerikortti: Ohjelmiston toimivat rajapinnat</text>,
  kriteeri:
    <text>
      Ohjelmiston API on selkeästi dokumentoitu, ja ohjelmiston rajapintojen käyttö on mahdollista kehittäjäorganisaation ulkopuolella.
    </text>,
  plainKriteeri:
    "Ohjelmiston API on selkeästi dokumentoitu, ja ohjelmiston rajapintojen käyttö on mahdollista kehittäjäorganisaation ulkopuolella.",
  kriteerinLyhytKuvaus:
    <text>
      Rajapinnat (API, application programming interface) mahdollistavat tietojärjestelmien komponenttien keskinäisen viestinnän. Rajapinnan käyttäjän ei
      pitäisi tarvita tietää, miten rajapinta on toteutettu. Rajapintojen tehokkaan käytön merkittävimmät ympäristövaikutukset ovat ohjelmiston elinkaaren
      pidentäminen ja jatkokehityksen sekä toimittajaorganisaation vaihtamisen mahdollistaminen. <br />

      Dokumentaatio on keskeinen osa rajapintoja, ja määrittää mm. kuinka vaikeaa niiden kehitys ja ylläpito on. Jatkettavat rajapinnat ovat sellaisia, joiden toimintaa voidaan muuttaa ja
      kehittää uusin tarkoituksiin rikkomatta toiminnallisuuksia olemassa oleville käyttäjille. Kriteerin ympäristövaikutukset ilmenevät epäsuorasti: hyvin suunnitellut rajapinnat vähentävät
      toimittajaloukkuja, tarvetta ohjelmiston korvaamiselle ja lisäävät hankkijaorganisaation autonomiaa. <br />

      Rajapinnan dokumentaation tulisi sisältää tietoa siitä, mistä kohtaa rajapintaa voi jatkaa, ja kertoa miten jatkokset tulee käsitellä. Käytettävien rajapintojen tulisi olla helppokäyttöisiä,
      sopeutua tarkoitukseensa ja antaa käyttäjälle tietoa itsestään. Tämä tarkoittaa sitä että, aloittelijat ja tyypilliset käyttäjät voivat käyttää rajapintaa.
    </text>,
  perustelu:
    <text>
      Kriteerin merkittävät vaikutukset ovat toimittajaloukkujen välttäminen, pienempi tarve ohjelmiston korvaamiselle sekä hankkijaorganisaation autonomia.
      Merkittävin ympäristövaikutus ilmentyy epäsuorasti siitä, että ohjelmiston uusimisen tai vaihtamisen tarve pienenee ja sen elinkaari pitenee.
    </text>,
  plainPerustelu:
    "Kriteerin merkittävät vaikutukset ovat toimittajaloukkujen välttäminen, pienempi tarve ohjelmiston korvaamiselle sekä hankkijaorganisaation autonomia. " +
    "Merkittävin ympäristövaikutus ilmentyy epäsuorasti siitä, että ohjelmiston uusimisen tai vaihtamisen tarve pienenee ja sen elinkaari pitenee.",
  minkaTyyppisiinEiSovi:
    <text>
      Ei sovellu jos tarkoituksenmukaisesti rajapinnat pysyvät kehittäjän hallussa, tai niihin ei ohjelmiston luonteen vuoksi ole pääsyä. Toisaalta
      hankkijaorganisaation tekninen osaaminen määrittää myös kriteerin käytön hyödyllisyyttä ja hankkija ei aina edes halua pääsyä rajapintoihin.
    </text>,
  kysymyksetHankkijanSuuntaan:
    <text>
      Tarvitseeko ohjelmiston tuottamaa dataa siirtää muualle tai käsitellä muissa ohjelmistoissa? <br />
      Onko ainakin osalla ohjelmiston käyttäjistä tarve käyttää sen ohjelmointirajapintaa? <br />
      Haluaako organisaatio kyvyn jatkokehittää rajapintoja?
    </text>,
  copyPastevaatimus:
    <text>
      Ohjelmiston API on selkeästi dokumentoitu, ja ohjelmiston rajapintojen käyttö on mahdollista kehittäjäorganisaation ulkopuolella.
    </text>,
  arvioKriteerinHinnasta:
    <text>
      Lähtökohtaisesti moderni verkkoyhteyksiä käyttävä ohjelmisto kannattaa toteuttaa käyttämään ja tarjoamaan rajapintoja, jolloin niiden avaaminen erilaisille ryhmille on melko
      yksinkertainen prosessi. Kriteerin noudattaminen voi jopa helpottaa kokonaisvaltaisesti ohjelmiston toteutusta.
    </text>,
  arvioKriteerinYmparisto:
    <text>
      Kriteerin merkittävät vaikutukset ovat toimittajaloukkujen välttäminen, pienempi tarve ohjelmiston korvaamiselle sekä hankkijaorganisaation autonomia. Merkittävin
      ympäristövaikutus ilmentyy epäsuorasti siitä, että ohjelmiston uusimisen tai vaihtamisen tarve pienenee ja sen elinkaari pitenee.
    </text>,
  ohjeistusKriteerinKaytosta:
    <text>
      TBA
    </text>,
  todentaminen:
    <text>
      TBA
    </text>,
  ehdotusSopimuksenSeuranta:
    <text>
      TBA
    </text>,
  edellytyksetHyodyntamiselle:
    <text>
      Hankkijaorganisaatiolla on tarpeeksi teknistä pätevyyttä määrittää painoasteikko ja tarve mittarille, sekä hyödyntää ohjelmistoa joka toteuttaa mittarin. <br />
      Käänteisesti, ainoa tilanne jossa ei tarvitse huolehtia asiasta on sovellus joka tekee on the spot -toimintaa
      </text>,
  esimerkki:
    <text>
      GitLab on GitLab Incorporated -nimisen yhtiön kehittämä DevOps-ohjelmistoalusta ohjelmistojen kehitykseen, suojaamiseen ja operointiin. GitLab tukee HTTPS-protokollan yli REST-tyyppistä API-rajapintaa,
      jota käyttäen voidaan suorittaa samoja toimintoja kuin interaktiivisen web-käyttöliittymän kautta.
      Rajapinta on kuvattu avoimesti GitLab:n kotisivulla <a href='https://docs.gitlab.com/ee/api/' target="_blank" rel="noopener noreferrer">(https://docs.gitlab.com/ee/api/)</a> ja
      sen versiointi mahdollistaa rajapinnan laajentamisen tulevaisuuden tarpeiden kehittyessä.
    </text>,
  mittausJaViitearvot:
    <text>
      Rajapintojen laatua ei voida varsinaisesti mitata, mutta arviointimenetelmiä on olemassa.
    </text>,
  kytkeytyvatKriteerit:
    <text>
      Taaksepäin yhteensopivuus, Tulevaisuuden ennakointi, Avoimuus.
    </text>,
  lisatiedotJaLahteet:
    <text>
      How to Design a Good API and Why it Matters, Joshua Block, Google <br />
      A systematic mapping study of API usability evaluation methods, I. Rauf & E. Troubitsyna, I. Porres, 2019
    </text>

};

export default EdistynytKriteeriB1Tekstit;
