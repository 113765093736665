import { Link } from 'react-router-dom';
import React from 'react';

import PerustasonKriteeriA1Tekstit from '../tekstit/perustaso/PerustasonKriteeriA1Tekstit';
import PerustasonKriteeriA2Tekstit from '../tekstit/perustaso/PerustasonKriteeriA2Tekstit';
import PerustasonKriteeriA3Tekstit from '../tekstit/perustaso/PerustasonKriteeriA3Tekstit';
import PerustasonKriteeriA4Tekstit from '../tekstit/perustaso/PerustasonKriteeriA4Tekstit';
import PerustasonKriteeriA5Tekstit from '../tekstit/perustaso/PerustasonKriteeriA5Tekstit';
import PerustasonKriteeriA6Tekstit from '../tekstit/perustaso/PerustasonKriteeriA6Tekstit';
import PerustasonKriteeriA7Tekstit from '../tekstit/perustaso/PerustasonKriteeriA7Tekstit';
import PerustasonKriteeriA8Tekstit from '../tekstit/perustaso/PerustasonKriteeriA8Tekstit';

/* Perustason kriteerit mitvidikysely sivulle */

const AccordionitPerustaso = ({ accordionCheckboxes, openAccordion, handleAccordionCheck, handleAccordionClick }) => {

    return (
        <div>
            <div className="accordion-topheader"> <h2>1. Perustaso</h2> </div>
            <div className={`accordion-item ${openAccordion.includes(PerustasonKriteeriA1Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(PerustasonKriteeriA1Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={PerustasonKriteeriA1Tekstit.id}
                        checked={accordionCheckboxes[PerustasonKriteeriA1Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {PerustasonKriteeriA1Tekstit.nimi} </p>


                    {openAccordion.includes(PerustasonKriteeriA1Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {PerustasonKriteeriA1Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {PerustasonKriteeriA1Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {PerustasonKriteeriA1Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {PerustasonKriteeriA1Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {PerustasonKriteeriA1Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiA1" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>



            <div className="accordion-subheader"> <h3>Virranhallinta</h3> </div>



            <div className={`accordion-item ${openAccordion.includes(PerustasonKriteeriA2Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(PerustasonKriteeriA2Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={PerustasonKriteeriA2Tekstit.id}
                        checked={accordionCheckboxes[PerustasonKriteeriA2Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {PerustasonKriteeriA2Tekstit.nimi} </p>


                    {openAccordion.includes(PerustasonKriteeriA2Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {PerustasonKriteeriA2Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {PerustasonKriteeriA2Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {PerustasonKriteeriA2Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {PerustasonKriteeriA2Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {PerustasonKriteeriA2Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiA2" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>

            <div className={`accordion-item ${openAccordion.includes(PerustasonKriteeriA3Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(PerustasonKriteeriA3Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={PerustasonKriteeriA3Tekstit.id}
                        checked={accordionCheckboxes[PerustasonKriteeriA3Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {PerustasonKriteeriA3Tekstit.nimi} </p>


                    {openAccordion.includes(PerustasonKriteeriA3Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {PerustasonKriteeriA3Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {PerustasonKriteeriA3Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {PerustasonKriteeriA3Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {PerustasonKriteeriA3Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {PerustasonKriteeriA3Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiA3" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>
            <div className={`accordion-item ${openAccordion.includes(PerustasonKriteeriA4Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(PerustasonKriteeriA4Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={PerustasonKriteeriA4Tekstit.id}
                        checked={accordionCheckboxes[PerustasonKriteeriA4Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {PerustasonKriteeriA4Tekstit.nimi} </p>


                    {openAccordion.includes(PerustasonKriteeriA4Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {PerustasonKriteeriA4Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {PerustasonKriteeriA4Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {PerustasonKriteeriA4Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {PerustasonKriteeriA4Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {PerustasonKriteeriA4Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiA4" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>

            <div className="accordion-subheader"> <h3>Käyttäjän tukeminen</h3> </div>

            <div className={`accordion-item ${openAccordion.includes(PerustasonKriteeriA5Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(PerustasonKriteeriA5Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={PerustasonKriteeriA5Tekstit.id}
                        checked={accordionCheckboxes[PerustasonKriteeriA5Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {PerustasonKriteeriA5Tekstit.nimi} </p>


                    {openAccordion.includes(PerustasonKriteeriA5Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {PerustasonKriteeriA5Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {PerustasonKriteeriA5Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {PerustasonKriteeriA5Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {PerustasonKriteeriA5Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {PerustasonKriteeriA5Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiA5" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>





            <div className={`accordion-item ${openAccordion.includes(PerustasonKriteeriA6Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(PerustasonKriteeriA6Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={PerustasonKriteeriA6Tekstit.id}
                        checked={accordionCheckboxes[PerustasonKriteeriA6Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {PerustasonKriteeriA6Tekstit.nimi} </p>


                    {openAccordion.includes(PerustasonKriteeriA6Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {PerustasonKriteeriA6Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {PerustasonKriteeriA6Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {PerustasonKriteeriA6Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {PerustasonKriteeriA6Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {PerustasonKriteeriA6Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiA6" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>
            <div className={`accordion-item ${openAccordion.includes(PerustasonKriteeriA7Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(PerustasonKriteeriA7Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={PerustasonKriteeriA7Tekstit.id}
                        checked={accordionCheckboxes[PerustasonKriteeriA7Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {PerustasonKriteeriA7Tekstit.nimi} </p>


                    {openAccordion.includes(PerustasonKriteeriA7Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {PerustasonKriteeriA7Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {PerustasonKriteeriA7Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {PerustasonKriteeriA7Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {PerustasonKriteeriA7Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {PerustasonKriteeriA7Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiA7" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>
            <div className={`accordion-item ${openAccordion.includes(PerustasonKriteeriA8Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(PerustasonKriteeriA8Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={PerustasonKriteeriA8Tekstit.id}
                        checked={accordionCheckboxes[PerustasonKriteeriA8Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {PerustasonKriteeriA8Tekstit.nimi} </p>


                    {openAccordion.includes(PerustasonKriteeriA8Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {PerustasonKriteeriA8Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {PerustasonKriteeriA8Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {PerustasonKriteeriA8Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {PerustasonKriteeriA8Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {PerustasonKriteeriA8Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiA8" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>
                </div>
            </div>
        </div>
    );
};





export default AccordionitPerustaso