import React from 'react';

import GoBackArrow from '../components/GoBackArrow';
import Footer from '../components/Footer'

/* Mittausjärjestely sivu, jossa kuvataan millä laitteistolla mittaukset on tehty */

const Mittausjarjestely = () => {
    return (
        <div>
            <div className='kriteerikortit-container'>
                { /* Classnamet samoja kuin kriteerikortit sivulla, koska sama layout */}
                <h1 className='kriteerikortit-topheader'>MitViDi-virranmittauksen mittausjärjestely</h1>

                <div className="kriteerikortit-text-container">
                    Tämä oppaan osa kuvaa MitViDi — Mittarit vihreän digitalisaation julkisiin
                    ICT-hankintoihin-hankkeen osana kehitetyn empiirisen mittausjärjestely virrankulutuksen
                    määrittämiseksi.

                    <br /> <br />
                    Opas jakaantuu seuraaviin osioihin:
                    <br /> <br />

                    <ol>
                        <li><a href="#mittaus-prosessi"><b>Virranmittausprosessin yleiskuvaus</b></a></li>
                        <li><a href="#laite-kartoitus"><b>Virtamittauslaitteistojen kartoitus</b></a></li>
                        <li><a href="#laite-suositus"><b>Virtamittauslaitteistojen hankintasuositus</b></a>
                        <ul>
                            <li><a href="#laite-sut"><b>SUT hankintasuositus</b></a></li>
                            <li><a href="#laite-mittari"><b>Mittarin hankintasuositus</b></a></li>
                            <li><a href="#laite-malli"><b>Mallikokoonpanot</b></a></li>
                        </ul>
                        </li>
                        <li><a href="#laite-arkkitehtuuri"><b>Mittauskokoonpanon kytkennän arkkitehtuurikuvaus</b></a></li>
                        <li><a href="#ohjelma-mittaus"><b>Ohjelmistopohjainen virranmittaus</b></a></li>
                    </ol>
                </div>

                <hr/>
                
                <h2 id="mittaus-prosessi" className='kriteerikortti-second-header'>Virranmittausprosessin yleiskuvaus</h2>

                <div className="kriteerikortit-text-container">

                    <p><b>Virranmittauksen yksinkertainen prosessi</b> käsittää järjestelmän käyttöskenaarioista lähtevien mittaustapausten määrittelyn, mittausjärjestelyn sekä siihen liittyvien skriptien rakentamisen ja mittausten ajamisen. Mittausten
                    tulosdata analysoidaan ja tarvittaessa käytetään uusien käyttöskenaarioiden tunnistamiseksi. Prosessi on kuvattu
                    seuraavassa:</p>
                    
                    <picture>
                        <source
                            srcSet="/pictures/process.png"
                            type="image/png"
                        />
                        {/* Fallback */}
                        <img
                            src="/pictures/process.png"
                            loading="lazy"
                            alt="Yleiskuva virranmittausprosessista"
                            className="kriteerikortti-images"
                        />
                    </picture>

                    <p>Eri mittausten aikasarjoja yhdessä mittausten painotusten kanssa voidaan käyttää
                    yhdessä tuotantojärjestelmän kulutusmittausten kanssa luotaessa arvio järjestelmän
                    kokonaisvirrankulutuksesta. Sähkön tuotantotapa huomioon ottaen voidaan laatia
                    <b>arvio ohjelmiston ympäristöystävällisyydestä ja CO2-ekvivalenssista</b>.</p>
                    
                    <picture>
                        <source
                            srcSet="/pictures/process2.png"
                            type="image/png"
                        />
                        {/* Fallback */}
                        <img
                            src="/pictures/process2.png"
                            loading="lazy"
                            alt="Yleiskuva virranmittausprosessin ympäristöystävällisyyden arvioinnin perusteista"
                            className="kriteerikortti-images"
                        />
                    </picture>

                    <p><b>Virranmittauksen mittaussuunnitelma</b> käyttää MitViDi-hankkeen mittausmenetelmää
                    esimerkiksi ohjelmiston yleisen käytön aiheuttaman virrankulutuksen karkeaa arviointia varten.
                    Päämääränä on sekä pyrkiä testaamaan virtamittauksen toimivuutta ja toisaalta kerätä
                    tietoa erinäisten ohjelmistojen ympäristöystävällisyydestä.</p>

                    <br/>

                    <p>Käytännössä käyttöskenaarioiden perusteella kirjoitetaan testausskripti, joka matkii
                    ihmiskäyttäjän toimintaa mahdollisimman hyvin mutta toimii automaattisesti toistaen
                    joka kerta samat toiminnot. Näitä ajetaan mittauksessa olevalla laitteella, ja joiden
                    aikana käyttöskenaariot toistuvat kymmeniä kertoja. Tämä tarkoittaa, että mittalaitteen
                    testikäyttäjän pitää päästä käsiksi skenaarioissa toteuttaviin toiminnallisuuksiin ongelmitta.</p>

                    <br/>
                    
                    <p>Käyttöskenaarioiden aikaisen mittausdatan pohjalta voidaan tehdä karkeita arvioita käytön
                    aiheuttamasta virrankulutuksesta, ja esimerkiksi tarkastella kuinka vakaasti järjestelmä
                    toimii virrankulutuksen osalta toteutettaessa samoja toimintoja. Skenaariot on muodostettu
                    arvioimalla mitattavan järjestelmän pääasiallisia käyttäjäryhmiä ja tulokset painotetaan
                    tärkeimpien ja useimpien toistuvien skenaarioiden tai toimintojen perusteella.</p>

                    <br/>
                    
                    <p>Taustajärjestelmästä voidaan haluta kerätä kaikkea saatavilla olevaa resurssinkäyttöä
                    kuvaavaa dataa testikäytön ajalta.
                    Erityisesti data virrankulutuksen, tai palvelimen resurssien kulutuksesta testiskenaarioiden
                    takia on arvokasta. Prosessorin, keskusmuistin ja tietoliikenteen käyttö ovat merkittävimmät
                    tekijät ympäristövaikutuksille, joten pelkästään näiden kanssa on myös mahdollista karkeasti
                    arvioida aiheutettua virrankulutusta. Näitä haetaan mitattavan palvelinympäristön kautta.</p>
                    
                </div>
                
                <hr/>
                    
                <h2 id="laite-kartoitus" className='kriteerikortti-second-header'>Virtamittauslaitteistojen kartoitus</h2>

                <div className="kriteerikortit-text-container">
                    <p>Virtamittauslaitteistojen kartoituksen tarkoitus oli selvittää mahdollisia tapoja
                    mitata yleiskäyttöisesti ohjelmiston virrankäyttöä sekä markkinoilta saatavissa olevia
                    mittalaitteistoja, joiden käyttöönotto on mahdollista ohjelmistohankinnassa ilman aiempaa
                    erityisosaamista virranmittaamisesta. Koska mittauslaitteistojen hinta voi nousta verrattain
                    korkeaksi erityisesti pienissä hankinnoissa, olemme rajanneet tarkempaan tarkasteluun
                    yksikköhinnaltaan alle 500 euron tuotteita (osaa laitteista toimitetaan vain EU-alueen
                    ulkopuolelta, mikä myös nostaa kustannuksia). Mittalaitteistoja hankkiessa on syytä muistaa,
                    että järjestelmään suunnitellun mittaamisen laajuus voi johtaa tarpeeseen hankkia useita
                    mittareita eri järjestelmän osille ja/tai kehittäjäkohtaisesti.</p>
                    
                    <br />
                    
                    <p>Mittauslaitteistoja kartoitettaessa luokiteltiin mittarit seuraaviin kategorioihin:
                    1) Vaihtovirtamittarit, 2) mittavirtalähteet (virtalähteeseen integroitu virtamittaus),
                    3) virtalähteeseen kytkettävät mittarit, 4) virranmittauspiirit ja 5)
                    sisäänrakennetut mittarit.</p>

                    <br />
                    
                    <p><b>Vaihtovirtamittari</b> on yksinkertaisin käyttää - mittari kytketään kiinteistön virransyötön ja
                    mitattavan tietoteknisen laitteen tai laitekokonaisuuden väliin. Sertifioitu vaihtovirtamittari
                    on myös soveltuva esimerkiksi joihinkin laitesaleihin ja ympäristöihin, joihin monimutkaisten
                    kytkentöjen asentaminen ei ole mahdollista. Vaihtovirtamittaus myös soveltuu hyvin monenlaisten
                    laitteiden mittaamiseen, sillä monia laitteita voidaan syöttää vaihtovirralla.</p>

                    <br />
                    
                    <p>Vaihtovirtamittarin on tuettava virranmittausta verrattain laajalla skaalalla, sillä laitteiden ja
                    laitekokonaisuuksien virrankulutus voi olla merkittävää. Mittari ei välttämättä pysty mittaamaan
                    suuritehoisimpia laitteita. Vaihtovirtamittarin suurin heikkous on epätarkkuus. Kaikki mitattavat
                    laitteet eivät toimi ideaalina resistiivisena kuormana, joka voi aiheuttaa epälineaarisuutta ja
                    epätarkkuutta. Lisäksi digitaaliset laitteet usein varastoivat sisäisesti virtaa, joka aiheuttaa
                    ajallista epätarkkuutta.</p>

                    <br />
                    
                    <p>MitViDi-hankkeessa arvioitiin myös vaihtovirtamittausta, mutta varsinaisen mittausjärjestelyn
                    kuvauksessa vaihtovirtamittaus tarjotaan vaihtoehtoisena tapana suorittaa mittaus.</p>

                    <br />
                    
                    <p>Erilaisia vaihtovirtamittareita on laajasti käytössä ja kodin älytekniikan yleistymisen myötä
                    monia eri älykoti-ekosysteemien vaihtovirtamittareita on saatavilla eri kodin elektroniikan
                    kaupoista. Ohjelmiston virrankulutuksen mittaamista varten näiden suurin rajoite voi olla
                    mittaamisen hienojakoisuuden ja tarkkuuden (esim. ajallisesti useita minuutteja tai tunteja)
                    lisäksi tiedon koneellinen lukeminen osaksi dokumentoitavaa ja toistettavaa mittausprosessia.
                    </p>

                    <br />

                    <p><b>Mittavirtalähde</b> tai muu laboratoriovirtalähde on virtalähde, johon on interoitu virtamittaus.
                    Tunnistimme luokittelua varten mittavirtalähteitä, mutta rajasimme ne tarkastelun ulkopuolelle, sillä
                    mittavirtalähteen kustannus voi olla useita satoja tai tuhansia euroja per mitattava kanava
                    (jännitetyyppi). Mittavirtalähteet sopivat erikoissovelluksiin, joissa mittarilta vaaditaan suurta
                    tarkkuutta. Yleiskäyttöiseen virranmittaukseen niiden kustannus saattaa nousta liian suureksi ja
                    toisaalta käyttö rajoittuu yksittäisen tasavirtapiirin mittaukseen.</p>

                    <br />
                    
                    <p>Hankkeen kontekstin kannalta vaihtovirtamittauksen ohella monipuolisin mittarityyppi
                    ovat <b>virtalähteeseen kytkettävät mittarit</b>. Mittareita on karkeasti
                    kahdenlaisia. <b>Tasavirtalähteeseen kytkettävä mittari</b> voidaan asettaa tarjoamaan yhtä tai useampaa
                    kanavaa pitkin eri jännitetasoja mitattavien laitteiden käyttöön. Mittari kanavoi oman virtalähteensä virtaa.
                    Mittarin tukema jänniteväli tyypillisesti kattaa akkukäyttöisten ja/tai verkkovirtaan kytkettävien
                    tietoteknisten laitteiden sisäisesti käyttämän tasajännitteen vaihteluvälin. Mitattava jänniteväli
                    on myös yleensä myös mittalaitteen oman virtalähteen rajoittama.</p>

                    <br />
                    
                    <p>Toinen yleinen mittarityyppi on <b>USB-liitäntäinen kytkettävä virtamittari</b>, joka vastaavalla tavalla
                    kanavoi USB-väylän virran mitattavalle laitteelle tai laitteille. USB-väylä myös itsessään käsittää
                    karkean menetelmän virrankäytön mitoittamiseen laitteille. USB-virtamittari rajoittuu
                    USB-väylällä varustettuihin laitteisiin, mutta toisaalta varsinkin EU:n lainsäädännön ja linjausten
                    myötä USB on yleistynyt yleiskäyttöisenä virtaliittimenä erityisesti akkuvirtaisissa laitteissa.</p>

                    <br />
                    
                    <p>MitViDi-hankkeessa kartoitettiin useita virtalähteeseen kytkettäviä mittareita. Mittareiden sopivuutta
                    arvioitiin niiden tukeman mitattavan jännite- ja virta-alueen lisäksi mittaustarkkuuden
                    (näytteistystaajuus), hinnan, datankeruun helppouden, yleiskäyttöisyyden ja kalibroitavuuden perusteella.
                    Kartoituksen pohjalta suositeltuun mittausjärjestelyyn poimittiin suositeltavat virtamittarit.</p>

                    <br />
                    
                    <p>Lisäksi MitViDi-hankkeessa kartoitettiin <b>virranmittauspiirejä</b>, joilla tarkoitamme yksittäisiä
                    virtamittaukseen kykeneviä mikropiirejä. Virtamittaukseen on monenlaisia komponentteja, mutta osa
                    komponenteista vaatii virtamittauksen teorian syvällistä tuntemusta sekä kalibrointia muita, kalibroituja
                    mittalaitteita käyttäen. Virtamittaukseen on myös tehdaskalibroituja piirejä, mutta myös niiden käyttö
                    edellyttää elektroniikan käsittelyä, mikä voi johtaa niiden käyttökelpoisuuden rajallisuuteen
                    yleiskäyttöisessä virtamittaamisessa</p>

                    <br />
                    
                    <p>Kartoituksessa tunnistettiin viisi erityppistä suosittuina rakennussarjoina
                    tarjottavaa virranmittauspiiriä, jotka tarjoavat tehdaskalibroitua mittadataa ja joita voidaan käyttää
                    virtalähteeseen kytkettävän mittarin tavoin joissakin
                    käyttökohteissa. Markkinoilla olevien virranmittauspiirien valikoima ei kuitenkaan rajoitu kartoituksen
                    listaamiin piireihin. Virranmittauspiirien etu on niiden erittäin matala hinta. Piirit voidaan myös integroida
                    kehitettäviin järjestelmiin, mikäli hankinta käsittää laitteistosuunnittelua. Virranmittauspiirit kuitenkin
                    tarvitsevat tietokoneen tai mikrokontrollerin, jota käytetään mittausdatan prosessointiin.</p>

                    <br />

                    <p>Edellisten lisäksi joissakin laitteissa on <b>sisäänrakennettuja virtamittareita</b>, joita voidaan
                    käyttää ohjelmiston tasolla virran mittaamiseen. Virranmittaus voi olla saatavilla komponenttikohtaisesti
                    (esimerkiksi prosessori, näytönohjain, SSD-levy, USB-laitteet, kannettavan tietokoneen näytön taustavalo) tai koko
                    järjestelmän osalta (esim. kannettavan tietokoneen hetkellinen kokonaisvirrankulutus).</p>
                    
                    <br />

                    <p>Kartoituksessa tarkasteltiin seuraavia mittavirtalähteitä ja virtamittareita:</p>

                    <br />
                    
                    <table>
                    <tr>
                        <th>Nimi</th>
                        <th>Hinta</th>
                        <th>Ulostulo</th>
                        <th>Jännite (V)</th>
                        <th>Virta (A)</th>
                        <th>Näytteistys</th>
                        <th>Datankeruu</th>
                        <th>Muuta</th>
                    </tr>
                    <tr>
                        <td><a href="https://www.cnx-software.com/2018/06/04/um25c-better-usb-power-meter-display-bluetooth-android-windows/">UM25C</a></td>
                        <td>~30€</td>
                        <td>USB</td>
                        <td>0 - 24</td>
                        <td>0 - 5</td>
                        <td>500 Hz (matala)</td>
                        <td>Android</td>
                        <td>ei kalibroitu, ei tuettu</td>
                    </tr>
                    <tr>
                        <td><a href="https://www.hardkernel.com/shop/smartpower-iii/">HardKernel SmartPower 3</a></td>
                        <td>&lt;150€</td>
                        <td>DC</td>
                        <td>2 x 3 - 18</td>
                        <td>2 x 0 - 3</td>
                        <td>200 Hz (matala)</td>
                        <td>USB serial</td>
                        <td>ei kalibroitu, tuettu</td>
                    </tr>
                    <tr>
                        <td><a href="https://www.nordicsemi.com/Products/Development-hardware/Power-Profiler-Kit-2">Nordic Semi Power Profiler Kit II</a></td>
                        <td>&lt;150€</td>
                        <td>DC</td>
                        <td>0 - 5</td>
                        <td>0 - 1</td>
                        <td>100 kHz (korkea)</td>
                        <td>PC USB</td>
                        <td>jännitealue alle 12V, virta-alue ei yleiskäyttöinen</td>
                    </tr>
                    <tr>
                        <td><a href="https://powerwerx.com/west-mountain-radio-pwrcheck-plus-usb">Powerwerx PWRcheck+</a></td>
                        <td>&lt;250€</td>
                        <td>DC</td>
                        <td>0 - 60</td>
                        <td>0 - 40</td>
                        <td>?</td>
                        <td>PC USB</td>
                        <td>ei kalibroitu, ei ollut saatavilla hankkeen alussa</td>
                    </tr>
                    <tr>
                        <td><a href="https://www.anglercircuits.com/">ZSCircuits ​ZS-2102-A</a></td>
                        <td>&lt;750€</td>
                        <td>DC</td>
                        <td>0 - 6</td>
                        <td>0 - 1</td>
                        <td>1 MHz (erittäin korkea)</td>
                        <td>PC USB</td>
                        <td>ei kalibroitu, jännitealue alle 12V, virta-alue ei yleiskäyttöinen</td>
                    </tr>
                    <tr>
                        <td><a href="https://www.qoitech.com/otii/">Qoitech Otii Arc</a></td>
                        <td>&lt;1100€</td>
                        <td>DC</td>
                        <td>0 - 5</td>
                        <td>0 - 2.5</td>
                        <td>250 kHz (korkea)</td>
                        <td>PC</td>
                        <td>ei kalibroitu, jännitealue alle 12V, korkea hinta</td>
                    </tr>
                    <tr>
                        <td><a href="https://www.joulescope.com/">Joulescope: Precision DC Energy Analyzer</a></td>
                        <td>&lt;1200€</td>
                        <td>DC</td>
                        <td>0 - 15</td>
                        <td>0 - 3</td>
                        <td>250 kHz (korkea)</td>
                        <td>PC</td>
                        <td>kalibroitu, korkea hinta</td>
                    </tr>
                    <tr>
                        <td><a href="https://www.sistemi.ca/p1125">Sistemi P1125</a></td>
                        <td>&lt;2300€</td>
                        <td>DC</td>
                        <td>1.8 - 8.2</td>
                        <td>0 - 3.2</td>
                        <td>10 kHz</td>
                        <td>PC</td>
                        <td>ei kalibroitu, jännitealue alle 12V, korkea hinta</td>
                    </tr>
                    <tr>
                        <td><a href="">Adafruit Triple Output 195W PSU</a></td>
                        <td>&lt;700€</td>
                        <td>DC</td>
                        <td>0 - 30</td>
                        <td>0 - 3</td>
                        <td>?</td>
                        <td>PC</td>
                        <td>ei kalibroitu, korkea hinta, mittavirtalähde</td>
                    </tr>
                    </table>

                    <br />
                    
                    <p>Kartoituksessa tarkasteltiin seuraavia virranmittauspiirejä:</p>

                    <ul>
                        <li><a href="https://www.adafruit.com/product/1164">INA169</a></li>
                        <li><a href="https://www.adafruit.com/product/904">INA219</a></li>
                        <li><a href="https://www.waveshare.com/current-power-monitor-hat.htm">INA219</a></li>
                        <li><a href="https://www.adafruit.com/product/4226">INA260</a></li>
                        <li><a href="https://github.com/crjens/PiPowerMeter">Cirrus Logic CS5463/5490</a></li>
                    </ul>
                
                </div>

                <hr/>
                
                <h2 id="laite-suositus" className='kriteerikortti-second-header'>Virtamittauslaitteiston hankintasuositus</h2>
                
                <div className="kriteerikortit-text-container">

                    <p>Virtamittauslaitteiston hankintasuosituksen tarkoituksena on kuvata minimaalinen
                    laitteistojärjestely, jolla yksittäinen virtamittaus voidaan suorittaa mahdollista
                    tuotantojärjestelmää mahdollisimman hyvin edustavalla järjestelmällä. Laitteistojärjestely
                    kuvaa joko palvelin- tai päätelaitetta. Mikäli mittaus halutaan ulottaa kattamaan
                    laajempi järjestely, suosituksen kokoonpano toimii muokattavana lähtökohtana.
                    Suosituksesta on suljettu pois vastaavia järjestelmiä, joiden ominaisuudet eroavat
                    merkittävästi (esim. käskykanta) tyypillisestä edustettavasta järjestelmästä
                    (PC / ARM).</p>

                    <br/>
                    
                    <p>Kartoituksessa päädyttiin suosittelemaan seuraavia virtamittareita:</p>

                    <br/>
                    
                    <ul>
                        <li>HardKernel SmartPower 3, laitteille joiden kulutus korkeintaan 50W</li>
                        <li>Powerwerx PWRcheck+, laitteille joiden kulutus korkeintaan 200W</li>
                        <li>Virtamittauspiiri: elektroniikkasuunnittelua vaativaan projektiin INA219, INA260, INA169, CS5463 tai CS5490.</li>
                    </ul>
                    
                    <br />
                    
                    <p>Kartoituksessa päädyttiin suosittelemaan seuraavia mitattavia alustoja:</p>

                    <br/>
                    
                    <ul>
                        <li>PC-laitteisto: <a href="https://up-board.org/up/specifications/">Up Board</a>, <a href="https://www.pcengines.ch/apu2.htm">PC Engines APU2</a>, <a href="https://www.intel.com/content/www/us/en/products/details/nuc.html">Intel NUC</a> tai muu x86-pohjainen matalan kulutuksen alusta, jonka virranotto voidaan rajata yhdelle jännitetasolle (esim. <a href="https://www.mini-box.com/picoPSU-160-XT">PicoPSU</a>:n avulla).</li>
                        <li>ARM-laitteisto: <a href="https://www.raspberrypi.org/">Raspberry Pi</a>, <a href="https://odroid.com/">Odroid</a> tai muu vastaava korttitietokone</li>
                    </ul>
                
                    <picture>
                        {/* WebP */}
                        <source
                            srcSet="/pictures/mittari2c.webp"
                            type="image/webp"
                        />
                        {/* PNG jos webp ei käytössä */}
                        <source 
                            srcSet="/pictures/mittari2c.jpg"
                            type="image/png"
                        />
                        {/* Fallback */}
                        <img
                            src="/pictures/mittari2c.jpg"
                            loading="lazy"
                            alt="Kuva mittausjärjestelyn laitealustaksi sopivista korttitietokoneista"
                            className="kriteerikortti-images"
                        />
                    </picture>
                    
                    <br/>

                    Tätä dokumenttia varten olemme poimineet edellä kuvatuista yhden mittarityypin ja kaksi
                    mitattavan laitteiston (SUT, system under test) laitteistotyyppiä, joita käyttäen voidaan
                    suorittaa edustavia virtamittauksia. Nämä vaihtoehdot esitetään seuraavassa.
                    
                </div>


                <h3 id="laite-sut" className='kriteerikortti-second-header'>SUT (2 vaihtoehtoa)</h3>

                <div className="kriteerikortit-text-container">

                    <p>Mitattavan laitteen (SUT) tarkoitus on edustaa tyypillistä ohjelmistoa ajavaa palvelin- tai
                    päätelaitetta. Virtamittarin toiminnan rajoitteiden vuoksi suositeltava laite on yhtä käyttöjännitettä
                    käyttävä ns. korttitietokone, jonka virrankulutus pysyy mittalaitteiston mittarin käyttöalueen
                    sisällä. Mitattavan laitteiston valikoiman ja määrän laajentamista suositeltujen laitetyyppien
                    ulkopuolelle ei käsitellä tässä oppaassa.</p>

                    <br/>
                    
                    <ul>
                        <li><b>(1) Raspberry Pi 4: <a href='https://rpilocator.com/?cat=PI4' target='_blank' rel='noopener noreferrer'>https://rpilocator.com/?cat=PI4</a></b>
                            <ul>
                                <li>Raspberry Pi on suosituin markkinoilla myytävä korttitietokone. Sen tärkein valintaperuste on alustan tuttuus ja tuki kehityskäytössä. Alustalle on saatavilla laaja valikoima ohjelmia ja sitä voidaan käyttää myös erilaisiin laiteohjelmointi- ja elektroniikkapainotteisiin sovelluksiin.</li>
                                <li>Suositeltavat spesifikaatiot riippuvat valitusta mitattavasta sovelluksesta</li>
                                <li>Suositus 4 tai 8 GB RAM</li>
                                <li>Suositus väh. 32 GB microSD, jos käytössä Linux</li>
                                <li>Huom! Laite on ARM-pohjainen, joten mitattavan ohjelmiston yhteensopivuus on varmistettava!</li>
                                <li>Huomioi myös hankinnassa USB-näppäimistön ja -hiiren, Cat5e/RJ45-verkkokaapelin, micro HDMI -kaapelin ja muiden oheislaitteiden tarve!</li>
                            </ul>
                        </li>
                    </ul>
                    
                    <picture>
                        {/* WebP */}
                        <source
                            srcSet="/pictures/mittari3c.webp"
                            type="image/webp"
                        />
                        {/* PNG jos webp ei käytössä */}
                        <source 
                            srcSet="/pictures/mittari3c.jpg"
                            type="image/png"
                        />
                        {/* Fallback */}
                        <img
                            src="/pictures/mittari3c.jpg"
                            loading="lazy"
                            alt="Kuva Raspberry PI:sta, jota voidaan käyttää mittausjärjestelyn toteuttamiseen"
                            className="kriteerikortti-images"
                        />
                    </picture>
                </div>


                <div className="kriteerikortit-text-container">
                    <ul>
                        <li><b>(2) UP 7000 Series: <a href='https://up-shop.org/default/up-7000-series.html' target='_blank' rel='noopener noreferrer'>https://up-shop.org/default/up-7000-series.html</a></b>
                            <ul>
                                <li>UP 7000 Series on Intelin x86-pohjainen korttitietokone, jonka toimintaperiaate ja yhteensopivuus eroaa hyvin minimaalisesti saatavilla olevista palvelin- ja työasemalaitteistoista. Se kykenee suorittamaan erittäin laajan valikoiman ohjelmistoja ja on erityisen käyttökelpoinen, mikäli ohjelmiston käyttö tapahtuu x86-pohjaisella tuotantolaitteistolla ja ohjelmiston siirtäminen ARM-pohjaiselle Raspberry Pi:lle osoittautuisi työlääksi.</li>
                                <li>Suositeltavat spesifikaatiot riippuvat valitusta mitattavasta sovelluksesta</li>
                                <li>8 GB RAM (suositus)</li>
                                <li>64 GB eMMC (suositus)</li>
                                <li>12V (suositus)</li>
                                <li>x86 / x86-64-pohjainen: tukee tavallisia PC-ohjelmistoja ja käyttöjärjestelmiä</li>
                                <li>Huom! Varmista riittävä muistin määrä testattavan ympäristön kannalta. Laitteen tarjoama 8 GB:n  keskusmuisti edustaa monia virtuaalipalvelimia ja kevyitä työasemia. Muistin saatavuutta voidaan rajoittaa ohjelmallisesti.</li>
                                <li>Huom! Tehonkulutus saattaa nousta yli 36W:n varsinkin videoulostuloa ja oheislaitteita käytettäessä! Mikäli laitteen virrankäyttö ylittää mittarin kapasiteetin, valitse mittariksi Powerwerx PWRcheck+ tai valmistajalta vähävirtaisempi laitteistovaihtoehto!</li>
                                <li>Huomioi myös hankinnassa USB-näppäimistön ja -hiiren, Cat5e/RJ45-verkkokaapelin, täysikokoisen HDMI -kaapelin ja muiden oheislaitteiden tarve!</li>
                            </ul>
                        </li>
                    </ul>
                    <picture>
                        {/* WebP */}
                        <source
                            srcSet="/pictures/UPBoard.webp"
                            type="image/webp"
                        />
                        {/* PNG jos webp ei käytössä */}
                        <source
                            srcSet="/pictures/UPBoard.png"
                            type="image/png"
                        />
                        {/* Fallback */}
                        <img
                            src="/pictures/UPBoard.png"
                            loading="lazy"
                            alt="Kuva UP Boardista, jota voidaan käyttää mittausjärjestelyn toteuttamiseen"
                            className="kriteerikortti-images"
                        />
                    </picture>
                </div>

                <h2 id="laite-mittari" className='kriteerikortti-second-header'>Virtamittaus</h2>
                
                <div className="kriteerikortit-text-container">

                    <p>Suositeltavaksi virtamittariksi on valittu <b>HardKernel SmartPower 3</b>. Valittu virtamittari on
                    verrattain edullinen ja soveltuu erilaisten pienten tasavirtalaitteiden virtamittauksiin.
                    Vaihtoehtoisen Powerwerx PWRcheck+ -mittarin saatavuus oli tilapäisesti heikko hankkeen
                    suunnitteluvaiheen aikana, joten mittaria ei hankittu arvioitavaksi ja se jouduttiin
                    sulkemaan pois suosituslaitteistoa valittaessa. On syytä huomioida, että HardKernel SmartPower 3
                    ei sovellu niiden 12V laitteiden mittaamiseen, joiden tehonkulutus ylittää 36W.</p>

                    <br/>
                    
                    <ul>
                        <li><b>Hardkernel SmartPower 3: <a href='https://www.hardkernel.com/shop/smartpower-iii/' target='_blank' rel='noopener noreferrer'>https://www.hardkernel.com/shop/smartpower-iii/</a></b>
                            <ul>
                                <li>Valitse mukaan PSU <a href='https://www.hardkernel.com/shop/19v-7a-power-supply/' target='_blank' rel='noopener noreferrer'>https://www.hardkernel.com/shop/19v-7a-power-supply/</a></li>
                                <li>Tilaus sisältää myös osan <a href='https://www.hardkernel.com/shop/dc-plug-cable-assembly-5-5mm/' target='_blank' rel='noopener noreferrer'>https://www.hardkernel.com/shop/dc-plug-cable-assembly-5-5mm/</a></li>
                                <li>Sekä virtalähde (PSU) ja 5.5mm DC-kaapeli ovat myös tilattavissa Suomesta elektroniikkakaupoista</li>
                                <li>PSU-suositus korkeintaan 21V (mittari kestänee sisäisesti 24V)</li>
                                <li>PSU-suositus vähintään 9V, jos SUT-laitteiden toimintajännite on 5V.</li>
                                <li>PSU-suositus vähintään 13V, jos SUT-laitteiden toimintajännite on 5-12V.</li>
                                <li>Monet kannettavien laturit sopivat virtalähteeksi, jos niiden liittimeksi vaihtaa 5.5mm DC urosliittimen rasialiittimellä.</li>
                            </ul>
                        </li>
                    </ul>
                    
                    <picture>
                        {/* WebP */}
                        <source
                            srcSet="/pictures/mittari1c.webp"
                            type="image/webp"
                        />
                        {/* PNG jos webp ei käytössä */}
                        <source
                            srcSet="/pictures/mittari1c.jpg"
                            type="image/png"
                        />
                        {/* Fallback */}
                        <img
                            src="/pictures/mittari1c.jpg"
                            loading="lazy"
                            alt="Kuva virtamittausjärjestelystä"
                            className="kriteerikortti-images"
                        />
                    </picture>
                    
                    
                    <p>Seuraava lista sisältää mittarin käytön huomioita:</p>
                    
                    <br/>
                    
                    <p>Virtamittarin jännitetaso tulee rajata ennen kytkemistä mittauskanavan osalta SUT:n suosittamalle
                    maksimitasolle (esim. 5V), jotta laite ei rikkoonnu!
                    Tehtäessä 5.5mm DC plug -kytkentöjä on suositeltavaa mitata jännitemittarilla DC-plugin napaisuus ennen kytkemistä, jotta laite ei rikkoonnu!
                    Mitattaessa ei ole suositeltavaa vaihtaa säädettyä jännitetasoa, jotta laite ei rikkoonnu!</p>
                </div>

                <h2 id="laite-malli" className='kriteerikortti-second-header'>Mallikokoonpanot</h2>

                <div className="kriteerikortit-text-container">

                    <p>Seuraavaksi listattujen mallikokoonpanojen tarkoitus on kuvata virtamittaukseen tarvittavat
                    työkalu- ja komponenttihankinnat. Listaukset eivät kata muita mahdollisia oheislaite- ja
                    kaapelitarpeita.</p>

                    <br/>
                    
                    <ul>
                        <li><b>Toimivaksi havaittu kokoonpano Raspberry Pi 4 Model B 8 GB:n (SUT #1) kanssa</b>
                            <ul>
                                <li>Raspberry Pi 4 Model B 8 GB</li>
                                <li>Raspberry Pi OS</li>
                                <li>Hardkernel SmartPower 3</li>
                                <li>Hardkernel 19V/7A Power Supply (sisältyy)</li>
                                <li>Hardkernel DC Plug Cable Assembly 5.5mm (sisältyy)</li>
                                <li>5.5mm DC naaras-naaras -muunnin (kytketään edelliseen)</li>
                                <li>5.5mm DC uros -> USB-A -välikaapeli (kytketään edelliseen)</li>
                                <li>USB-A -> USB-C OTG-kaapeli (kytketään edelliseen)</li>
                            </ul>
                        </li>
                        <li><b>Toimivaksi havaittu kokoonpano Raspberry Pi 4 Model B 8 GB:n kanssa (ohittaa sulakkeen)</b>
                            <ul>
                                <li>Raspberry Pi 4 Model B 8 GB</li>
                                <li>Raspberry Pi OS</li>
                                <li>Hardkernel SmartPower 3</li>
                                <li>Hardkernel 19V/7A Power Supply (sisältyy)</li>
                                <li>Hardkernel DC Plug Cable Assembly 5.5mm (sisältyy)</li>
                                <li>5.5mm DC naaras rasialiittimellä (kytketään edelliseen)</li>
                                <li>Dupont koiras-naaras -johto (kytketään edelliseen)</li>
                                <li>(Dupont naaras-naaras -johto voitaisiin kytkeä myös suoraan edellisten sijaan, mutta virtamittarin kiristysruuvi voi katkaista liittimen)</li>
                                <li><a href='https://www.raspberrypi.com/documentation/computers/raspberry-pi.html' target='_blank' rel='noopener noreferrer'>Kytkentä Raspberryn GND & 5V -GPIO-pinneihin</a></li>
                                <li>Kokoaminen vaatii risti- tai talttapäisen ruuvitaltan.</li>
                            </ul>
                        </li>
                        <li><b>Toimivaksi havaittu kokoonpano UP 7000 Series (SUT #2) kanssa</b>
                            <ul>
                                <li>Hardkernel SmartPower 3</li>
                                <li>Hardkernel 19V/7A Power Supply (sisältyy)</li>
                                <li>Hardkernel DC Plug Cable Assembly 5.5mm (sisältyy)</li>
                            </ul>
                        </li>
                        <li><b>Usean laitteen mittaaminen</b>
                            <ul>
                                <li>Yksi Hardkernel SmartPower 3 tukee kahta mittauskanavaa ja sitä kautta kahta samaa tai eri jännitetasoa. Yhteen kanavaan voidaan kytkeä hetkellistä kuormaa korkeintaan 50W / 3A.</li>
                                <li>Useita laitteita voidaan yhdistää yhteen mittauskanavaan 5.5mm DC naaras-monta urosta -haaroittimella.</li>
                                <li>Useita Hardkernel SmartPower 3 -yksiköitä voidaan ottaa käyttöön - tarvittaessa USB-portti voidaan haaroittaa USB-hubilla. Laitteistosta ja ajureista riippuen esimerkiksi Linux-isäntäkone tukee korkeintaan 32-128 eri yksikköä.</li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <hr/>
                
                <h2 id="laite-arkkitehtuuri" className='kriteerikortti-second-header'>Mittauskokoonpanon kytkennän arkkitehtuurikuvaus</h2>

                <div className="kriteerikortit-text-container">

                    <p>Yksinkertaisessa mittajärjestelyssä kokoonpano koostuu virtalähteestä ja virtamittarista (PSU),
                    mitattavasta järjestelmästä (SUT) sekä mittausdatan keräävästä ja mittausta koordinoivasta
                    hallintatietokoneesta (PC). Mittausjarjestelyssä virranmittauksen data kerätään suoraan virtamittarilta
                    mittarin USB-protokollaa käyttäen CSV-muodossa. Mitattava järjestelmä viestii lisäksi tarvittaessa
                    eri mitattavia suureita, jotka on kerätty collectd-sovellusta käyttäen. Tieto siirretään verkkolevylle,
                    johon sekä SUT:lta ja PC:ltä on pääsy kummankin verkkoyhteyttä käyttäen.</p>
                    
                    <br/>

                    <p>Seuraava kuva esittää tätä yksinkertaista mittausjärjestelyä:</p>
                    
                    <picture>
                        <source
                            srcSet="/pictures/architecture1.png"
                            type="image/png"
                        />
                        {/* Fallback */}
                        <img
                            src="/pictures/architecture1.png"
                            loading="lazy"
                            alt="Kuva yksinkertaisesta kytkennän arkkitehtuurikuvauksesta"
                            className="kriteerikortti-images"
                        />
                    </picture>
                    
                    <p>Askeleen monimutkaisemmassa mittajärjestelyssä kokoonpano koostuu edellisen tavoin virtalähteestä ja
                    virtamittarista (PSU), jolla on kaksi mittauskanavaa (esim. HardKernel SmartPower 3), jotka on konfiguroitu
                    tarjoamaan virtaa 5V ja 12V laitteille. Kokoonpanossa verkkoreititin on 12V laite ja mitattava päätelaite
                    ja palvelin 5V laitteita. Järjestelyssä käytetään myös virtamittarin kykyä kytkeä virta päälle ja pois
                    mitattavilta laitteilta. Toimintoa voidaan käyttää mm. järjestelmän palauttamiseen alkutilaansa.
                    Toiselle kanavalle konfiguroitu verkkolaite mahdollistaa verkkoliikenteen virrankulutuksen arvioinnin.
                    Muilta osin järjestely on toiminnaltaan samanlainen kuin edellisessä.</p>
                    
                    <br/>

                    <p>Seuraava kuva esittää tätä askeleen monimutkaisempaa mittausjärjestelyä:</p>
                    
                    <picture>
                        <source
                            srcSet="/pictures/architecture2.png"
                            type="image/png"
                        />
                        {/* Fallback */}
                        <img
                            src="/pictures/architecture2.png"
                            loading="lazy"
                            alt="Kuva monimutkaisemmasta kytkennän arkkitehtuurikuvauksesta"
                            className="kriteerikortti-images"
                        />
                    </picture>

                </div>
                
                <hr/>

                <h2 id="ohjelma-mittaus" className='kriteerikortti-second-header'>Ohjelmistopohjainen virranmittaus</h2>

                <div className="kriteerikortit-text-container">

                    <p>Kullakin virtamittarilla on oma yksilöllinen menetelmä tiedonkeruuseen ja datan lukemiseen.
                    Lähes kaikki selvityksessä käsitellyt mittarit kommunikoivat tietokoneen kanssa USB-väylän
                    välityksellä. Hankintasuositukseksi nostettu HardKernel SmartPower 3 kommunikoi myös USB-väylän
                    siten, että mittari näkyy tietokoneelle sarjaporttina (UART), joka kommunikoi laitteen
                    kosketuskäyttöliittymän kautta asetetuilla yhteysasetuksilla (115200 bps) ja intervallilla.
                    Tyypillisesti PC:n sarjaporttia hyödyntävät ohjelmat voivat lukea laitteen lähettämää
                    tekstimuotoista tulostetta suoraan valitsemalla ensin oikea yhteysnopeus. Yksi mittauspiste
                    ilmenee tulosteesta yksittäisenä rivinä, johon on pilkuin eroteltu mittauskanavan jännite,
                    virta ja teho. Protokollan tarkempi kuvaus on esitetty laitteen virallisella tukisivulla</p>

                    <br/>

                    <p>Suositellun virtamittarin ohjelmoinnin lisäksi MitViDi-hankkeessa selvitettiin muita
                    mahdollisia ohjelmistopohjaisia resurssimittareita, joiden datan pohjalta voidaan arvioida
                    virrankulutusta. Yksi perustelu ohjelmistopohjaiselle virranmittaukselle onkin mahdollisesti hankala tai
                    mahdoton pääsy mittaamaan fyysisesti mitattavaa järjestelmää. Ohjelmistopohjainen mittaus
                    mahdollistaa laitteiden mittaamisen ilman fyysistä pääsyä niiden laitetilaan. Tekniikka tarjoaakin
                    mahdollisuuksia erityisesti palvelinlaitteiden virranmittaukseen. Selvityksen kohteena olleet
                    ohjelmat ovat pääosin Linux-pohjaisia, sillä myös palvelinlaitteet ovat usein Linux-pohjaisia.</p>

                    <br/>
                    
                    <p>Seuraavat kartoituksessa tarkastellut monitorointiohjelmat sopivat huonosti virranmittauksen
                    datankeruuseen, sillä ne toimivat lähinnä interaktiivisesti: top, htop, busybox top, iotop, iptraf, iftop.
                    Joidenkin ohjelmien (esim. Prometheus ja JVM flight recorder) käyttö painottuu järjestelmiin, jotka
                    pohjautuvat Docker-konttien ajoon tai Java-palveluiden tarjontaan. Resurssikäyttöön on kehitetty
                    myös lukuisia etämonitorointiin soveltuvia ohjelmia, jotka kuitenkin aiheuttavat myös
                    resurssikuormitusta mitattavalle järjestelmälle mm. web-käyttöliittymän tarjoamisen tai datan käsittelyn
                    ja visualisoinnin vuoksi. Selvityksessä rajoituttiin sovelluksiin, joiden ei havaittu aiheuttavan
                    etäyhteyksien ylläpidosta aiheutuvaa lisäkuormitusta. </p>
                    
                    <br/>
                    
                    <p>Kartoituksessa luokiteltiin seuraavia työkaluja ja niiden mittaamia suureita:</p>

                    <br />
                    
                    <table>
                    <tr>
                        <th>Työkalu</th>
                        <th>Teho</th>
                        <th>CPU</th>
                        <th>Keskusmuisti</th>
                        <th>Verkko</th>
                        <th>Levynkäyttö</th>
                        <th>Käyttöjärjestelmän prosessit</th>
                    </tr>
                    <tr>
                    <td><span class="c1">collectd</span></td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-CPU
                    plugin</span></td>
                    <td></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-xen
                    plugin</span></td>
                    <td></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-memory
                    plugin</span></td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-swap
                    plugin</span></td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-vmem
                    plugin</span></td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-apc ups
                    plugin</span></td>
                    <td><span class="c1">X (ups)</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-nut
                    plugin</span></td>
                    <td><span class="c1">X (ups)</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-ted
                    plugin</span></td>
                    <td><span class="c1">X (power)</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-battery
                    plugin</span></td>
                    <td><span class="c1">X (laptop)</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-ipmi
                    plugin</span></td>
                    <td><span class="c1">X (ipmi)</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-snmp
                    plugin</span></td>
                    <td><span class="c1">X (ipmi)</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-mbmon
                    plugin</span></td>
                    <td><span class="c1">X (sensors)</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-redfish
                    plugin</span></td>
                    <td><span class="c1">X (sensors)</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-sensors
                    plugin</span></td>
                    <td><span class="c1">X (sensors)</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-thermal
                    plugin</span></td>
                    <td><span class="c1">X (sensors)</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-multimeter
                    plugin</span></td>
                    <td><span class="c1">X (multimeter)</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-sigrok
                    plugin</span></td>
                    <td><span class="c1">X (multimeter)</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-smart
                    plugin</span></td>
                    <td><span class="c1">X (hdd)</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-wireless
                    plugin</span></td>
                    <td><span class="c1">X (wireless)</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-cgroups
                    plugin</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    </tr>
                    <tr>
                    <td><span class="c1">-cpufreq
                    plugin</span></td>
                    <td></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-turbostat
                    plugin</span></td>
                    <td></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-dbi
                    plugin</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><span class="c1">X (db)</span></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-disk
                    plugin</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-dns
                    plugin</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-interface
                    plugin</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-iptables
                    plugin</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-netlink
                    plugin</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">-load
                    plugin</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    </tr>
                    <tr>
                    <td><span class="c1">-processes
                    plugin</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    </tr>
                    <tr>
                    <td><span class="c1">-virt
                    plugin</span></td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">nmon</span></td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    </tr>
                    <tr>
                    <td><span class="c1">atop</span></td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    </tr>
                    <tr>
                    <td><span class="c1">vmstat</span></td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                    </tr>
                    <tr>
                    <td><span class=
                    "c1">sysstat/iostat</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">statsd</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">java flight
                    recorder</span></td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">rapl-read</span></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">mozilla_rapl</span></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">PAPI</span></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">Intel Power Gadged</span></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">perf</span></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td><span class="c1">turbostat</span></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    </table>

                    <br/>
                    
                    <p>Kuvattua listaa voi käyttää myös mittausprosessissa fyysisen laitteiston virranmittausdatan
                    laajennoksena. Mikäli eri lähteistä saadut datapisteet voidaan ajoittaa tarkasti, tulosten
                    välisiä yhteyksiä voidaan käyttää virrankulutuksen tarkempaan analyysiin erittelemään eri
                    resurssityyppien vaikutusta kokonaiskulutukseen.</p>
                    
                </div>
                
            </div>
            <GoBackArrow />
            <Footer />
        </div>
    )
}

export default Mittausjarjestely
