import React from "react";

const HuipputasonKriteeriD1Tekstit = {
    id: "D1",
    nimi: <text>D1 Kriteerikortti: Virrankulutus</text>,
    kriteeri:
        <text>
            Ohjelmiston virrankulutus voidaan mitata eri käyttötapauksissa sen kulutuksen arvioimiseksi sekä sen toiminnallisuuksien resurssitarpeen määrittämiseksi.
        </text>,
    plainKriteeri:
        "Ohjelmiston virrankulutus voidaan mitata eri käyttötapauksissa sen kulutuksen arvioimiseksi sekä sen toiminnallisuuksien resurssitarpeen määrittämiseksi.",
    kriteerinLyhytKuvaus:
        <text>
            Tietojärjestelmän virrankulutus on laaja kokonaisuus, jota voidaan havainnoida sekä yksittäisten komponenttien ja osajärjestelmien että makrotasolla.
            Tyypillisesti virrankulutus on järjestelmän toiminnan tarkoituksen kannalta toissijainen laadullinen ominaisuus, jota voidaan havainnoida epäsuorasti
            järjestelmän suorituskyvyn sekä käyttökustannusten kautta. Virrankulutus on kuitenkin ympäristövaikutusten kannalta pääasiallinen osatekijä
            laitteiden valmistuksen ja muiden epäsuorien vaikutusten ohella. <br />
            Virrankulutukselle saadaan karkea arvio järjestelmän laitteiston spesifikaation, mutta myös käytön laskutuksen ja akullisissa laitteissa toiminta-ajan
            perusteella. Tarkka kulutuksen määrittäminen edellyttää kuitenkin usein systemaattista järjestelmän virtamittausta järjestelmän kehityksen ja/tai käytön aikana. <br />
            Virtamittausta voidaan tehdä mittaamalla joko suoraan fysikaalista virrankulutusta tai laitteisiin mahdollisesti sisäänrakennetuilla mittareilla. Mikäli
            tuotantojärjestelmän suora mittaaminen ei ole mahdollista, mittausta voidaan tehdä erillisellä mittaukseen laaditulla järjestelmäkokoonpanolla, josta saaduilla
            tuloksilla voidaan estimoida varsinaisen tuotantojärjestelmän kulutusta. Kulutusta voidaan laskea myös epäsuorasti mallien avulla resurssikulutuksesta, erityisesti
            kehitettävän järjestelmän ulkopuolisten osien (esim. tietoverkot) osalta.
        </text>,
    perustelu:
        <text>
            Virranmittaus mahdollistaa ohjelmiston kehitysprosessissa sen ympäristövaikutusten validoinnin valitun mittausjärjestelyn määrittelemällä tarkkuudella.
            Järjestely mahdollistaa myös järjestelmän resurssien kokonaiskulutuksen rajaamisen, sillä mittauksella voidaan varmistaa, että virrankulutus ei ylitä
            asetettuja rajoja ja toisaalta järjestelmä pysyy toimintakykyisenä myös kuormituksessa. Menettely onkin välttämätön osa esimerkiksi mobiililaitteiden
            tuotekehitystä, jossa kulutuksen optimointi on yksi päätavoite. Kulutusta järjestelmän tuotantokäytössä voidaan myös jo arvioida kehitysvaiheessa ja
            vaikuttaa tarvittaessa järjestelmän suunnitteluun. Mittausta voidaan jatkaa myös tuotantojärjestelmiin ja havaita näin käyttötapojen ja toimintaympäristön
            muutoksien mahdollisia ympäristövaikutuksia.
        </text>,
    plainPerustelu:
        "Virranmittaus mahdollistaa ohjelmiston kehitysprosessissa sen ympäristövaikutusten validoinnin valitun mittausjärjestelyn määrittelemällä tarkkuudella. " +
        "Järjestely mahdollistaa myös järjestelmän resurssien kokonaiskulutuksen rajaamisen, sillä mittauksella voidaan varmistaa, että virrankulutus ei ylitä " +
        "asetettuja rajoja ja toisaalta järjestelmä pysyy toimintakykyisenä myös kuormituksessa. Menettely onkin välttämätön osa esimerkiksi mobiililaitteiden " +
        "tuotekehitystä, jossa kulutuksen optimointi on yksi päätavoite. Kulutusta järjestelmän tuotantokäytössä voidaan myös jo arvioida kehitysvaiheessa ja " +
        "vaikuttaa tarvittaessa järjestelmän suunnitteluun. Mittausta voidaan jatkaa myös tuotantojärjestelmiin ja havaita näin käyttötapojen ja toimintaympäristön " +
        "muutoksien mahdollisia ympäristövaikutuksia.",
    minkaTyyppisiinEiSovi:
        <text>
            Fysikaaliseen virrankulutukseen pohjautuva mittaus edellyttää sekä järjestelmän kehityksen että käytön aikana pääsyä joko sen sisäisiin mittareihin tai fyysiseen laitteistoon.
            Pääsyä ei voida aina järjestää esimerkiksi kaupallisiin pilvipalveluihin, ja näiden osalta joudutaankin luottamaan järjestelmän mahdollisesti tarjoamaan sisäiseen mittausdataan. <br />
            Vaikka järjestelmiin olisi fyysinen pääsy mittausta varten, virtamittarien tarkkuus voi olla riittämätön tai ympäristö mittarille soveltumaton, esim. vaihtovirtalaitteiden,
            suuritehoisten laitteiden ja laajojen järjestelmäkokonaisuuksien osalta. Toisaalta varsinaista tuotantojärjestelmää edustava mittausjärjestely ei aina mallinna alkuperäistä
            järjestelmää riittävällä tarkkuudella. Mittalaitteiden ja mittauksen kustannus voi myös ylittää niillä saavutettavat taloudelliset hyödyt.
        </text>,
    kysymyksetHankkijanSuuntaan:
        <text>
            Onko järjestelmässä osia, joiden virrankulutus aiheuttaa merkittäviä kustannuksia? <br />
            Sisältääkö järjestelmä toiminnallisuuksia tai alijärjestelmiä, joiden virrankulutuksella on merkittävä vaikutus järjestelmän toimintaan (esim. akkukäyttöisen laitteen toiminta-aika)? <br />
            Sisältääkö järjestelmä osia, joissa on sisäänrakennettuja resurssien käytön mittareita? <br />
            Onko järjestelmää mahdollista modularisoida ja rakentaa mittaamista ja kulutuksen läpinäkyvyyttä edistävillä tavoilla?
        </text>,
    copyPastevaatimus:
        <text>
            Ohjelmiston virrankulutus voidaan mitata eri käyttötapauksissa sen kulutuksen arvioimiseksi sekä sen toiminnallisuuksien resurssitarpeen määrittämiseksi.
        </text>,
    arvioKriteerinHinnasta:
        <text>
            Virrankulutuksen mittaus voidaan toteuttaa hyvin kevyenä suhteellisten, resurssien kulutusta kuvaavien viitearvojen keräämisenä osana normaalia kehitysprosessia
            tai ottaa käyttöön laajamittaisesti ottamalla käyttöön systemaattinen, kattava mittausjärjestely ja eri osajärjestelmien mittaamiseen soveltuvia mittauslaitteita. <br />

            Hyvin karkeallakin tasollakin kerätty data viitteellisestä resurssikulutuksesta voi auttaa ja tehostaa kehitystyötä ja edistää ympäristövaikutuksia kehitystyön
            aikaisessa vaiheessa. Riippuen valitusta toteutuksesta, virrankulutuksen seuranta voi parhaassa tapauksessa laskea virrankulutuksen lisäksi kokonaiskustannuksia.
            Yleisesti on odotettavissa, että virrankulutusta voidaan tehostaa lisäpanostuksilla kriteeriin ja kriteerille on määritettävä toteutukselle soveltuva budjetti,
            jonka määrää voidaan tarkistaa toteutuksesta kerätyn datan ja kokemuksen pohjalta.
        </text>,
    arvioKriteerinYmparisto:
        <text>
            Virranmittaus mahdollistaa ohjelmiston kehitysprosessissa sen ympäristövaikutusten validoinnin valitun mittausjärjestelyn määrittelemällä tarkkuudella.
            Järjestely mahdollistaa myös järjestelmän resurssien kokonaiskulutuksen rajaamisen, sillä mittauksella voidaan varmistaa, että virrankulutus ei ylitä
            asetettuja rajoja ja toisaalta järjestelmä pysyy toimintakykyisenä myös kuormituksessa. Menettely onkin välttämätön osa esimerkiksi mobiililaitteiden
            tuotekehitystä, jossa kulutuksen optimointi on yksi päätavoite. Kulutusta järjestelmän tuotantokäytössä voidaan myös jo arvioida kehitysvaiheessa ja
            vaikuttaa tarvittaessa järjestelmän suunnitteluun. Mittausta voidaan jatkaa myös tuotantojärjestelmiin ja havaita näin käyttötapojen ja toimintaympäristön
            muutoksien mahdollisia ympäristövaikutuksia.
        </text>,
    ohjeistusKriteerinKaytosta:
        <text>
            TBA
        </text>,
    todentaminen:
        <text>
            TBA
        </text>,
    ehdotusSopimuksenSeuranta:
        <text>
            TBA
        </text>,
    edellytyksetHyodyntamiselle:
        <text>
            Ohjelmistosta on erotettavissa itsenäisiä osajärjestelmiä tai -toiminnallisuuksia, joiden arviointiin on saatavilla soveltuvia virran mittaamisen työkaluja.
            Lisäksi esimerkiksi pilvipalvelun tai web-palvelimen kapasiteetti voi olla jaettu usean sovelluksen tai palvelun kesken, joiden kesken kunkin yksilöllinen
            kulutus tulisi olla eroteltavissa luotettavasti.
        </text>,
    esimerkki:
        <text>
            Organisaation työajan seurannan tietojärjestelmän kokonaisvirrankulutus määritetään laatimalla virran mittaukseen pohjautuva testijärjestely järjestelmän normaalia käyttöä
            edustavien käyttötapausten avulla. Mittaus kattaa sekä järjestelmää käyttävien työasemien kokonaisvirrankulutuksen, joka mitataan DC-virtamittarilla, että taustajärjestelmän
            osalta vastaavan palvelun aiheuttaman kuormituksen arvioituna laitteiston sisäänrakennetuista ohjelmistopohjaisista mittareista. <br />

            Lisäksi palvelimen osalta laaditaan kokonaiskuva palvelun vaatimasta tyypillisestä ja maksimaalisesta resurssitarpeesta ja siihen liittyvistä kokonaiskulutuksen
            viitearvoista laitteistojen spesifikaatioihin pohjautuen.

        </text>,
    mittausJaViitearvot:
        <text>
            Virranmittaus on systemaattinen prosessi, jonka tekninen viitekehys voidaan määrittää laitteistokohtaisesti. Mittausjärjestely suunnitellaan ja kalibroidaan tietylle
            käyttökohteelle ja viitearvot voidaan johtaa aiemmista vastaavista mittauksista ja/tai kehittää osana mittausprosessia. Laitteiston spesifikaatiot ja koeajoja erilaisilla
            kuormitusmäärillä voidaan käyttää viitearvoina myös yksinkertaisissa mittauksissa. Mittausta voidaan tehdä järjestelmän todellisesta kulutuksesta tai suhteellisesta kulutuksesta
            edustavaa mittauksessa käytettävää järjestelmää käyttäen.
        </text>,
    kytkeytyvatKriteerit:
        <text>
            Ohjelmisto antaa palautetta kapasiteetin ja virran käytöstä, Ohjelmisto vapauttaa väliaikaisesti kapasiteettia, jota ei tarvitse, Virranhallinnan tuki, Tekninen optimointi
        </text>,
    lisatiedotJaLahteet:
        <text>
            German environment agency. Guide on green public procurement of software. 2020. <br />
            Software Sustainability. Calero C., Moraga M.A. & Piattini M., Springer. 2021.
        </text>

};

export default HuipputasonKriteeriD1Tekstit;
