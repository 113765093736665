import React from "react";

const PerustasonKriteeriA4Tekstit = {
  id: "A4",
  nimi: <text>A4 Kriteerikortti: Ohjelmiston ja laitteen virranhallintaominaisuudet </text>,
  kriteeri:
    <text>
      Ohjelmisto ja laitteen virranhallinta eivät estä toistensa käyttöä seuraavasti. a) Ohjelmisto ei estä laitteen virranhallintatoiminnallisuuksia ilman perusteita.
      b) Ohjelmiston käyttökokemus ei kärsi virranhallinnan tuesta.
    </text>,
  plainKriteeri:
    "Ohjelmisto ja laitteen virranhallinta eivät estä toistensa käyttöä seuraavasti. a) Ohjelmisto ei estä laitteen virranhallintatoiminnallisuuksia ilman perusteita. " +
    "b) Ohjelmiston käyttökokemus ei kärsi virranhallinnan tuesta.",
  kriteerinLyhytKuvaus:
    <text>
      Virransäästön toiminnallisuuksien tietokonejärjestelmän tai siihen liittyvien järjestelmien osalta ei pitäisi haitata ohjelmiston toiminnallisuutta millään tavoilla,
      eikä ohjelmiston pitäisi haitata virranhallintaa ilman syytä. Esimerkiksi palvelimella toimivan sovelluksen ei pitäisi menettää sessiodataa, jos päätelaite asettuu
      lepotilaan. Uudestaan tunnistautumisen vaatimista ei kuitenkaan katsota toiminnallisuuden haittaamiseksi.
    </text>,
  perustelu:
    <text>
      Erilaiset virranhallintatoiminnallisuudet säästävät merkittävästi energiaa ja resursseja jos niitä käytetään oikeaoppisesti. Tästä syystä ohjelmiston on syytä mahdollistaa
      niiden toiminta, jos erityisiä syitä sen estämiseen ei ole. Jotta käyttäjä on valmis hyödyntämään virranhallintaan liittyviä toiminnallisuuksia, ohjelmiston käyttökokemuksen
      ei tulisi kärsiä niiden käytöstä. Tämä tarkoittaa pääasiassa sitä että käyttäjän tai session dataa ei menetetä, mutta esimerkiksi uudelleenkirjautuminen tietoturvasyistä on hyväksyttävää.
    </text>,
  plainPerustelu:
    "Erilaiset virranhallintatoiminnallisuudet säästävät merkittävästi energiaa ja resursseja jos niitä käytetään oikeaoppisesti. Tästä syystä ohjelmiston on syytä mahdollistaa " +
    "niiden toiminta, jos erityisiä syitä sen estämiseen ei ole. Jotta käyttäjä on valmis hyödyntämään virranhallintaan liittyviä toiminnallisuuksia, ohjelmiston käyttökokemuksen " +
    "ei tulisi kärsiä niiden käytöstä. Tämä tarkoittaa pääasiassa sitä että käyttäjän tai session dataa ei menetetä, mutta esimerkiksi uudelleenkirjautuminen tietoturvasyistä on hyväksyttävää.",
  minkaTyyppisiinEiSovi:
    <text>
      Joillain ohjelmistoilla on luonteensa vuoksi jatkuva tarve toimia päällä olonsa ajan täydellä teholla. Toisaalta verkkopalveluiden tms. tapauksessa käytetty selain voi varsinaisesti määrätä
      asiasta, jolloin ohjelmistossa ei pystytä merkittävästi vaikuttamaan asiaan.
    </text>,
  kysymyksetHankkijanSuuntaan:
    <text>
      Onko ohjelmistolla tarvetta kirjata käyttäjä ulos virransäästötilan yhteydessä, esimerkiksi tietoturvasyistä? <br />
      Onko ohjelmistolla omia virransäästötoiminnallisuuksia irrallaan laitteiston vastaavasta toiminnasta? <br />
    </text>,
  copyPastevaatimus:
    <text>
      Ohjelmisto ja virranhallinta eivät estä toistensa käyttöä seuraavasti. <br />
      <strong>a)</strong> Ohjelmisto ei estä laitteen virranhallintatoiminnallisuuksia ilman perusteita. <br />
      <strong>b)</strong> Ohjelmiston käyttökokemus ei kärsi virranhallinnan tuesta.
    </text>,
  arvioKriteerinHinnasta:
    <text>
      Kriteerin hintaa on vaikea arvioida tarkasti, mutta se tulisi lähtökohtaisesti toteuttaa.
    </text>,
  arvioKriteerinYmparisto:
    <text>
      Erilaiset virranhallintatoiminnallisuudet säästävät merkittävästi sähköä ja resursseja jos niitä käytetään oikeaoppisesti. Tästä syystä sovelluksen on syytä mahdollistaa
      niiden toiminta, jos erityisiä syitä sen estämiseen ei ole (a). Jotta käyttäjä on valmis hyödyntämään virranhallintaan liittyviä toiminnallisuuksia, ohjelmiston käyttökokemuksen
      ei tulisi kärsiä niiden käytöstä. Tämä tarkoittaa pääasiassa sitä että käyttäjän tai session dataa ei menetetä, mutta esimerkiksi uudelleenkirjautuminen tietoturvasyistä on hyväksyttävää (b).
    </text>,
  ohjeistusKriteerinKaytosta:
    <text>
      Suosittelemme kriteerin käyttöä vähimmäisvaatimuksena.
    </text>,
  todentaminen:
    <text>
      Testaamalla todennetaan, että laitteiston virranhallintaominaisuuksien toiminta ei haittaa ohjelmistosessiota ja että ohjelmiston käynnissäolo ei estä virranhallintaominaisuuksien toimintaa. Testaus toteutetaan sopimuskaudella, viimeistään ohjelmiston julkaisuversion kanssa.
    </text>,
  ehdotusSopimuksenSeuranta:
    <text>
      Kriteerin toteutumisen tulee pysyä ohjelmiston elinkaaren ajan samalla tasolla, ja tätä tulee seurata. Kriteerin toteutuminen varmistetaan käyttäjätestein.
    </text>,
  edellytyksetHyodyntamiselle:
    <text>
      On joitain hyvin harvinaisia käyttötapauksia joissa sovelluksen pitää jatkuvasti toimia täydellä teholla, esim. terveydenhuollossa. Nämä poislukien ohjelmistot voivat relevantisti käyttää kriteeriä.
    </text>,
  esimerkki:
    <text>
      Videoita itsestään soittavat sovellukset voivat haitata virranhallinnan toimia. Yleisesti videon toistaminen estää laitteistoa siirtymästä lepotilaan kesken videon,
      ymmärrettävistä syistä. Tämä muodostuu ongelmaksi kun joillain sivustoilla videot tai animaatiot voivat alkaa itsestään ja pyöriä loputtoman pitkään, eikä käyttäjä välttämättä kiinnitä
      tähän huomiota.
    </text>,
  mittausJaViitearvot:
    <text>
      Säästötoimintojen vaikutuksen mittaamiseksi on mahdollista testata ohjelmistoa ilman niitä ja niiden kanssa, jos on saatavilla luotettava menetelmä arvioida tai mitata virrankulutusdataa.
    </text>,
  kytkeytyvatKriteerit:
    <text>
      Kuormituksen seuranta loppukäyttäjälle, Oletusasetukset.
    </text>,
  lisatiedotJaLahteet:
    <text>
      1. A. Kamilaris, D. T. Hoang Ngan, A. Pantazaras, B. Kalluri, S. Kondepudi and T. K. Wai, "Good practices in the use of ICT equipment for electricity savings at a university campus,"
      International Green Computing Conference, Dallas, TX, USA, 2014, pp. 1-11, doi: 10.1109/IGCC.2014.7039176.
    </text>

};

export default PerustasonKriteeriA4Tekstit;
