import React from 'react';
import { Link } from 'react-router-dom';

import Footer from '../components/Footer';
import '../index.css';

const FrontPage = () => {
  return (
    <div>
      <div className="kriteerikortit-container">
        <h1 className="kriteerikortit-topheader">MitViDi-Portaali</h1>
        <div className="kriteerikortit-text-container">
          MitViDi-portaali on kotisivu MitViDi-hankkeen aikana kehitetyille työkaluille. Työkalujen tarkoituksena on lisätä tietoa kestävästä
          tietojenkäsittelystä ja auttaa eri toimijoita luomaan kestävempiä ratkaisuja omissa ICT-hankinnoissaan.
          Työkalut pohjautuvat hankkeen aikana rakennettuun MitViDi-arviointikehikkoon.

          <br /> <br />

          MitViDi-arviointikehikon tarkoituksena on asettaa tietojärjestelmien hankkijoille selkeä polku tuoda ympäristönäkökulmat osaksi julkisia
          hankintoja. Arviointikehikko koostuu kriteereistä, joiden tarkoituksena on ohjata ottamaan huomioon tietojärjestelmän kehityksen ja käytön
          aikaiset ympäristönäkökulmat ohjelmisto- ja IT-palveluhankintojen yhteydessä. Tavoitteena on ollut, että kriteerit voidaan viedä osaksi
          hankintadokumentaatiota sellaisenaan tai pienellä muokkauksella.

          <br /> <br />

          Kriteerien sisältö on kuvattu tarkemmin ns. kriteerikorteissa, joissa
          esitellään tarkemmin kriteerin taustat, vaikutukset ja käyttö hankinnan eri vaiheissa. Lisäksi kriteerikorteista on muokattu tiiviimmät,
          KEINO-osaamiskeskuksen ja Motivan kriteeripankin kanssa yhteensopivat kuvaukset, jotta eri kriteerien vertailu olisi mahdollisimman helppoa.
          Kriteerikorttien sisältö perustuu MitViDi-hankkeessa tehtyyn laajaan kirjallisuuskatsaukseen, jonka lisäksi korttien sisältöä on muokattu
          hankkijoiden ja toimittajien haastatteluista saadun palautteen perusteella.

          <br /> <br />

          Arviointikehikon tasot on asetettu siten, että aiemmin asiaan perehtymätönkin voi ottaa kriteerit pienellä kynnyksellä käyttöön hankinnassaan
          ja kokemuksen kertyessä edetä kohti edistyneempiä tasoja. Vastaavasti kilpailutuksiin osallistuvat järjestelmätoimittajat voivat kehikon
          kautta arvioida omaa osaamistaan ympäristöystävällisten tietojärjestelmien osalta ja valmistautua tuleviin julkisten hankintojen vaatimuksiin
          perehtymällä kehikon kriteereihin.

          <h2 className='kriteerikortti-second-header'>MitViDi-resurssit </h2>
          <b>MitViDi-työkalulla </b> käyttäjä pääsee tutkimaan arviointikehikon pohjalta muodostettuja ohjelmistohankintoihin sopivia hankintakriteerejä.
          Työkalun avulla käyttäjä pääsee valitsemaan hankkeelleen sopivat hankintakriteerit. Koko arviointikehikko ja siihen liittyvät kriteerikortit
          ovat tarkasteltavissa työkalun kautta. Tarkemmat kuvaukset arviointikehikon kriteerikorteista löytyy
          <b> MitViDi-kriteerikortit</b>-sivulta.
          <br /> <br />
          <Link to="/mitvidityokalu" className="styled-button">MitViDi-työkalu</Link>
          <Link to="/mitvidikriteerikortit" className="styled-button">MitViDi-kriteerikortit</Link>
          <br /> <br />
          <b>MitViDi-opas</b>-sivulla on linkki MitViDi-oppaaseen, jossa on tietoa MitViDi-hankkeen aikana kerättyä tietoa ohjelmistojen
          ekologisesta kestävyydestä. Oppaassa esitellään hankkeessa kehitetty kestävien ohjelmistojen arviointikehikko sekä siihen
          perustuva selainpohjainen työkalu hyödynnettäväksi julkisiin ohjelmistohankintoihin.
          <b> MitViDi-hanke</b>-painike vie hankesivulle, jossa on kuvattu tarkemmin MitViDi-hanketta.
          <b> Green ICT-ekosysteemi</b>-painike vie Green ICT-ekosysteemi-sivulle, jossa on kestävyyssuunnitteluun digityökaluja ja Green ICT -informaatiota.
          <br /> <br />
          <Link to="/mitvidiopas" className="styled-button">MitViDi-opas</Link>
          <a href="https://tieke.fi/hankkeet/mittarit-vihrean-digitalisaation-hankintoihin/" className="styled-button" target="_blank" rel="noopener noreferrer">
            MitViDi-hanke
          </a>
          <a href="https://greenict.fi/" className="styled-button" target="_blank" rel="noopener noreferrer">
          Green ICT-ekosysteemi
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FrontPage;