import React from "react";

const PerustasonKriteeriA2Tekstit = {
    id: "A2",
    nimi: <text>A2 Kriteerikortti: Kapasiteetin käyttö ja vapautus ohjelmiston taustajärjestelmässä </text>,
    kriteeri:
        <text>
            Ohjelmiston taustajärjestelmä käyttää vain tarvitsemiaan palvelinresursseja ja vapauttaa tarpeettomat palvelinresurssit muiden järjestelmien käyttöön.
        </text>,
    plainKriteeri:
        "Ohjelmiston taustajärjestelmä käyttää vain tarvitsemiaan palvelinresursseja ja vapauttaa tarpeettomat palvelinresurssit muiden järjestelmien käyttöön.",
    kriteerinLyhytKuvaus:
        <text>
            Taustajärjestelmän, eli ohjelmiston käyttäjälle näkymättömän osan ohjelmistosta, tulee pystyä mukauttamaan palvelimelta vaatimiaan resursseja todelliseen
            tarpeeseen perustuen. Tämä tarkoittaa että palvelinresursseja käytetään vain niin paljon ja niin pitkään kuin ohjelmisto oikeasti niitä tarvitsee. Kyseisen
            toiminnallisuuden liian heikkoa toteutusta voidaan pitää ohjelmointivirheenä.
        </text>,
    perustelu:
        <text>
            Taustajärjestelmän osalta voidaan saavuttaa merkittäviä palvelimien energiansäästöjä, mikäli kuormituksen
            vaihtelut ovat suuria (esim. yöaikaan / työajan ulkopuolella järjestelmän käyttö hyvin vähäistä). Toisaalta globaalissa käytössä olevassa
            järjestelmässä kriteerin hyöty taustajärjestelmien osalta voi jäädä vähäiseksi. On myös huomattava, että kriteerin huomioiminen mahdollistaa
            yleensä järjestelmän skaalautuvuuden ylöspäin ja sitä kautta toimintavarmuuden käyttöpiikkien sattuessa.
        </text>,
    plainPerustelu:
        "Taustajärjestelmän osalta voidaan saavuttaa merkittäviä palvelimien energiansäästöjä, mikäli kuormituksen " +
        "vaihtelut ovat suuria (esim. yöaikaan / työajan ulkopuolella järjestelmän käyttö hyvin vähäistä). Toisaalta globaalissa käytössä olevassa " +
        "järjestelmässä kriteerin hyöty taustajärjestelmien osalta voi jäädä vähäiseksi. On myös huomattava, että kriteerin huomioiminen mahdollistaa " +
        "yleensä järjestelmän skaalautuvuuden ylöspäin ja sitä kautta toimintavarmuuden käyttöpiikkien sattuessa.",
    minkaTyyppisiinEiSovi:
        <text>
            Tietyissä kriittisissä järjestelmissä varatun kapasiteetin vapauttaminen ennakoivasti voi heikentää kykyä varautua äkillisiin kuormituspiikkeihin. Resursseja voi olla tarvetta
            pitää varalla tietyille toiminnoille jos niitä yhtäkkiä tarvitaankin. Esimerkiksi terveydenhuollossa käytetyissä järjestelmissä voi olla elintärkeää varmistaa jatkuva toimintakyky.
        </text>,
    kysymyksetHankkijanSuuntaan:
        <text>
        </text>,
    copyPastevaatimus:
        <text>
            Taustajärjestelmä käyttää vain tarvitsemiaan palvelinresursseja ja vapauttaa tarpeettomat palvelimet / palvelinresurssit muiden järjestelmien käyttöön.
        </text>,
    arvioKriteerinHinnasta:
        <text>
            Kriteerin toteuttamisen hinta riippuu järjestelmän monimutkaisuudesta ja sen toimintamalleista. Yleisesti ottaen kriteerin toteutuminen on osa järjestelmän normaalia toimintaa eikä
            poikkeustilanteiden ulkopuolella aiheuta ylimääräisiä kustannuksia. Esimerkiksi virtuaalikoneita käytettäessä on muutenkin tärkeää hallita instansseille annettuja resursseja.
        </text>,
    arvioKriteerinYmparisto:
        <text>
            Taustajärjestelmän osalta voidaan saavuttaa merkittäviä säästöjä palvelimien virrankulutukseen, mikäli kuormituksen vaihtelut ovat suuria (esim. yöaikaan / työajan ulkopuolella
            järjestelmän käyttö hyvin vähäistä). Toisaalta globaalissa käytössä olevassa järjestelmässä kriteerin hyöty taustajärjestelmien osalta voi jäädä vähäiseksi. On myös huomattavaa, että
            kriteerin huomioiminen mahdollistaa yleensä järjestelmän skaalautuvuuden ylöspäin ja sitä kautta toimintavarmuuden käyttöpiikkien sattuessa.
        </text>,
    ohjeistusKriteerinKaytosta:
        <text>
          Suosittelemme kriteerin käyttöä vähimmäisvaatimuksena.
        </text>,
    todentaminen:
        <text>
          Toimittajan selvitys siitä, että taustajärjestelmä käyttää vain tarvitsemiaan palvelinresursseja ja vapauttaa tarpeettomat palvelimet / palvelinresurssit muiden järjestelmien käyttöön.
        </text>,
    ehdotusSopimuksenSeuranta:
        <text>
          Taustajärjestelmän hyötykäyttösuhdetta mitataan ja seurataan osana järjestelmän ylläpitoa.
        </text>,
    edellytyksetHyodyntamiselle:
        <text>
            Merkitys korostuu, jos ohjelmisto tai sen käyttötavat aiheuttavat ajoittaisia työkuormia tai piikkejä palvelinresurssien tarpeessa, ja pienenee jos ohjelmiston toiminta tai
            käyttötavat aiheuttavat tasaista kuormitusta.
        </text>,
    esimerkki:
        <text>
            Skaalattavien pilvijärjestelmien tehokas käyttö perustuu dynaamiseen tarjolla olevan kapasiteetin lisäämiseen ja vähentämiseen.
            Kuormituksen tasaaminen on myös palvelimien toimintakyvyn parantamisessa usein käytetty menetelmä.
        </text>,
    mittausJaViitearvot:
        <text>
            Kriteerin ymmärtämisessä auttaa jos voidaan tarkastella varsinaista dataa palvelinkeskuksen kuormituksesta ja suhteuttaa sitä ohjelmiston toimintaan.
        </text>,
    kytkeytyvatKriteerit:
        <text>
            Kuormituksen seuranta ylläpitäjälle
        </text>

};

export default PerustasonKriteeriA2Tekstit;
