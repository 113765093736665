import React from "react";

const EdistynytKriteeriB3Tekstit = {
  id: "B3",
  nimi: <text>B3 Kriteerikortti: Tulevaisuuden ennakointi</text>,
  kriteeri:
    <text>
      Ohjelmiston data ja dataformaatit siirrettävissä toisiin ohjelmistoihin, ohjelmiston jatkuvuus ja pitkä elinikä.
    </text>,
  plainKriteeri:
    "Ohjelmiston data ja dataformaatit siirrettävissä toisiin ohjelmistoihin, ohjelmiston jatkuvuus ja pitkä elinikä.",
  kriteerinLyhytKuvaus:
    <text>
      Ohjelmiston käsittelemä data on jopa yksittäistä ohjelmistoa tärkeämpi ja arvokkaampi liiketoiminnallinen varallisuuserä.
      Ideaalitilanteessa ohjelmiston elinkaaren aikana sen käyttämää dataa voidaan käsitellä halutussa laajuudessa myös järjestelmän
      ulkopuolelta ohjelmiston tarjoamien rajapintojen kautta, mikäli valitut dataformaatit ovat yhteensopivia. Näiden kahden
      suunnittelussa voidaan myös ennakoida tulevia käyttökohteita. <br />
      Vastaavasti ohjelmiston elinkaaren lopussa sen käyttämä data on otettavissa käyttöön seuraavassa järjestelmässä ilman erityisiä
      datan migraatioon liittyviä haasteita, mikäli datan hallinnan elinkaari on huomioitu ohjelmiston suunnittelussa. Ideaalisti uutta
      ja vanhaa järjestelmää voidaan ajaa tarvittaessa rinnakkain, mikäli uuden käyttöönottoon liittyy mahdollisia epävarmuuksia. <br />
      Datan siirrettävyyden takaaminen edellyttää joko datan viennin toteuttamista järjestelmästä, tai arkkitehtuuriratkaisuja dataan
      pääsyyn tarvittaessa alkuperäisen järjestelmän ulkopuolelta sekä sellaisen datan organisoinnin ja rakenteen, että data on mahdollista
      tuoda järjestelmästä vaivattomasti.
    </text>,
  perustelu:
    <text>
      Kriteerin merkittävimmät vaikutukset ovat toimittajaloukkujen välttäminen, tarpeettomien ja ylläpitoa ja mahdollisesti infrastruktuuria
      vaativien datan muunnosprosessien välttäminen ja järjestelmien riippuvuuksien vähentäminen.
    </text>,
  plainPerustelu:
    "Kriteerin merkittävimmät vaikutukset ovat toimittajaloukkujen välttäminen, tarpeettomien ja ylläpitoa ja mahdollisesti infrastruktuuria " +
    "vaativien datan muunnosprosessien välttäminen ja järjestelmien riippuvuuksien vähentäminen.",
  minkaTyyppisiinEiSovi:
    <text>
      Järjestelmät, jotka eivät tuota pysyvää dataa, ohjelmistot joiden dataa ei haluta siirtää järjestelmien välillä.
    </text>,
  kysymyksetHankkijanSuuntaan:
    <text>
      Käsitelläänkö ohjelmiston tuottamaa dataa muissa ohjelmistoissa? <br />
      Onko ohjelmiston käsittelemiin datatyyppeihin saataville avoimia dataformaatteja? <br />
      Onko ohjelmiston käsittelemälle datalle odotettavissa myöhempää käyttöä järjestelmän käytön päätyttyä? <br />
    </text>,
  copyPastevaatimus:
    <text>
      Ohjelmiston data ja dataformaatit siirrettävissä toisiin ohjelmistoihin, ohjelmiston jatkuvuus ja pitkä elinikä.
    </text>,
  arvioKriteerinHinnasta:
    <text>
      Kriteerin vaatiman suunnittelun ja dokumentaation toteuttaminen ennakoivasti aiheuttaa pieniä lisäkuluja kehitysprosessiin. Standardiformaattien
      suosiminen oletusarvoisesti jopa tehostaa uusien järjestelmien määrittelytyötä. Vanhojen, jo käytössä olevien omisteisten ja dokumentoimattomien
      formaattien yhteensopivuus ja takaisinmallinnus voi aiheuttaa toisaalta huomattavia kuluja, mutta myös näiden järjestelmien osalta formaattien migraation
      varhainen huomiointi todennäköisesti pienentää datan elinkaaren hallinnan kokonaiskustannuksia.
    </text>,
  arvioKriteerinYmparisto:
    <text>
      Kriteerin merkittävimmät vaikutukset ovat toimittajaloukkujen välttäminen, tarpeettomien ja ylläpitoa ja mahdollisesti infrastruktuuria vaativien datan
      muunnosprosessien välttäminen ja järjestelmien riippuvuuksien vähentäminen.
    </text>,
  ohjeistusKriteerinKaytosta:
    <text>
      TBA
    </text>,
  todentaminen:
    <text>
      TBA
    </text>,
  ehdotusSopimuksenSeuranta:
    <text>
      TBA
    </text>,
  edellytyksetHyodyntamiselle:
    <text>
      Hankintaorganisaation pitää pystyä arvioimaan uusien dataformaattien valintaperusteita sekä vanhojen järjestelmien käyttämien formaattien yhteensopivuuden
      takaamiseksi tarvittavien toimenpiteiden työmääriä ja mielekkyyttä. Ideaalisti dataformaatit pitäisi tuoda osaksi datan hallinnan strategioita organisaatiotasolla.
    </text>,
  esimerkki:
    <text>
      Ohjelmiston virtamittauksen virtamittarien toimittaja tarjoaa asiakkailleen Android-ohjelmiston, joka mahdollistaa mittausdatan selaamisen älypuhelimen näytöltä.
      Ohjelman taltioi myös dataa älypuhelimen sisäiseen muistiin, mutta formaattia ei ole dokumentoitu. Formaatti kyetään takaisinmallintamaan ja tunnistamaan SQLite-tietokannaksi.
      Kuitenkin ohjelmistopäivityksen jälkeen sekä datan sijainti että formaatti muuttuvat eikä uutta formaattia kyetä lukemaan standardityökaluilla.
    </text>,
  mittausJaViitearvot:
    <text>
      Dataformaattien yhteensopivuutta voidaan mitata ja arvioida systemaattisesti testaamalla järjestelmien tekemää formaattien jäsentämistä ja sarjallistamista esimerkkidokumenttien
      ja -sanomien avulla. Joissakin tapauksissa formaattien toteutuksia voidaan laatia suoraan formaatin skeemamäärittelystä, jolloin voidaan keskittyä skeemojen yhteensopivuuteen.
      Tarkkoja viitearvoja on vaikea määrittää, sillä pienikin epäyhteensopivuus voi estää datan käytön kokonaan ilman manuaalista datan korjaamista.
    </text>,
  kytkeytyvatKriteerit:
    <text>
      Ohjelmiston taaksepäin yhteensopivuus, Ohjelmiston rajapinnat ovat käytettäviä ja jatkuvia, API on selkeästi dokumentoitu.
    </text>,
  lisatiedotJalahteet:
    <text>
      Data Portability in Practice, Babak Jahromi, Microsoft, 2019 <br />
      Lait, Leslie R., Eric R. Nash, and Paul A. Newman. The df: A proposed data format standard. No. NAS 1.15: 4467. 1993.
    </text>

};

export default EdistynytKriteeriB3Tekstit;
