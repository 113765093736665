import React from "react";

const PerustasonKriteeriA7Tekstit = {
  id: "A7",
  nimi: <text>A7 Kriteerikortti: Ohjelmisto sallii asetusten muokkaamisen </text>,
  kriteeri:
    <text>
      Ohjelmisto sallii käyttäjän muokata ohjelmiston toimintoja ja asetuksia tarpeisiinsa.
    </text>,
  plainKriteeri:
    "Ohjelmisto sallii käyttäjän muokata ohjelmiston toimintoja ja asetuksia tarpeisiinsa.",
  kriteerinLyhytKuvaus:
    <text>
      Kaikilla käyttäjillä ei ole yhtäläisiä tarpeita ohjelmistolle. Ohjelmiston kuormituksen tulisi perustua ainoastaan niihin toiminnallisuuksiin, joita käyttäjä
      siltä kulloinkin tarvitsee / käyttää. Järjestelmän tulisi tukea mahdollisuutta poistaa käytöstä yksittäiselle käyttäjälle hyödyttömiä ominaisuuksia ja yleisesti
      sopeuttaa käyttöliittymää käyttäjälle tarkoituksenmukaiseksi, jotta turhia resursseja ei kuluteta.
    </text>,
  perustelu:
    <text>
      Riippuen ohjelmiston toimintamallista ja käyttötavoista, erilaisten käyttäjäryhmien tarpeet voivat vaihdella suuresti. Jos osa toiminnallisuuksista on relevantteja vain tietyille
      käyttäjille, kyky ottaa niitä pois käytöstä tai käyttöön aiheuttaa ohjelmiston elinkaaren aikana merkittäviä säästöjä käytettävyyden parantumisen ja käyttövirheiden pienenemisen johdosta.
      Samaten asetusten muokkaaminen antaa käyttäjän räätälöidä ohjelmiston toimintaa, jolloin hänen kykynsä käyttää sitä paranee.
    </text>,
  plainPerustelu:
    "Riippuen ohjelmiston toimintamallista ja käyttötavoista, erilaisten käyttäjäryhmien tarpeet voivat vaihdella suuresti. Jos osa toiminnallisuuksista on relevantteja vain tietyille " +
    "käyttäjille, kyky ottaa niitä pois käytöstä tai käyttöön aiheuttaa ohjelmiston elinkaaren aikana merkittäviä säästöjä käytettävyyden parantumisen ja käyttövirheiden pienenemisen johdosta. " +
    "Samaten asetusten muokkaaminen antaa käyttäjän räätälöidä ohjelmiston toimintaa, jolloin hänen kykynsä käyttää sitä paranee.",
  minkaTyyppisiinEiSovi:
    <text>
      Sellaiset joissa kaikki toiminnot ovat tarpeellisia jokaiselle käyttäjälle, tai joissa ei muusta syystä ole merkityksellistä muokata toimintaa.
    </text>,
  kysymyksetHankkijanSuuntaan:
    <text>
      Tarvitsevatko tietyt ohjelmiston käyttäjät erityistoimintoja, jotka ovat vain heidän käyttöönsä suunnattuja? <br />
      Onko ohjelmistossa käyttäjälle vapaaehtoisia toiminnallisuuksia? <br />
      Onko ohjelmisto poikkeuksellisen monimutkainen tai muuten käyttäjävirheille altis?
    </text>,
  copyPastevaatimus:
    <text>
      Ohjelmisto tarjoaa käyttäjälle mahdollisuuden muokata toimintoja / asetuksia tarpeisiinsa
    </text>,
  arvioKriteerinHinnasta:
    <text>
      Mittarin oletettu hinta on melko pieni, sillä sen toteuttaminen vaatii pääasiassa asetusten kehittämistä toimintojen muokkaamiseksi, eikä varsinaisten uusien toiminnallisuuksien kehittämistä.
    </text>,
  ohjeistusKriteerinKaytosta:
    <text>
    TBA
    </text>,
  todentaminen:
    <text>
      TBA
    </text>,
  ehdotusSopimuksenSeuranta:
    <text>
      TBA
    </text>,
  edellytyksetHyodyntamiselle:
    <text>
      Ohjelmiston toiminnoista pitää määrittää erikseen perustoiminnalle kriittiset osat, sekä ne osat jotka eivät välttämättä ole tarpeellisia kaikille käyttäjille tai yhdellekään käyttäjälle.
    </text>,
  esimerkki:
    <text>
      Esim. Officessa on LinkedIn-ominaisuuksia jotka on mahdollista ottaa pois käytöstä.
    </text>,
  kuva:
    <picture>
      {/* WebP */}
      <source
        srcSet="/pictures/linkedinFeatures.webp"
        type="image/webp"
      />
      {/* PNG jos webp ei käytössä */}
      <source
        srcSet="/pictures/linkedinFeatures.png"
        type="image/png"
      />
      {/* Fallback */}
      <img
        src="/pictures/linkedinFeatures.png"
        loading="lazy"
        alt="Kuva Officen Linkedin ominaisuudeta"
        className="kriteerikortti-images"
      />
    </picture>,
  kytkeytyvatKriteerit:
    <text>
      Muut toiminnalliset ominaisuudet, erityisesti sisältääkö ohjelmisto yleisesti tarpeettomia toiminnallisuuksia tai osia ja offline-toiminnallisuudet.
    </text>

};

export default PerustasonKriteeriA7Tekstit;
