import React from "react";

const EdistynytKriteeriB2Tekstit = {
  id: "B2",
  nimi: <text>B2 Kriteerikortti: Ohjelmiston taaksepäin yhteensopivuus</text>,
  kriteeri:
    <text>
      Ohjelmisto on suoritettavissa X vanhalla referenssijärjestelmällä, joka on hankkijaorganisaation määrittämä laitteistokokoonpano.
    </text>,
  plainKriteeri:
    "Ohjelmisto on suoritettavissa X vanhalla referenssijärjestelmällä, joka on hankkijaorganisaation määrittämä laitteistokokoonpano.",
  kriteerinLyhytKuvaus:
    <text>
      Taaksepäin yhteensopivuus tarkoittaa sitä, että ohjelmisto pystyy toimimaan vanhentuneen järjestelmän tai vanhentuneelle järjestelmälle tarkoitetun syötteen kanssa. <br />
      Taaksepäin yhteensopivuuden takaaminen on merkittävää sen takia, että ohjelmistojen asettamat vaatimukset ovat yleinen syy laitteiston korvaamiselle, millä on suoria ympäristövaikutuksia. <br />
      Kriteeriä sovellettaessa sen tärkein vaikutuskeino on jatkuvasti kasvavien laitteistovaatimusten trendiin puuttuminen vaatimuksia rajoittamalla, minkä tarkoitus on pääasiassa pidentää
      olemassa olevan laitteiston käyttöikää. Kriteeriä käyttäen voidaan myös pyrkiä eriyttämään ohjelmistojen sekä ohjelmistokomponenttien väliset vaatimukset toisistaan. Ideaalitilanteessa
      ohjelmiston ja laitteiston korvaamisen syklit erotetaan täysin toisistaan.
    </text>,
  perustelu:
    <text>
      Kriteerillä voi olla erittäin merkittävät ympäristövaikutukset, sillä se tarkoittaa että hankkijaorganisaation laitteiden käyttöikä pitenee.
      Sähköinen jäte on merkittävä ympäristöhaitta. Laitteiden iän pidentäminen vähentää sen määrää huomattavasti.
    </text>,
  plainPerustelu:
    "Kriteerillä voi olla erittäin merkittävät ympäristövaikutukset, sillä se tarkoittaa että hankkijaorganisaation laitteiden käyttöikä pitenee. " +
    "Sähköinen jäte on merkittävä ympäristöhaitta. Laitteiden iän pidentäminen vähentää sen määrää huomattavasti.",
  minkaTyyppisiinEiSovi:
    <text>
      Uudet ja paljon suoritustehoa vaativat ohjelmistot, tai jotka muuten vaativat hyvin spesifiä laitteistoa eivät luonteensa vuoksi toimi vanhemmilla laitteistoilla. Toisaalta vaatimus on vähemmän
      relevantti jos ohjelmisto on tarpeeksi kevyt pyöriäkseen käytännössä millä tahansa käytössä olevalla laitteistolla.
    </text>,
  kysymyksetHankkijanSuuntaan:
    <text>
      Vaatiko ohjelmisto laitteistolta paljon suoritustehoa tai muistia? <br />
      Onko ohjelmisto kevyt, esimerkiksi yksinkertainen selainpohjainen sovellus?
    </text>,
  copyPastevaatimus:
    <text>
      Ohjelmisto on suoritettavissa ongelmitta X vanhalla referenssijärjestelmällä, joka on hankkijaorganisaation määrittämä laitteistokokoonpano.
    </text>,
  arvioKriteerinHinnasta:
    <text>
      Kriteerin toteuttaminen aiheuttaa kustannuksia kehityksessä, jos yhteensopivuuden varmistaminen vie kehittäjien aikaa. Varsinkin yhteensopivuuden testaamisesta aiheutuu kuluja.
      Tämä myös rajoittaa ohjelmiston jatkokehitystä, sillä kriteeriä tulee ylläpitää tulevaisuudessakin.
    </text>,
  arvioKriteerinYmparisto:
    <text>
      Kriteerillä voi olla erittäin merkittävät ympäristövaikutukset, sillä se tarkoittaa että hankkijaorganisaation laitteiden käyttöikä pitenee. Sähköinen jäte on merkittävä ympäristöhaitta.
      Laitteiden iän pidentäminen vähentää sen määrää huomattavasti.
    </text>,
  ohjeistusKriteerinKaytosta:
    <text>
      TBA
    </text>,
  todentaminen:
    <text>
      TBA
    </text>,
  ehdotusSopimuksenSeuranta:
    <text>
      TBA
    </text>,
  edellytyksetHyodyntamiselle:
    <text>
      Hankkijan pitää pystyä arvioimaan relevantteja referenssijärjestelmiä, jotka voi asettaa vaatimukseksi taaksepäin yhteensopivuudelle.
    </text>,
  esimerkki:
    <text>
      Steam lopettaa Windows 7 ja 8 -käyttöjärjestelmien tukemisen 2024. Windows 7 julkaistiin 2009, eli se oli yhteensopiva Steamin kanssa yli 14 vuotta.
    </text>,
  kuva:
    <picture>
      {/* WebP */}
      <source
        srcSet="/pictures/steamsupport.webp"
        type="image/webp"
      />
      {/* PNG jos webp ei käytössä */}
      <source
        srcSet="/pictures/steamsupport.png"
        type="image/png"
      />
      {/* Fallback */}
      <img
        src="/pictures/steamsupport.png"
        loading="lazy"
        alt="Kuva Steamin windows 7 ja 8 tuen päättymisestä"
        className="kriteerikortti-images"
      />
    </picture>,
  mittausJaViitearvot:
    <text>
      Viitearvot vaihtelevat riippuen tarkastelussa olevasta laitteesta tai tuotteesta. Kuitenkin, mitä vanhempi laitteisto toimii ohjelmiston kanssa sitä parempi.
    </text>,
  kytkeytyvatKriteerit:
    <text>
      Tulevaisuuden ennakointi, Tekninen optimointi.
    </text>,
  lisatiedotJaLahteet:
    <text>
      Ponomarenko, A., and V. Rubanov. Backward compatibility of software interfaces: Steps towards automatic verification. Programming and Computer Software 38.5 (2012): 257-267. <br />
      Kern, Eva, et al. &quot;Sustainable software products—Towards assessment criteria for resource and energy efficiency.&quot; Future Generation Computer Systems 86 (2018): 199-210.
    </text>

};

export default EdistynytKriteeriB2Tekstit;
