import React, { Component, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";


import FrontPage from './pages/FrontPage';
import MitvidiTyokalu from './pages/MitvidiTyokalu';
import Kriteerikortit from './pages/Kriteerikortit';
import MitividiOpas from './pages/MitividiOpas';
import Mittausjarjestely from './pages/Mittausjarjestely';


const ScrollToTop = () => { /* Avaa linkit sivun yläosasta */ 
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


class App extends Component {
  render() {
    return (
      <BrowserRouter>
      <ScrollToTop /> 
      <Routes>
        <Route path="/" element={<FrontPage />}> </Route>
        <Route path="/mitvidityokalu" element={<MitvidiTyokalu />}> </Route>
        <Route path="/mitvidikriteerikortit" element={<Kriteerikortit />}> </Route>
        <Route path="/mitvidiopas" element={<MitividiOpas />}> </Route>
        <Route path="/mitvidiopas/mittausjarjestely" element={<Mittausjarjestely />}> </Route>
      </Routes>
    </BrowserRouter>
    );
  }
}

export default App;