import React from "react";

const PerustasonKriteeriA6Tekstit = {
  id: "A6",
  nimi: <text>A6 Kriteerikortti: Ylläpitäjän mahdollisuus seurata ohjelmiston kuormitusta</text>,
  kriteeri:
    <text>
      Ohjelmisto sisältää toiminnallisuuden, jolla ylläpitäjä voi seurata ohjelmiston taustajärjestelmän virrankulutusta sekä sen aiheuttamaa kuormitusta palvelinten
      1) suorittimissa (CPU)  2) keskusmuistissa (RAM)  ja 3) tiedonsiirrossa.
    </text>,
  plainKriteeri:
    "Ohjelmisto sisältää toiminnallisuuden, jolla ylläpitäjä voi seurata ohjelmiston taustajärjestelmän virrankulutusta sekä sen aiheuttamaa kuormitusta palvelinten " +
    "1) suorittimissa (CPU)  2) keskusmuistissa (RAM)  ja 3) tiedonsiirrossa.",
  kriteerinLyhytKuvaus:
    <text>
      Ohjelmiston käyttötavoilla on merkitystä sen aiheuttamalle energiankulutukselle. Ylläpitäjät pystyvät tehostamaan ohjelmiston käytön aikaisen ajan ympäristöystävällisyyttä
      keräämällä tietoa ohjelmiston käytön aiheuttamasta kuormituksesta
    </text>,
  perustelu:
    <text>
      Järjestelmän ylläpitäjän on hyvä seurata taustajärjestelmän energiankulutusta ja resurssien käyttöä järjestelmän tuotantokäytön aikana. Näin pystytään havaitsemaan mahdolliset
      järjestelmän ympäristövaikutuksiin negatiivisesti vaikuttavat ongelmat, joista seuraa turhaa energiankulutusta ja voidaan puuttua niihin. Kun järjestelmän normaalit kulutusarvot on käytön myötä
      selvitetty, voidaan monitorointipalveluun ohjelmoida hälytysrajat, jolloin ylläpitäjä saa tiedon näiden ylittymisestä. Em. tietoja voidaan hyödyntää jo kehitystyön aikana, kun arvioidaan
      taustajärjestelmän tehokkuutta energiankulutuksen ja ympäristön näkökulmista.
    </text>,
  plainPerustelu:
    "Järjestelmän ylläpitäjän on hyvä seurata taustajärjestelmän energiankulutusta ja resurssien käyttöä järjestelmän tuotantokäytön aikana. Näin pystytään havaitsemaan mahdolliset " +
    "järjestelmän ympäristövaikutuksiin negatiivisesti vaikuttavat ongelmat, joista seuraa turhaa energiankulutusta ja voidaan puuttua niihin. Kun järjestelmän normaalit kulutusarvot on käytön myötä " +
    "selvitetty, voidaan monitorointipalveluun ohjelmoida hälytysrajat, jolloin ylläpitäjä saa tiedon näiden ylittymisestä. Em. tietoja voidaan hyödyntää jo kehitystyön aikana, kun arvioidaan " +
    "taustajärjestelmän tehokkuutta energiankulutuksen ja ympäristön näkökulmista.",
  minkaTyyppisiinEiSovi:
    <text>
      Moni nykyaikainen ohjelmisto on rakennettu erilaisten palveluntarjoajien alustojen päälle, jolloin voi olla vaikeaa päästä käsiksi haluttuun tietoon. Kriteerin merkitys myös pienenee
      jos sovelluksella on hyvin rajalliset käyttötavat, jolloin niiden aiheuttama kulutus pysyy melko samana käyttäjästä huolimatta.
    </text>,
  kysymyksetHankkijanSuuntaan:
    <text>
      Onko ohjelmistossa raskaita toiminnallisuuksia, joita käyttäjä voi käyttää väärin tai pitää tarpeettomasti päällä? <br />
      Onko ohjelmistossa toimintoja jotka ovat vain muutaman käyttäjän käytössä, tai muuten merkittävästi erilaisia käyttötapoja eri käyttäjäryhmien välillä? <br />
      Rakennetaanko ohjelmisto palveluntarjoajan alustan päälle (esim. Wordpress)?
    </text>,
  copyPastevaatimus:
    <text>
      Ohjelmisto sisältää toiminnallisuuden, jolla ylläpitäjä voi seurata taustajärjestelmien virrankulutusta sekä sen aiheuttamaa kuormitusta palvelinten
      i) suorittimessa (CPU) , ii) keskusmuistissa (RAM) ja iii) tiedonsiirrossa.
    </text>,
  arvioKriteerinHinnasta:
    <text>
      Kriteerin toteuttaminen vaatii erillisten palauteominaisuuksien ja ohjeiden suunnittelua ja kehittämistä. Kustannukset ovat todennäköisesti kuitenkin pienet, sillä toiminnallisuus ei
      itsessään ole monimutkainen. Laitteiston kuormitukseen vaikuttaa rajallinen määrä tekijöitä, ja näiden kerääminen on usein mahdollista esimerkiksi käyttöjärjestelmän API:sta.
    </text>,
  arvioKriteerinYmparisto:
    <text>
      Järjestelmän ylläpitäjän on hyvä seurata taustajärjestelmän energiankulutusta ja resurssien käyttöä järjestelmän tuotantokäytön aikana. Näin pystytään havaitsemaan mahdolliset järjestelmän
      ympäristövaikutuksiin negatiivisesti vaikuttavat ongelmat ja puuttumaan niihin. Kun järjestelmän normaalit kulutusarvot on käytön myötä selvitetty, voidaan monitorointipalveluun
      ohjelmoida hälytysrajat, jolloin ylläpitäjä saa tiedon näiden ylittymisestä. Em. tietoja voidaan hyödyntää jo kehitystyön aikana, kun arvioidaan taustajärjestelmän tehokkuutta
      energiankulutuksen ja ympäristön näkökulmista.
    </text>,
  ohjeistusKriteerinKaytosta:
    <text>
      TBA
    </text>,
  todentaminen:
    <text>
      TBA
    </text>,
  ehdotusSopimuksenSeuranta:
    <text>
      TBA
    </text>,
  edellytyksetHyodyntamiselle:
    <text>
      Ylläpitäjällä tulee olla pääsy relevanttiin dataan ja kyky hyödyntää sitä.
    </text>,
  esimerkki:
    <text>
      Ohjelmiston käytöstä ja käyttötavoista kerätään usein analytiikkatietoa, ainakin jos sovellus sijaitsee verkossa. Tämä tarkoittaa esimerkiksi sen seuraamista, mitä toiminnallisuuksia
      käytetään ja kuinka pitkään käyttäjät viipyvät tietyillä sivuilla. Ohjelmiston käyttöön liittyvää kuormitusta voitaisiin käsitellä osana tätä käyttäjädataa.
    </text>,
  mittausJaViitearvot:
    <text>
      Ohjelmiston tulisi joko arvioida tai mitata aiheuttamaansa kuormitusta, tämän ei tarvitse olla tarkkaa mutta pitäisi olla luotettavaa
    </text>
};

export default PerustasonKriteeriA6Tekstit;
