import React from "react";

const EdistynytKriteeriB7Tekstit = {
  id: "B7",
  nimi: <text>B7 Kriteerikortti: Ohjelmistonkehityksen tekniset valinnat pyrkivät tehokkuuteen </text>,
  kriteeri:
    <text>
      Kehittäjä on ohjelmistoa kehittäessään varmistanut, että ohjelmiston kehityksen aikana tehdyt tekniset valinnat edistävät ohjelmiston tehokkuutta. <br />
      Ohjelmiston tehokkuudella tarkoitetaan sitä, että ohjelmisto käyttää optimaalisesti saatavilla olevia resursseja kuten suoritinta ja muistia.
    </text>,
  plainKriteeri:
    "Kehittäjä on ohjelmistoa kehittäessään varmistanut, että ohjelmiston kehityksen aikana tehdyt tekniset valinnat edistävät ohjelmiston tehokkuutta. \n " +
    "Ohjelmiston tehokkuudella tarkoitetaan sitä, että ohjelmisto käyttää optimaalisesti saatavilla olevia resursseja kuten suoritinta ja muistia.",
  kriteerinLyhytKuvaus:
    <text>
      Ohjelmistonkehityksessä on tehokkainta suunnitella ohjelmisto alusta alkaen laadukkaasti, sillä kehitysprojektin aikana kertyy aina teknistä velkaa joka
      aiheuttaa suuria kustannuksia jos muutoksia tarvitsisi tehdä myöhemmin elinkaaren aikana. Ohjelmiston kehittäminen tietyin tavoin myöhemmässä vaiheessa voi jopa osoittautua
      kustannusten takia tehottomaksi. Kun tekniset valinnat ohjaavat ohjelmistoa oikeaan suuntaan jo alkuvaiheissa ohjelmiston käyttö tulee olemaan ympäristöystävällisempää ja sen kehitys
      ja ylläpito helpompaa. <br />

      Tähän sisältyy ohjelmiston kehitysalusta, käytetty ohjelmointikieli, dataformaattien toiminta, sekä yleensä useita koodikirjastoja tai -kehyksiä. Tietyt toiminnot voidaan toteuttaa
      useilla eri algoritmeilla, joista tietyt sopivat tehtävään paljon paremmin kuin toiset. <br />

      Tämän lisäksi esimerkiksi verkkopalvelun käytön aikana palvelimien toiminta sekä sivuille ladattavien resurssien käyttö
      tulisi määrittää huolella. Näiden valitsemisessa on tärkeää välttää resurssien hukkaan menemistä, ja sopeuttaa valinnat
      mahdollisimman hyvin ohjelmiston tarkoitukseen.
    </text>,
  perustelu:
    <text>
      Ohjelmiston kehitysympäristön, käytetyn ohjelmointikielen ja muiden arkkitehturaalisten ohjelmiston toimintaa määrittävien teknisten valintojen
      kerrannaisvaikutus on todella merkittävä ohjelmiston elinkaaren lopullisen ympäristövaikutuksen kannalta. Esimerkiksi tarpeettomien koodikirjastojen
      käyttö voi sekä hidastaa ohjelmiston suoritusnopeutta että merkittävästi vaikeuttaa sen ylläpitoa, ilman varsinaista hyötyä kenellekään.
    </text>,
  plainPerustelu:
    "Ohjelmiston kehitysympäristön, käytetyn ohjelmointikielen ja muiden arkkitehturaalisten ohjelmiston toimintaa määrittävien teknisten valintojen " +
    "kerrannaisvaikutus on todella merkittävä ohjelmiston elinkaaren lopullisen ympäristövaikutuksen kannalta. Esimerkiksi tarpeettomien koodikirjastojen " +
    "käyttö voi sekä hidastaa ohjelmiston suoritusnopeutta että merkittävästi vaikeuttaa sen ylläpitoa, ilman varsinaista hyötyä kenellekään.",
  minkaTyyppisiinEiSovi:
    <text>
      Jos ohjelmisto toteutetaan valmiin alustan päälle, esimerkiksi Wordpress-sivustot tai pilvialustat, voi olla että tekniset valinnat on paljolti
      jo tehty jolloin niitä ei voi olennaisesti muuttaa. Kriteeriä on vaikeaa soveltaa olemassa oleviin ohjelmistoihin.
    </text>,
  kysymyksetHankkijanSuuntaan:
    <text>
      Onko ohjelmiston tekninen toteutus (ohjelmointikieli tms.) rajoitettu alustan tai muiden syiden johdosta? <br />
      Voiko kehittäjä itse tarjota ohjelmiston toteutusalustan? <br />
      Onko kyseessä uuden ohjelmiston hankinta? <br />
      Onko ohjelmistossa paljon kuvia, videoita tai muuta mediaa?
    </text>,
  copyPastevaatimus:
    <text>
      Kehittäjä on ohjelmistoa kehittäessään varmistanut, että ohjelmiston kehityksen aikana tehdyt tekniset valinnat edistävät ohjelmiston tehokkuutta. <br />
      Ohjelmiston tehokkuudella tarkoitetaan sitä, että ohjelmisto käyttää optimaalisesti saatavilla olevia resursseja kuten suoritinta ja muistia.
    </text>,
  arvioKriteerinHinnasta:
    <text>
      Kriteerin toteutus aiheuttaa lisää kehitystyötä, sillä kehittäjillä ei todennäköisesti ole osaamista tai tottumusta vastaavien asioiden arviointiin.
      Kustannukset aiheuttavat pääasiassa vaadittujen työtuntien noususta.
    </text>,
  arvioKriteerinYmparisto:
    <text>
      Ohjelmiston kehitysympäristön, käytetyn ohjelmointikielen ja muiden arkkitehturaalisten ohjelmiston toimintaa määrittävien teknisten valintojen kerrannaisvaikutus on todella merkittävä
      ohjelmiston elinkaaren lopullisen ympäristövaikutuksen kannalta.Esimerkiksi tarpeettomien koodikirjastojen käyttö voi sekä hidastaa ohjelmiston suoritusnopeutta että merkittävästi
      vaikeuttaa sen ylläpitoa, ilman varsinaista hyötyä kenellekään.
    </text>,
  ohjeistusKriteerinKaytosta:
    <text>
      TBA
    </text>,
  todentaminen:
    <text>
      TBA
    </text>,
  ehdotusSopimuksenSeuranta:
    <text>
      TBA
    </text>,
  edellytyksetHyodyntamiselle:
    <text>
      Hankkijaorganisaatio tarvitsee tietoteknistä osaamista, jotta se voi järkevästi määrittää kehittäjäorganisaatiolle puitteita ohjelmistoon.
      Toisaalta tämä voidaan aikaansaada it-konsulttien avulla.
    </text>,
  esimerkki:
    <text>
      Kuvien tehokas pakkaaminen voi pienentää kuvan koon murto-osaan alkuperäisestä, aiheuttamatta kuitenkaan käyttäjälle merkittävää kuvanlaadun heikkenemistä. Esimerkin
      kuvatiedoston kokoa on pienennetty 66 %.
    </text>,
  kuva:
    <picture>
      {/* WebP */}
      <source
        srcSet="/pictures/tomaatti.webp"
        type="image/webp"
      />
      {/* PNG jos webp ei käytössä */}
      <source
        srcSet="/pictures/tomaatti.png"
        type="image/png"
      />
      {/* Fallback */}
      <img
        src="/pictures/tomaatti.png"
        loading="lazy"
        alt="Kuva tehokkaasti pakatusta tomaatista"
        className="kriteerikortti-images"
        style={{ maxHeight: '700px' }} /* POikkeuksellisesti, koska muuten tomaattikuva valtaa koko sivun*/
      />
    </picture>,
  mittausJaViitearvot:
    <text>
      Kriteerin mittaus toimii olennaisimmin ohjelmiston toimintaa testaamalla, ja vertailemalla eri tavoin toteutettuja ohjelmistoja toisiinsa.
      Tehokkainta on verrata ohjelmiston eri versioita toisiinsa.
    </text>,
  lisatiedotJaLahteet:
    <text>
      Pereira, Rui, et al. &quot;Energy efficiency across programming languages: how do energy, time, and memory relate?.&quot; Proceedings of the 10th ACM SIGPLAN
      international conference on software language engineering. 2017. <br />

      Capra, Eugenio, Chiara Francalanci, and Sandra A. Slaughter. &quot;Is software “green”? Application development environments and energy efficiency in open source applications.&quot; Information and
      Software Technology 54.1 (2012): 60-71.
    </text>

};

export default EdistynytKriteeriB7Tekstit;
