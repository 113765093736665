import React from "react";



const EdistynytKriteeriB4Tekstit = {
  id: "B4",
  nimi: <text>B4 Kriteerikortti: Avoimuus</text>,
  kriteeri:
    <text>
      Läpinäkyvyys ja selkeys ohjelmiston lähdekoodissa, ohjelmistossa ja sen komponenteissa, ohjelmiston dokumentaatiossa ja käyttöehdoissa.
    </text>,
  plainKriteeri:
    "Läpinäkyvyys ja selkeys ohjelmiston lähdekoodissa, ohjelmistossa ja sen komponenteissa, ohjelmiston dokumentaatiossa ja käyttöehdoissa.",
  kriteerinLyhytKuvaus:
    <text>
      Ohjelmiston lähdekoodi mahdollistaa ohjelmiston jatkokehityksen ja mukauttamisen siihen kohdistuvien vaatimusten tai toimintaympäristön
      muuttuessa, tai reaktiona ohjelmistossa havaittuihin ongelmiin ja puutteisiin. Lähdekoodin avoin saatavuus on usein edellytys sen kehittämiseksi.
      Tehokas ja taloudellinen ohjelmistokehitys edellyttää myös läpinäkyvää ja avointa ohjelmiston dokumentointia sekä läpinäkyvästi kuvattua ohjelmiston
      koontiprosessia. Nykyaikaisessa ohjelmistotuotannossa myös ohjelmiston käännös ja julkaisun koonti on yleensä automatisoitu tehtävään käytettävällä
      lähdekoodilla. <br />
      Läpinäkymättömyys ja selkeyden puute lisäävät ylläpito- ja kehityskustannuksia, erityisesti uusien järjestelmien käyttöönoton yhteydessä. Pahimmillaan
      järjestelmän osia on toteutettava uudelleen. Vastaavat ongelmat koskevat ohjelmiston ja sen komponenttien käyttöä tai dokumentaation puutteita; toteutetun
      toiminnallisuuden käyttö edellyttää sen hallintaa ja tietoisuutta toiminnallisuuden olemassaolosta. <br />
      Järjestelmien komponenttien lisensointi ja käyttöehdot voivat myös tuottaa ongelmia komponenttien yhteiskäytössä. Epäselvät tai läpinäkymättömät
      käyttöehdot voivat estää järjestelmän tulevia käyttötapoja.
    </text>,
  perustelu:
    <text>
      Avoimuus ja läpinäkyvyys mahdollistavat kunkin komponentin osalta laajan yhteistyön, erityisesti mikäli komponentti on julkisesti
      helposti saatavilla. Avoimen lähdekoodin julkaisualustat (GitHub, GitLab) mahdollistavat vakiomuotoisen komponentin koontiympäristön
      ja sitä kautta suosii tehokkaita käytäntöjä kehittää ja ottaa käyttöön komponentteja. Nämä seikat mahdollisesti edistävät omalta osaltaan
      muita ympäristön kannalta edullisia laadullisia ominaisuuksia.
    </text>,
  plainPerustelu:
    "Avoimuus ja läpinäkyvyys mahdollistavat kunkin komponentin osalta laajan yhteistyön, erityisesti mikäli komponentti on julkisesti " +
    "helposti saatavilla. Avoimen lähdekoodin julkaisualustat (GitHub, GitLab) mahdollistavat vakiomuotoisen komponentin koontiympäristön " +
    "ja sitä kautta suosii tehokkaita käytäntöjä kehittää ja ottaa käyttöön komponentteja. Nämä seikat mahdollisesti edistävät omalta osaltaan " +
    "muita ympäristön kannalta edullisia laadullisia ominaisuuksia.",
  minkaTyyppisiinEiSovi:
    <text>
      Järjestelmät, joille ei ole avoimia vaihtoehtoja ja joiden kehitystyöhön hankkijalla ei ole resursseja. Pienet ja yksinkertaiset järjestelmät, joiden toteutus on
      verrattain kevyttä dokumentointiin verrattuna.
    </text>,
  kysymyksetHankkijanSuuntaan:
    <text>
      Hyödyntääkö ohjelmisto standardimuotoista dataa ja -komponentteja tai muistuttaako se niitä? <br />
      Halutaanko ohjelmiston lähdekoodia käyttää jatkokehitykseen ja järjestelmän puutteiden korjaamiseen organisaation sisällä tai kolmannen osapuolen kanssa? <br />
      Kohdistuuko järjestelmään voimakasta jatkokehitystä ja/tai läpinäkyvyyttä ja dokumentointia vaativaa auditointia? <br />
      Vaarantaako järjestelmään mahdollisesti liittyvä ja realisoituva toimittajaloukku organisaation liiketoiminnallisia tavoitteita?
    </text>,
  copyPastevaatimus:
    <text>
      Läpinäkyvyys ja selkeys ohjelmiston lähdekoodissa, ohjelmistossa ja sen komponenteissa, ohjelmiston dokumentaatiossa ja käyttöehdoissa.
    </text>,
  arvioKriteerinHinnasta:
    <text>
      Avoimien ja läpinäkyvien komponenttien suosiminen kehitystyössä aiheuttaa pientä lisätyötä ennakoivasti, sillä komponentteja joudutaan mahdollisesti vertailemaan
      ja analysoimaan. Avoimuus ei välttämättä takaa, että valitut komponentit ovat tehokkaampia käytössä ja kehitystyössä. Toisaalta avoimuus ja läpinäkyvyys
      todennäköisesti vähentävät työmäärää projektin edetessä. Avoimuus mahdollistaa myös yhteistyön ja kehityskulujen jakamisen näiden komponenttien osalta.
    </text>,
  arvioKriteerinYmparisto:
    <text>
      Avoimuus ja läpinäkyvyys mahdollistavat kunkin komponentin osalta laajan yhteistyön, erityisesti mikäli komponentti on julkisesti helposti saatavilla. Avoimen
      lähdekoodin julkaisualustat (GitHub, GitLab) mahdollistavat vakiomuotoisen komponentin koontiympäristön ja sitä kautta suosii tehokkaita käytäntöjä kehittää
      ja ottaa käyttöön komponentteja. Nämä seikat mahdollisesti edistävät omalta osaltaan muita ympäristön kannalta edullisia laadullisia ominaisuuksia.
    </text>,
  ohjeistusKriteerinKaytosta:
    <text>
      TBA
    </text>,
  todentaminen:
    <text>
      TBA
    </text>,
  ehdotusSopimuksenSeuranta:
    <text>
      TBA
    </text>,
  edellytyksetHyodyntamiselle:
    <text>
      Hankintaorganisaation pitää pystyä tunnistamaan ja kartoittamaan avoimien ratkaisujen saatavuutta rakennettavan järjestelmän kontekstissa. Toteutettavan
      järjestelmän ja sen osien teknisen kehittäjien dokumentaation ja avoimuuden arviointi edellyttää joko omaa ja ulkopuolelta hankittua ohjelmistokehityksen osaamista.
    </text>,
  esimerkki:
    <text>
      The ODF Toolkit (https://github.com/tdf/odftoolkit) on avoimen lähdekoodin ohjelmistokomponentti mm. LibreOffice-toimisto-ohjelmiston tuottamien ja lukemien
      standardimuotoisten ISO/IEC 26300 -asiakirjojen lukemiseen ja muokkaamiseen. Komponentin projektisivulla on kuvattu komponentin käyttämä lisenssi (Apache 2.0),
      lähdekoodit sekä lähdekoodin dokumentaatio. Komponentti hyödyntää automatisoitua vakiomuotoista Maven-konfiguraationhallintaa ja on tätä kautta myös tehokkaasti
      kytkettävissä muiden ohjelmistojen osaksi. Komponentti pohjautuu standardiformaatteihin, joka on kuvattu ISO-standardissa.
    </text>,
  mittausJaViitearvot:
    <text>
      Järjestelmän kunkin komponentin osalta voidaan todeta dokumentaation olemassaolo sekä käyttöehtojen ja lisenssin avoimuus. Komponenttien ohjelmoitavuus ja
      tarjottavien rajapinnat on kuvattu kattavassa dokumentaatiossa. Avoimille lisensseille on useita standardimuotoja (esim. OSI ja FSF). Lisensoinnista tulee
      ilmetä rajoitukset komponentin käyttöön, uudelleenlevitykseen, patentoitavuuteen ja kaupalliseen hyödyntämiseen. Järjestelmän osien koostaminen sekä
      käyttöönotto tuotantoon tulisi olla kattavasti kuvattu ja toistettavissa vakioidussa kehitysympäristössä myös kun käytetään suljettuja komponentteja.
    </text>,
  kytkeytyvatKriteerit:
    <text>
      Ohjelmiston taaksepäin yhteensopivuus, Ohjelmiston rajapinnat ovat käytettäviä ja jatkuvia, API on selkeästi dokumentoitu.
    </text>,
  lisatiedotJaLahteet:
    <text>
      Feller, Joseph, and Brian Fitzgerald. Understanding open source software development. Addison-Wesley Longman Publishing Co., Inc., 2002. <br />
      Lerner, Josh, and Jean Tirole. "The scope of open source licensing." Journal of Law, Economics, and Organization 21.1 (2005): 20-56. <br />
      Smith, Peter. Software build systems: principles and experience. Addison-Wesley Professional, 2011. <br />
      Why open source software matters to your enterprise, Linux Foundation, 2020 <br />
      Reproducible builds, https://reproducible-builds.org/
    </text>
};

export default EdistynytKriteeriB4Tekstit;
