import { Link } from 'react-router-dom';
import React from 'react';


import HuipputasonKriteeriD1Tekstit from '../tekstit/huipputaso/HuipputasonKriteeriD1Tekstit';

/* Huipputason kriteerit mitvidikysely sivulle */

const AccordionitHuipputaso = ({ accordionCheckboxes, openAccordion, handleAccordionCheck, handleAccordionClick }) => {

    return (
        <div>
            <div className="accordion-topheader"> <h2>3. Huipputaso </h2> </div>
            <div className={`accordion-item ${openAccordion.includes(HuipputasonKriteeriD1Tekstit.id) ? 'open' : ''}`}>
                <div className="accordion-header" onClick={handleAccordionClick(HuipputasonKriteeriD1Tekstit.id)}>
                    <input
                        type="checkbox"
                        value={HuipputasonKriteeriD1Tekstit.id}
                        checked={accordionCheckboxes[HuipputasonKriteeriD1Tekstit.id]}
                        onChange={handleAccordionCheck}
                        aria-label='Accordion checkbox'
                    />
                    <p className="accordion-name"> {HuipputasonKriteeriD1Tekstit.nimi} </p>


                    {openAccordion.includes(HuipputasonKriteeriD1Tekstit.id) ? (
                        <span className="accordion-open-icon">&#9650;</span> // (▲)
                    ) : (
                        <span className="accordion-open-icon">&#9660;</span> // (▼)
                    )}


                </div>
                <div className="accordion-details">
                    <ul>
                        <li>
                            <strong>Kriteeri: </strong>
                            {HuipputasonKriteeriD1Tekstit.kriteeri}
                        </li>
                        <li>
                            <strong>Ehdotus kriteerin käytöstä: </strong>
                            {HuipputasonKriteeriD1Tekstit.ohjeistusKriteerinKaytosta}
                        </li>
                        <li>
                            <strong>Perustelu: </strong>
                            {HuipputasonKriteeriD1Tekstit.perustelu}
                        </li>
                        <li>
                            <strong>Todentaminen: </strong>
                            {HuipputasonKriteeriD1Tekstit.todentaminen}
                        </li>
                        <li>
                            <strong>Ehdotus sopimuksen seurannasta: </strong>
                            {HuipputasonKriteeriD1Tekstit.ehdotusSopimuksenSeuranta}
                        </li>
                    </ul>
                    <Link to="/mitvidikriteerikortit#kriteerikorttiD1" target="_blank" rel="noopener noreferrer">
                        <strong>Kriteerikortin tarkempi kuvaus</strong>
                    </Link>

                </div>
            </div>
        </div>
    );
};


export default AccordionitHuipputaso;