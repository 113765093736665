import React from "react";

const PerustasonKriteeriA5Tekstit = {
  id: "A5",
  nimi: <text>A5 Kriteerikortti: Loppukäyttäjän mahdollisuus seurata ohjelmiston kuormitusta</text>,
  kriteeri:
    <text>
      Ohjelmisto sisältää toiminnallisuuden, jolla loppukäyttäjä voi seurata ymmärrettävästi i) omien toimiensa vaikutusta ohjelmiston virrankulutukseen ja ii)
      ohjelmiston eri osien energiankulutusta (esim. web-sivuston yksittäiset sivut).
      <br />
      Ymmärrettävyydellä tarkoitetaan että vaikutuksen tulee olla havainnollistettu loppukäyttäjälle visaalisesti (esim. jatkuvasti näkyvillä olevalla olevat liikennevalot tai kuormituspalkit,
      jotka havainnollistavat kulloisenkin käyttötilanteen arvioitua virrankulutusta suhteutetettuna laitteiston keskimääräiseen, mitattuun virrankulutukseen).
    </text>,
  plainKriteeri:
    "Ohjelmisto sisältää toiminnallisuuden, jolla loppukäyttäjä voi seurata ymmärrettävästi i) omien toimiensa vaikutusta ohjelmiston virrankulutukseen ja ii)" +
    "ohjelmiston eri osien energiankulutusta (esim. web-sivuston yksittäiset sivut). \n" +
    "Ymmärrettävyydellä tarkoitetaan että vaikutuksen tulee olla havainnollistettu loppukäyttäjälle visaalisesti (esim. jatkuvasti näkyvillä olevalla olevat liikennevalot tai kuormituspalkit, " +
    "jotka havainnollistavat kulloisenkin käyttötilanteen arvioitua virrankulutusta suhteutetettuna laitteiston keskimääräiseen, mitattuun virrankulutukseen).",
  kriteerinLyhytKuvaus:
    <text>
      Ohjelmiston käyttötavoilla on merkitystä sen aiheuttamalle energiankulutukselle. Voidakseen seurata omien käyttötapojensa aiheuttamaa energiankulutusta ja muuttaakseen niitä
      vähemmän kuluttavaan suuntaan, käyttäjä tarvitsee tietoa siitä, miten hänen käyttötapansa vaikuttavat ohjelmiston kuormitukseen ja sitä kautta energiankulutukseen.
    </text>,
  perustelu:
    <text>
      Ohjelmiston käyttötavoilla on merkitysta sen aiheuttamalle energiankulutukselle. Voidakseen seurata omien käyttötapojensa aiheuttamaa energiankulutusta ja muuttaakseen niitä
      vähemmän kuluttavaan suuntaan, käyttäjä tarvitsee tietoa siitä, miten hänen käyttötapansa vaikuttavat ohjelmiston energiankulutukseen.
    </text>,
  plainPerustelu:
    "Ohjelmiston käyttötavoilla on merkitysta sen aiheuttamalle energiankulutukselle. Voidakseen seurata omien käyttötapojensa aiheuttamaa energiankulutusta ja muuttaakseen niitä " +
    "vähemmän kuluttavaan suuntaan, käyttäjä tarvitsee tietoa siitä, miten hänen käyttötapansa vaikuttavat ohjelmiston energiankulutukseen.",
  minkaTyyppisiinEiSovi:
    <text>
      Moni nykyaikainen ohjelmisto on rakennettu erilaisten palveluntarjoajien alustojen päälle, jolloin voi olla vaikeaa päästä käsiksi haluttuun tietoon. Kriteerin merkitys myös
      pienenee jos sovelluksella on hyvin rajalliset käyttötavat, jolloin niiden aiheuttama kulutus pysyy melko samana käyttäjästä huolimatta.
    </text>,
  kysymyksetHankkijanSuuntaan:
    <text>
      Onko ohjelmistossa raskaita toiminnallisuuksia, joita käyttäjä voi käyttää väärin tai pitää tarpeettomasti päällä? <br />
      Onko ohjelmistossa toimintoja jotka ovat vain muutaman käyttäjän käytössä, tai muuten merkittävästi erilaisia käyttötapoja eri käyttäjäryhmien välillä? <br />
      Rakennetaanko ohjelmisto palveluntarjoajan alustan päälle (esim. Wordpress)?
    </text>,
  copyPastevaatimus:
    <text>
      Ohjelmisto sisältää toiminnallisuuden jolla loppukäyttäjä voi seurata selkeällä tavalla <br />
      <strong>i)</strong> omien toimiensa vaikutusta järjestelmän virrankulutukseen ja
      <strong>ii)</strong> järjestelmän eri osien ympäristövaikutuksia (esim. web-sivuston yksittäiset sivut).
    </text>,
  arvioKriteerinHinnasta:
    <text>
      Kriteerin toteuttaminen vaatii erillisten palauteominaisuuksien ja ohjeiden suunnittelua ja kehittämistä. Kustannukset ovat todennäköisesti kuitenkin pienet, sillä
      toiminnallisuus ei itsessään ole monimutkainen. Laitteiston kuormitukseen vaikuttaa rajallinen määrä tekijöitä, ja näiden kerääminen on usein mahdollista esimerkiksi
      käyttöjärjestelmän API:sta.
    </text>,
  arvioKriteerinYmparisto:
    <text>
      Toimittajan antamaa dokumentaatiota ylläpidetään ja tarkistetaan, käyttäjäkokemukseen
      voidaan myös kohdistaa selvityksiä käyttötapojensa aiheuttamaa energiankulutusta ja muuttaakseen niitä vähemmän kuluttavaan suuntaan, käyttäjä tarvitsee tietoa siitä, miten
      hänen käyttötapansa vaikuttavat ohjelmiston energiankulutukseen. Tämä tieto olisi hyvä tarjota käyttäjälle selkeänä, jatkuvasti näkyvillä olevalla visualisointina (esim. liikennevalo,
      kuormituspalkit), jossa kulloisenkin käyttötilanteen arvioitu virrankulutus suhteutetaan järjestelmän keskimääräiseen, mitattuun virrankulutukseen. Käyttäjälle voidaan lisäksi tarjoilla
      tarkempaa tietoa suorittimen kuormituksesta, muistinkäytöstä ja verkkoliikenteestä.
    </text>,
  ohjeistusKriteerinKaytosta:
    <text>
      Suosittelemme kriteerin käyttöä pisteytettävänä.
    </text>,
  todentaminen:
    <text>
      Toiminnallisuuden olemassaolo varmistetaan ja toimittaja laatii selvityksen jossa käsitellään toiminnallisuuden käyttämien virrankulutuksen arvioiden laskentamenetelmät.
    </text>,
  ehdotusSopimuksenSeuranta:
    <text>
      Testausvaiheessa tarkistetaan toiminnallisuuden olemassaolo ja toimivuus. Visualisoinnin toimivuus varmistetaan vertaamalla sitä tietyllä aikavälillä kerättyyn keskiarvoiseen käyttödataan.
    </text>,
  edellytyksetHyodyntamiselle:
    <text>
      Ohjelmiston toiminnallisuudet ovat tarpeeksi monimutkaisia, jotta käyttäjä tarvitsee opastusta niiden käyttämisessä ympäristöystävällisesti ja jotta kuormituksen monitorointi on merkityksellistä.
    </text>,
  esimerkki:
    <text>
      Zoom antaa käyttäjälle tietoa siitä millaista kuormitusta se aiheuttaa, ja myös mahdollistaa asetusten kautta toiminnan muuttamista.
    </text>,
  kuva:
    <picture>
      {/* WebP */}
      <source
        srcSet="/pictures/zoomesimerkki.webp"
        type="image/webp"
      />
      {/* PNG jos webp ei käytössä */}
      <source
        srcSet="/pictures/zoomesimerkki.png"
        type="image/png"
      />
      {/* Fallback */}
      <img
        src="/pictures/zoomesimerkki.png"
        loading="lazy"
        alt="Kuva Zoomin virransäästöominaisuudesta"
        className="kriteerikortti-images"
      />
    </picture>,
  mittausJaViitearvot:
    <text>
      Ohjelmiston tulisi joko arvioida tai mitata aiheuttamaansa kuormitusta, tämän ei tarvitse olla tarkkaa mutta pitäisi olla luotettavaa.
      Tämän voi visualisoida karkein luokituksin käyttäjälle, esim. vihreä tai punainen valo.
    </text>,
  kytkeytyvatKriteerit:
    <text>
      Kapasiteetin vapautus ohjelmistossa, oletusasetukset.
    </text>

};

export default PerustasonKriteeriA5Tekstit;
